export const MENU_ITEMS = [
  { title: 'Dashboard', iconName: 'line-chart', link: '/admin/dashboard' },
  { title: 'Help Center', iconName: 'comments', link: '/admin/helpdesk' },
  {
    title: 'Notifications',
    iconName: 'bullhorn',
    link: '/admin/notifications'
  },
  { title: 'Live Streaming', iconName: 'cog', link: '/admin/meetings'},
  { title: 'Groups', iconName: 'book', link: '/admin/courses' },
  { title: 'Events', iconName: 'calendar-o', link: '/admin/events' },
  { title: 'Polls', iconName: 'bar-chart', link: '/admin/polls' },
  { title: 'News', iconName: 'newspaper-o', link: '/admin/news' },
  { title: 'Users', iconName: 'user', link: '/admin/students' },
  { title: 'Approvals', iconName: 'check', link: '/admin/approvals' },
  { title: 'Manuals', iconName: 'book', link: '/admin/manuals' },
  { title: 'Videos', iconName: 'video', link: '/admin/videos' },
  { title: 'Settings', iconName: 'cog', link: '/admin/settings' },
];
