import { StudentProfilePictureModalView } from './student-profile-picture-modal.component';
import { resetProfilePictureMutation } from '../graphql/reset-profile-picture.mutation';
import { graphql } from 'react-apollo';

const withResetProfilePictureMutation = graphql(resetProfilePictureMutation, {
  props: ({ mutate }) => ({
    reset: (variables: any) => mutate!({ variables }),
  }),
});

export const StudentProfilePictureModal = withResetProfilePictureMutation(StudentProfilePictureModalView as any) as any;
