import { PublishNotificationView } from './publish-notification.component';
import { publishNotificationMutation } from '../graphql/publish-notification.mutation';
import { graphql } from 'react-apollo';

const withMutation = graphql(publishNotificationMutation, {
  props: ({ ownProps, mutate }) => ({
    publish: (groups, title, content, isSms) =>
      mutate({ variables: { groups, title, content, isSms } })
  })
});

export const PublishNotification = withMutation(PublishNotificationView);
