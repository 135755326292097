import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Subheader, List, ListItem } from 'material-ui';
import { Loading } from '../../common/components/loading.component';
import { ProfileAvatar } from '../../common/components/profile-avatar.component';
import { FontAwesome } from '../../common/components/font-awesome';
import { LiveFeedDialog } from './live-feed-approval-dialog.container';

const styles = {
  miniListItem: {
    paddingTop: 2,
    paddingBottom: 2
  },
  name: {
    display: 'inline-block',
    verticalAlign: 'top',
    marginTop: 5,
    marginLeft: 8,
    marginRight: 8,
    paddingTop: 5,
    fontSize: 12
  },
  date: {
    display: 'inline-block',
    verticalAlign: 'top',
    marginTop: 5,
    marginLeft: 8,
    marginRight: 8,
    paddingTop: 7,
    fontSize: 10,
    float: 'right'
  },
  changeArrow: {
    verticalAlign: 'top',
    marginLeft: 8,
    marginRight: 8,
    marginTop: 5
  }
};

export class LiveFeedApprovalsView extends Component {
  static propTypes = {
    approvals: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      error: PropTypes.any,
      waitingEventLiveFeedImagesApprovals: PropTypes.arrayOf(PropTypes.object)
    }).isRequired,
    height: PropTypes.number
  };

  constructor() {
    super();

    this.state = {
      currentApproval: null
    };
  }

  openApproval(approval) {
    this.setState({
      currentApproval: approval
    });
  }

  close() {
    this.props.approvals.refetch();
    this.setState({ currentApproval: null });
  }

  render() {
    if (this.props.approvals.loading) {
      return <Loading />;
    }

    const { waitingEventLiveFeedImagesApprovals } = this.props.approvals;

    return (
      <div style={{ height: this.props.height, overflow: 'scroll' }}>
        <LiveFeedDialog
          onRequestClose={() => this.close()}
          open={this.state.currentApproval !== null}
          approval={this.state.currentApproval}
        />
        <List>
          <Subheader>Pending Approvals</Subheader>
          {waitingEventLiveFeedImagesApprovals.length === 0 ? (
            <ListItem
              disabled={true}
              primaryText='There are no pending approvals!'
              leftIcon={<FontAwesome name='smile-o' />}
            />
          ) : null}
          {waitingEventLiveFeedImagesApprovals.map(approval => (
            <ListItem
              innerDivStyle={styles.miniListItem}
              key={approval.id}
              onClick={() => this.openApproval(approval)}
              onTouchTap={() => this.openApproval(approval)}
            >
              <div>
                <ProfileAvatar
                  size={35}
                  src={approval.uploadedBy.profilePictureUrl}
                />
                <div style={styles.name}>
                  <strong>{approval.uploadedBy.name}</strong> uploaded to{' '}
                  <strong>{approval.event.title}</strong>
                </div>
                <div style={styles.date}>{approval.createdAt}</div>
              </div>
            </ListItem>
          ))}
        </List>
      </div>
    );
  }
}
