import { graphql, compose } from 'react-apollo';
import { allVideosQuery } from '../graphql/all-videos-query';
import { VideosView } from './videos.component';
import { connect } from 'react-redux';
import { updateVideosFilter, editEntity } from '../videos.actions';
import { removeVideoMutation } from '../graphql/remove-video.mutation';
import { withStatistics } from '../../common/components/with-statistics.container';

const mapStateToProps = ({ videos }) => ({
  skip: videos.skip,
  limit: videos.limit,
  search: videos.search,
  editModalOpen: videos.isEditMode,
  editModeEntity: videos.editEntity
});

const mapDispatch = dispatch => ({
  updateFilter: filterChanges => dispatch(updateVideosFilter(filterChanges)),
  toggleEditMode: entity => dispatch(editEntity(entity))
});

const withState = connect(mapStateToProps, mapDispatch);

const withData = graphql(allVideosQuery, {
  name: 'videos',
  options: props => {
    let extraVars = {
      perferedIds: []
    };

    if (
      props.search === '' &&
      props.checkedItems &&
      props.checkedItems.length > 0
    ) {
      extraVars = {
        perferedIds: props.checkedItems
          .map(group =>
            group.startsWith('video_') ? group.replace('video_', '') : null
          )
          .filter(item => item)
      };
    }

    return {
      fetchPolicy: 'network-only',
      variables: {
        skip: props.skip,
        limit: props.limit,
        search: props.search,
        ...extraVars
      }
    };
  }
});

export const withVideos = compose(withState, withData);

const withMutation = graphql(removeVideoMutation, {
  options: {
    refetchQueries: ['allVideos', 'statistics']
  },
  props: ({ ownProps, mutate }) => ({
    removeVideo: videoId => mutate({ variables: { videoId } })
  })
});

export const Videos = withVideos(withMutation(withStatistics(VideosView)));
