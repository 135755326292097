import { combineReducers } from 'redux';
import { EFeedViewActions } from './feed.actions';

export const PER_PAGE = 10;

const search = (state = '', action) => {
  if (action.type === '@@router/LOCATION_CHANGE') {
    return '';
  }

  if (action.type === EFeedViewActions.CHANGE_FILTER && action.search !== undefined) {
    return action.search;
  }

  return state;
};

const skip = (state = 0, action) => {
  if (action.type === '@@router/LOCATION_CHANGE') {
    return 0;
  }

  if (action.type === EFeedViewActions.CHANGE_FILTER && action.skip !== undefined) {
    return action.skip;
  }

  return state;
};

const limit = (state = PER_PAGE, action) => {
  if (action.type === '@@router/LOCATION_CHANGE') {
    return PER_PAGE;
  }

  if (action.type === EFeedViewActions.CHANGE_FILTER && action.limit !== undefined) {
    return action.limit;
  }

  return state;
};

export const feed = combineReducers({
  search,
  skip,
  limit,
});
