import { graphql } from 'react-apollo';
import { eventGoingQuery } from '../graphql/event-going.query';
import { EventRSVPListView } from './event-rsvp-list.component';

const withRSVPList = graphql(eventGoingQuery, {
  name: 'goingList',
  skip: ({ eventId }) => eventId === null,
  options: ({ eventId }) => ({
    variables: {
      eventId,
    },
    fetchPolicy: 'network-only',
  }),
});

export const EventRSVPList = withRSVPList(EventRSVPListView);
