import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme } from '../../theme/theme';
import {
  Table,
  TableFooter,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn
} from 'material-ui';
import RaisedButton from 'material-ui/RaisedButton';
import _ from 'lodash';
import { CircularProgress } from 'material-ui';

const styles = {
  footerCell: {
    verticalAlign: 'middle'
  },
  right: {
    textAlign: 'right'
  },
  headerRow: {
    backgroundColor: Theme.SECONDARY_COLOR
  },
  headerCell: {
    color: Theme.SECONDARY_TEXT_COLOR,
    textTransform: 'uppercase'
  },
  cell: {
    width: '50px',
    color: Theme.SECONDARY_TEXT_COLOR
  },
  footer: {
    color: Theme.SECONDARY_TEXT_COLOR
  },
  toolbarTitle: {
    color: Theme.PRIMARY_TEXT_COLOR
  },
  container: {
    marginBottom: 20,
    position: 'relative'
  },
  loading: {
    position: 'absolute',
    right: 10,
    top: 10
  }
};

export class DataTable extends Component {
  static propTypes = {
    selectable: PropTypes.bool,
    multiSelectable: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    onNext: PropTypes.func,
    getItemStyle: PropTypes.func,
    onPrev: PropTypes.func,
    onItemPress: PropTypes.func,
    style: PropTypes.any,
    customHeaderOverlay: PropTypes.any,
    height: PropTypes.string,
    skip: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    idField: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    description: PropTypes.arrayOf(
      PropTypes.shape({
        headerName: PropTypes.string,
        render: PropTypes.func,
        style: PropTypes.any,
        valueFrom: PropTypes.any,
        width: PropTypes.number,
        rowStyle: PropTypes.any
      })
    ).isRequired
  };

  renderLoading() {
    if (this.props.loading) {
      return (
        <CircularProgress color={Theme.PRIMARY_COLOR} size={20} thickness={2} />
      );
    }

    return null;
  }

  getValue(item, desc) {
    if (desc.valueFrom && typeof desc.valueFrom === 'string') {
      return _.get(item, desc.valueFrom);
    } else if (desc.valueFrom && typeof desc.valueFrom === 'function') {
      return desc.valueFrom(item);
    } else if (desc.render) {
      return desc.render(item);
    }

    return '';
  }

  renderFooter(currentTopItems) {
    if (!this.props.onPrev || !this.props.onNext) {
      return null;
    }

    return (
      <TableFooter adjustForCheckbox={false}>
        <TableRow style={styles.footer}>
          <TableRowColumn style={styles.footerCell}>
            <RaisedButton
              onClick={() => this.props.onPrev()}
              disabled={currentTopItems - this.props.limit <= 0}
              primary={true}
              label='Previous'
            />
          </TableRowColumn>
          <TableRowColumn style={styles.footerCell}>
            Displaying items {this.props.skip}-{currentTopItems} of{' '}
            {this.props.total}
          </TableRowColumn>
          <TableRowColumn style={{ ...styles.footerCell, ...styles.right }}>
            <RaisedButton
              onClick={() => this.props.onNext()}
              disabled={this.props.total - currentTopItems === 0}
              primary={true}
              label='Next'
            />
          </TableRowColumn>
        </TableRow>
      </TableFooter>
    );
  }

  renderHeader() {
    return (
      <TableHeader
        displaySelectAll={false}
        adjustForCheckbox={false}
        enableSelectAll={false}
      >
        <TableRow style={styles.headerRow}>
          {this.props.description.map((desc, index) => (
            <TableHeaderColumn
              style={{
                ...styles.headerCell,
                width: desc.width ? `${desc.width}%` : null
              }}
              key={desc.headerName || index}
            >
              {desc.headerName
                ? desc.headerName
                : desc.header
                ? desc.header()
                : ''}
            </TableHeaderColumn>
          ))}
        </TableRow>
      </TableHeader>
    );
  }

  render() {
    let currentTopItems = this.props.limit + this.props.skip;

    if (currentTopItems > this.props.total) {
      currentTopItems = this.props.total;
    }

    return (
      <div
        style={{
          ...styles.container,
          ...(this.props.style || {}),
          overflowX: 'visible',
          overflowY: 'visible'
        }}
      >
        <Table
          onCellClick={(rowNumber, columnId) =>
            this.props.onItemPress &&
            !this.props.description[columnId].skipClick
              ? this.props.onItemPress(this.props.data[rowNumber])
              : null
          }
          bodyStyle={{ overflowX: 'visible', overflowY: 'visible' }}
          height={this.props.height || null}
          selectable={this.props.selectable || null}
          multiSelectable={this.props.multiSelectable || null}
        >
          {this.renderHeader()}
          <TableBody displayRowCheckbox={false}>
            {this.props.data.map(dataItem => (
              <TableRow
                key={dataItem[this.props.idField]}
                style={this.props.onItemPress ? { cursor: 'pointer' } : {}}
              >
                {this.props.description.map((desc, index) => (
                  <TableRowColumn
                    style={{
                      ...styles.cell,
                      ...(desc.rowStyle || {}),
                      width: desc.width ? `${desc.width}%` : null,
                      ...(desc.style || {}),
                      ...(this.props.getItemStyle
                        ? this.props.getItemStyle(dataItem)
                        : {})
                    }}
                    key={
                      (desc.headerName || index) + dataItem[this.props.idField]
                    }
                  >
                    {this.getValue(dataItem, desc)}
                  </TableRowColumn>
                ))}
              </TableRow>
            ))}
            {this.props.data.length === 0 ? (
              <TableRow>
                <TableRowColumn style={styles.cell}>
                  Oops, seems like there are no items to display!
                </TableRowColumn>
              </TableRow>
            ) : null}
          </TableBody>
          {this.props.showFooter !== null && this.props.showFooter === false ? null : this.renderFooter(currentTopItems)}
        </Table>
        <div style={styles.loading}>
          {this.props.customHeaderOverlay
            ? this.props.customHeaderOverlay
            : null}
          {this.renderLoading()}
        </div>
      </div>
    );
  }
}
