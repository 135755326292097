import React, { Component } from 'react';
import { Theme } from '../../theme/theme';
import { AppDrawer } from './drawer.container';
import { Courses } from '../../courses/components/courses.container';
import { Route } from 'react-router';
import { Students } from '../../students/components/students.container';
import { Events } from '../../events/components/events.component';
import { Polls } from '../../polls/components/polls.component';
import { News } from '../../news/components/news.component';
import { FeedItemWizard } from '../../feed/components/feed-item-wizard.component';
import { Approvals } from '../../approvals/components/approvals.component';
import { VideoContainer } from '../../meetings/components/video.container';

import { SettingsPage } from '../../settings/components/settings-page.rn';
import { MeetingsContainer } from '../../meetings/components/meetings.container';
import { Dashboard } from '../../dashboard/components/dashboard.container';
import { Helpdesk } from '../../helpdesk/components/helpdesk.container';
import { HelpdeskChat } from '../../helpdesk/components/helpdesk-chat-screen.container';
import { HelpdeskNewChat } from '../../helpdesk/components/helpdeks-new-chat.container';
import { PublishNotification } from '../../notifications/components/publish-notification.container';
import { SportsEvents } from '../../sports-events/components/sports-events.component';
import { Manuals } from '../../manuals/components/manuals.container';
import { Videos } from '../../videos/components/videos.container';

const styles = {
  mainContainer: {
    backgroundColor: Theme.BG_COLOR,
    width: '100%',
    height: '100%'
  },
  appBar: {
    position: 'fixed',
    zIndex: 2,
    top: 0,
    backgroundColor: Theme.TOP_BAR_COLOR
  }
};

export class MainView extends Component {
  render() {
    return (
      <div style={styles.mainContainer}>
        <AppDrawer />
        <div style={{ paddingLeft: 256, paddingTop: 10, height: '90%' }}>
          <Route
            exact={true}
            path='/admin/notifications'
            component={PublishNotification}
          />
          <Route exact={true} path='/admin/helpdesk' component={Helpdesk} />
          <Route
            exact={true}
            path='/admin/helpdesk-new'
            component={HelpdeskNewChat}
          />
          <Route
            exact={true}
            path='/admin/helpdesk/:id'
            component={HelpdeskChat}
          />
          <Route exact={true} path='/admin/dashboard' component={Dashboard} />
          <Route exact={true} path='/admin/courses' component={Courses} />
          <Route exact={true} path='/admin/students' component={Students} />
          <Route exact={true} path='/admin/events' component={Events} />
          <Route
            exact={true}
            path='/admin/sports-events'
            component={SportsEvents}
          />
          <Route exact={true} path='/admin/polls' component={Polls} />
          <Route exact={true} path='/admin/news' component={News} />
          <Route exact={true} path='/admin/settings' component={SettingsPage} />
          <Route exact={true} path='/admin/meetings' component={MeetingsContainer}/>
          <Route exact={true} path='/admin/video/:name' component={VideoContainer}/>
          <Route exact={true} path='/admin/approvals' component={Approvals} />
          <Route exact={true} path='/admin/manuals' component={Manuals} />
          <Route exact={true} path='/admin/videos' component={Videos} />
          <Route
            exact={true}
            path='/admin/events/create'
            component={FeedItemWizard}
          />
          <Route
            exact={true}
            path='/admin/events/edit/:id'
            component={FeedItemWizard}
          />
          <Route
            exact={true}
            path='/admin/events/publish/:id'
            component={FeedItemWizard}
          />
          <Route
            exact={true}
            path='/admin/events/platforms/:id'
            component={FeedItemWizard}
          />
          <Route
            exact={true}
            path='/admin/sports-events/create'
            component={FeedItemWizard}
          />
          <Route
            exact={true}
            path='/admin/sports-events/edit/:id'
            component={FeedItemWizard}
          />
          <Route
            exact={true}
            path='/admin/sports-events/publish/:id'
            component={FeedItemWizard}
          />
          <Route
            exact={true}
            path='/admin/sports-events/platforms/:id'
            component={FeedItemWizard}
          />
          <Route
            exact={true}
            path='/admin/news/create'
            component={FeedItemWizard}
          />
          <Route
            exact={true}
            path='/admin/news/edit/:id'
            component={FeedItemWizard}
          />
          <Route
            exact={true}
            path='/admin/news/publish/:id'
            component={FeedItemWizard}
          />
          <Route
            exact={true}
            path='/admin/news/platforms/:id'
            component={FeedItemWizard}
          />
          <Route
            exact={true}
            path='/admin/polls/create'
            component={FeedItemWizard}
          />
          <Route
            exact={true}
            path='/admin/polls/edit/:id'
            component={FeedItemWizard}
          />
          <Route
            exact={true}
            path='/admin/polls/publish/:id'
            component={FeedItemWizard}
          />
          <Route
            exact={true}
            path='/admin/polls/platforms/:id'
            component={FeedItemWizard}
          />
        </div>
      </div>
    );
  }
}
