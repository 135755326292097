export const EStudentsViewActions = {
  CHANGE_FILTER: 'students/CHANGE_FILTER',
  EDIT_ENTITY: 'students/EDIT_ENTITY',
};

export const updateStudentsFilter = filterChanges => ({
  type: EStudentsViewActions.CHANGE_FILTER,
  ...filterChanges,
});

export const editEntity = editableEntity => ({
  type: EStudentsViewActions.EDIT_ENTITY,
  open: !!editableEntity,
  entity: editableEntity,
});
