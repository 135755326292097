import gql from 'graphql-tag';

export const publishNotificationMutation = gql`
  mutation publishNotification(
    $groups: [String]!
    $content: String!
    $title: String!
    $isSms: Boolean
  ) {
    publishNotification(
      groups: $groups
      content: $content
      title: $title
      isSms: $isSms
    )
  }
`;
