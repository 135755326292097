import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Wizard } from '../../common/components/wizard.component';
import { CreateEventForm } from '../../events/components/create-events-form.container';
import { TargetPickerForm } from './target-picker-form.container';
import { PublicationPlatformPickerForm } from './publication-platform-picker-form.container';
import { CreateUpdatePollForm } from '../../polls/components/create-update-poll-form.container';
import { CreateUpdateNewsForm } from '../../news/components/create-update-news-form.container';

export class FeedItemWizard extends Component {
  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
      params: PropTypes.object.isRequired,
    }).isRequired,
  };

  componentWillMount() {
    const splittedPath = this.props.match.path.split('/');
    const type = splittedPath[2].toUpperCase();
    const action = splittedPath[3].toUpperCase();
    const editEntityId = this.props.match.params.id || null;
    this.action = action;

    const definitionsMap = {
      'SPORTS-EVENTS': {
        component: CreateEventForm,
        title: 'Create/Edit Sport Event',
        props: {
          editEntityId,
        },
      },
      EVENTS: {
        component: CreateEventForm,
        title: 'Create/Edit Event',
        props: {
          editEntityId,
        },
      },
      POLLS: {
        component: CreateUpdatePollForm,
        title: 'Create/Edit Polls',
        props: {
          editEntityId,
        },
      },
      NEWS: {
        component: CreateUpdateNewsForm,
        title: 'Create/Edit News',
        props: {
          editEntityId,
        },
      },
    };

    this.steps = [
      definitionsMap[type],
      {
        component: TargetPickerForm,
        title: 'Choose Recipients',
        props: {
          editEntityId,
        },
      },
      {
        component: PublicationPlatformPickerForm,
        title: 'Communication Platform',
        props: {
          editEntityId,
        },
      },
    ];
  }

  render() {
    let step = 0;

    if (this.action === 'PUBLISH') {
      step = 1;
    } else if (this.action === 'PLATFORMS') {
      step = 2;
    }

    return (
      <Wizard step={step} steps={this.steps} />
    );
  }
}
