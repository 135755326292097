import { StaticDataView } from './static-data.component';
import { graphql } from 'react-apollo';
import { loadStaticDataMutation } from '../../students/graphql/load-static-data.mutation';

const withLoadStaticDataMutation = graphql(loadStaticDataMutation, {
  options: {
    refetchQueries: ['statistics'],
  },
  props: ({ mutate }) => ({
    loadStaticData: () => mutate({}),
  }),
});


export const StaticData = withLoadStaticDataMutation(StaticDataView);
