import { graphql } from 'react-apollo';
import { statisticsQuery } from '../graphql/statistics.query';

export const withStatistics = graphql(statisticsQuery, {
  options: {
    fetchPolicy: 'network-only',
  },
  props: ({ data }) => ({
    statisticsData: (data.loading || data.error) ? {} : data.statistics,
  }),
});
