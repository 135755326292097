import gql from 'graphql-tag';

export const settingsQuery = gql`
  query settings {
    settings {
      name
      value
    }
  }
`;
