import gql from 'graphql-tag';

export const photosQuery = gql`
  query photos($filter: PhotosFilter) {
    photos(filter: $filter) {
      id
      url
    }
  }
`;
