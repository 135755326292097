import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DataTable } from '../../common/components/data-table.component';
import { Loading } from '../../common/components/loading.component';
import { ProfileAvatar } from '../../common/components/profile-avatar.component';
import { FontAwesome } from '../../common/components/font-awesome';
import { Theme } from '../../theme/theme';
import moment from 'moment';
import { store } from '../../store';
import { push } from 'react-router-redux';
import { Checkbox, FloatingActionButton, RaisedButton, TextField } from 'material-ui';
import { FiltersBar } from '../../common/components/filters-bar.component';

export class HelpdeskView extends Component {
  static propTypes = {
    chats: PropTypes.shape({
      error: PropTypes.any,
      loading: PropTypes.bool,
      helpdeskChats: PropTypes.array,
    }),
    markAsResolved: PropTypes.func.isRequired,
  };

  constructor() {
    super();

    this.state = {
      search: '',
      checkedChats: [],
    };
  }

  handleClick(e, chat) {
    e.preventDefault();
    e.stopPropagation();

    if (this.state.checkedChats.find(id => id === chat.id)) {
      this.setState({
        checkedChats: this.state.checkedChats.filter(id => id !== chat.id),
      });
    } else {
      this.setState({
        checkedChats: [...this.state.checkedChats, chat.id],
      });
    }
  }

  get tableDesc() {
    return [
      {
        headerName: '',
        width: 5,
        skipClick: true,
        render: chat => {
          if (chat.isHidden) {
            return null;
          }

          return (
            <Checkbox
              checked={!!this.state.checkedChats.find(id => id === chat.id)}
              onCheck={e => this.handleClick(e, chat)} />
          );
        },
      },
      { headerName: '', width: 5, render: chat => <ProfileAvatar size={30} src={chat.users[0].profilePictureUrl} /> },
      { headerName: 'User', width: 25, valueFrom: chat => chat.users[0].name },
      {
        headerName: 'Last Update',
        width: 30,
        valueFrom: chat => moment(chat.lastUpdate, 'x').format('LLLL'),
      },
      {
        headerName: 'Status',
        width: 15,
        valueFrom: chat => chat.isHidden ? 'Resolved' : 'Open',
      },
      {
        headerName: 'Unread',
        width: 10,
        render: chat => {
          if (chat.unreadMessagesCount === 0) {
            return null;
          }
          return (
            <div style={styles.unreadBadge}>{chat.unreadMessagesCount}</div>
          );
        },
      },
      {
        headerName: 'Actions',
        width: 20,
        rowStyle: {
          overflow: 'visible',
        },
        render: item => (
          <div>
            {
              !item.isHidden && (
                <a
                  style={{ marginLeft: 10 }}
                  onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                    this.props.markAsResolved(item.id);
                  }}>
                  <FontAwesome tooltip="Resolve" style={styles.actionIcon} name="check" />
                </a>
              )
            }
          </div>
        ),
      },
    ];
  }

  updateSearch(newValue) {
    this.setState({ search: newValue });
  }

  componentDidUpdate(prevProps) {
    const prevQuery = (prevProps.location.query || {}).reload;
    const currentQuery = (this.props.location.query || {}).reload;

    if (prevQuery !== currentQuery && currentQuery === true) {
      this.props.chats.refetch();
    }
  }

  resolveSelected() {
    this.state.checkedChats.forEach(chatId => {
      this.props.markAsResolved(chatId);
    });
  }

  render() {
    if (this.props.chats.networkStatus === 1) {
      return <Loading />;
    }

    const chats = (this.props.chats && this.props.chats.helpdeskChats ? Array.from(this.props.chats.helpdeskChats) : []).filter(chat => {
      if (this.state.search === '') {
        return true;
      }

      const user = chat.users[0];

      if (!user) {
        return false;
      }

      return [
        user.name,
        user.username,
      ].some(text => text.toLowerCase().includes((this.state.search || '').toLowerCase()));
    }).sort((a, b) => {
      const first = a.isHidden ? 1 : 0;
      const second = b.isHidden ? 1 : 0;

      return first - second || Number(b.lastUpdate) - Number(a.lastUpdate);
    });

    return (
      <div>
        <FiltersBar title="Help Center Chats">
          <TextField
            hintText="Search chats by user"
            value={this.state.search}
            onChange={(event, newValue) => this.updateSearch(newValue)} />
          <FloatingActionButton
            style={styles.addButton}
            onClick={() => {
              store.dispatch(push('helpdesk-new'));
            }}>
            <FontAwesome name="plus" />
          </FloatingActionButton>
          <RaisedButton
            onClick={() => this.resolveSelected()}
            style={{ float: 'right', marginTop: 10, marginRight: 20 }}
            disabled={this.state.checkedChats.length === 0}
            primary={true}
            label="RESOLVE SELECTED" />
        </FiltersBar>
        <DataTable
          skip={0}
          limit={chats.length}
          loading={this.props.chats.loading}
          total={chats.length}
          style={styles.table}
          idField="id"
          data={chats}
          onItemPress={item => {
            store.dispatch(push(`helpdesk/${item.id}`));
          }}
          getItemStyle={item => item.isHidden ? { opacity: 0.7 } : { fontWeight: 'bold' }}
          description={this.tableDesc} />
      </div>
    );
  }
}

const styles = {
  table: {
    marginLeft: '3%',
    marginRight: '3%',
  },
  unreadBadge: {
    backgroundColor: Theme.ACTIVE2_COLOR,
    borderRadius: 10,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 2,
    paddingBottom: 2,
    textAlign: 'center',
    color: 'white',
    width: 20,
  },
  addButton: {
    float: 'right',
  },
};
