import { graphql } from 'react-apollo';
import { withEditEntity } from './with-edit-feed-item.container';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { PublicationPlatformPickerFormView } from './publication-platform-picker-form.component';
import { publishMutation } from '../graphql/publish-feed-item.mutation';

const mapDispatch = dispatch => ({
  navigate: route => dispatch(push(route)),
});

const withState = connect(({ router }) => ({
  currentLocation: router.location.pathname,
}), mapDispatch);

const withMutation = graphql(publishMutation, {
  props: ({ ownProps, mutate }) => ({
    publish: (itemId, platforms) => mutate({ variables: { itemId, platforms } }),
  }),
});

export const PublicationPlatformPickerForm = withState(withMutation(withEditEntity(PublicationPlatformPickerFormView)));
