import gql from 'graphql-tag';

export const getUserQuery = gql`
  query GetUser {
    getUser {
      id
      name
      email
      admin
      username
      profilePictureUrl
    }
  }
`;
