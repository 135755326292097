import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, RaisedButton, TextField } from 'material-ui';
import { Loading } from '../../common/components/loading.component';
import { Theme } from '../../theme/theme';

const INITIAL_STATE = {
  isLoading: false,
  isDone: false,
  error: null,
  snapchatUsername: ''
};

const styles = {
  changeArrow: {
    verticalAlign: 'top',
    marginLeft: 15,
    marginRight: 15,
    marginTop: 80
  },
  button: {
    marginLeft: 10
  },
  parent: {
    textAlign: 'center'
  }
};

export class SnapchatApprovalDialogView extends Component {
  static propTypes = {
    approve: PropTypes.func.isRequired,
    reject: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func,
    approval: PropTypes.object,
    open: PropTypes.bool.isRequired
  };

  constructor() {
    super();

    this.state = INITIAL_STATE;
  }

  remove() {
    this.setState({ isLoading: true, isDone: false, error: null });

    this.props
      .reject(this.props.approval.approvalId)
      .then(({ data }) => {
        if (!data.manuallyRespondToSnapchat) {
          throw new Error('אופס, משהו השתבש!');
        }

        this.setState({ isLoading: false, error: null, isDone: true });
      })
      .catch(e => {
        this.setState({ isLoading: false, error: e });
      });
  }

  approve() {
    this.setState({ isLoading: true, isDone: false, error: null });

    this.props
      .approve(this.props.approval.approvalId, this.state.snapchatUsername)
      .then(({ data }) => {
        if (!data.manuallyRespondToSnapchat) {
          throw new Error('אופס, משהו השתבש!');
        }

        this.setState({ isLoading: false, error: null, isDone: true });
      })
      .catch(e => {
        this.setState({ isLoading: false, error: e });
      });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.approval === null && this.props.approval !== null) {
      this.setState(INITIAL_STATE);
      this.props.onRequestClose();
    } else if (nextProps.approval !== null) {
      this.setState({ snapchatUsername: nextProps.approval.snapchatUsername });
    }
  }

  close() {
    this.props.onRequestClose();
  }

  cancel() {
    this.close();
  }

  renderContent(approval) {
    if (this.state.isLoading) {
      return <Loading />;
    }

    if (this.state.error !== null) {
      return <div>{this.state.error.toString()}</div>;
    }

    if (this.state.isDone) {
      return (
        <div style={styles.parent}>
          <div>Done!</div>
        </div>
      );
    }

    return (
      <div style={styles.parent}>
        <div>
          <span>
            You can change and modify the Snapchat username in case of a
            mistake:
          </span>
          <br />
          @
          <TextField
            value={this.state.snapchatUsername}
            hintText='Snapchat username'
            style={{ marginLeft: 30, marginRight: 30 }}
            onChange={(event, newValue) =>
              this.setState({ snapchatUsername: newValue })
            }
          />
        </div>
      </div>
    );
  }

  render() {
    if (!this.props.approval) {
      return null;
    }

    const { approval } = this.props;

    const actions =
      this.state.isDone || this.state.error
        ? [
            <RaisedButton
              secondary={true}
              style={styles.button}
              label='Close'
              onClick={() => this.cancel()}
              onTouchTap={() => this.cancel()}
            />
          ]
        : [
            <RaisedButton
              disabled={this.state.isLoading}
              style={{ ...styles.button, float: 'left' }}
              label='Cancel'
              onClick={() => this.cancel()}
              onTouchTap={() => this.cancel()}
            />,
            <RaisedButton
              backgroundColor={Theme.ACTIVE2_COLOR}
              disabled={this.state.isLoading}
              secondary={true}
              style={styles.button}
              label='Remove Request'
              onClick={() => this.remove()}
              onTouchTap={() => this.remove()}
            />
          ];

    if (!this.state.isDone) {
      actions.push(
        <RaisedButton
          disabled={this.state.isLoading || this.state.duringRejection}
          style={styles.button}
          label='Approve & Update'
          primary={true}
          onClick={() => this.approve()}
          onTouchTap={() => this.approve()}
        />
      );
    }

    return (
      <Dialog
        title={
          this.state.isDone
            ? 'Done!'
            : `Approve ${approval.user.name}'s Snapchat account?`
        }
        actions={actions}
        modal={true}
        open={this.props.open}
      >
        {this.renderContent(approval)}
      </Dialog>
    );
  }
}
