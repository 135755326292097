import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, FlatButton } from 'material-ui';
import { ProfileAvatar } from '../../common/components/profile-avatar.component';
import { DataTable } from '../../common/components/data-table.component';
import { CourseIcon } from '../../common/components/course-icon.component';

const styles = {
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  infoSpan: {
    marginRight: 10,
    fontWeight: 'bold'
  },
  details: {
    marginLeft: 20
  },
  tableContainer: {
    marginTop: 10
  },
  infoText: {
    userSelect: 'text'
  }
};

export class StudentDetailsModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    student: PropTypes.any,
    onRequestClose: PropTypes.func.isRequired
  };

  render() {
    if (!this.props.isOpen) {
      return null;
    }

    const actions = [
      <FlatButton
        label='Close'
        onClick={() => this.props.onRequestClose()}
        onTouchTap={() => this.props.onRequestClose()}
      />
    ];

    const { student } = this.props;

    const coursesColumns = [
      {
        headerName: '',
        width: 10,
        render: item => (
          <CourseIcon
            style={{ maxWidth: '15px' }}
            active={false}
            courseIdentifier={item.identifier}
          />
        )
      },
      {
        headerName: 'Identifier',
        width: 30,
        valueFrom: 'identifier'
      },
      {
        headerName: 'Name',
        width: 30,
        valueFrom: 'fullName'
      },
      {
        headerName: 'Type',
        width: 30,
        render: item => (item.isManual ? 'Manual' : 'Predefined')
      }
    ];

    return (
      <Dialog
        autoDetectWindowHeight={false}
        title={`Student Details: ${student.name}`}
        actions={actions}
        modal={false}
        open={this.props.isOpen}
        onRequestClose={() => this.props.onRequestClose()}
      >
        <div style={styles.row}>
          <div>
            <ProfileAvatar size={100} src={student.profilePictureUrl} />
          </div>
          <div style={styles.details}>
            <span style={styles.infoSpan}>Internal User ID: </span>
            <span style={styles.infoText}>{student.id}</span>
            <br />
            <span style={styles.infoSpan}>Name: </span>
            <span style={styles.infoText}>{student.name}</span>
            <br />
            <span style={styles.infoSpan}>Username: </span>
            <span style={styles.infoText}>{student.username}</span>
            <br />
            <span style={styles.infoSpan}>Email: </span>
            <span style={styles.infoText}>{student.msjEmail}</span>
            <br />
            <span style={styles.infoSpan}>Major: </span>
            <span style={styles.infoText}>{student.major}</span>
          </div>
          <div style={styles.details}>
            <span style={styles.infoSpan}>Instagram: </span>
            <span style={styles.infoText}>{student.instagram || '-'}</span>
            <br />
            <span style={styles.infoSpan}>Snapchat: </span>
            <span style={styles.infoText}>
              {student.snapchat
                ? student.snapchat === 'DURING_ENROLLMENT'
                  ? 'During Approval'
                  : student.snapchat
                : '-'}
            </span>
            <br />
            <span style={styles.infoSpan}>Twitter: </span>
            <span style={styles.infoText}>{student.twitter || '-'}</span>
            <br />
            <span style={styles.infoSpan}>Facebook: </span>
            <span style={styles.infoText}>
              {student.facebook ? (
                <a
                  target='_blank'
                  href={`https://www.facebook.com/${student.facebook}`}
                >
                  {student.facebookName}
                </a>
              ) : (
                '-'
              )}
            </span>
            <br />
            <span style={styles.infoSpan}>LinkedIn: </span>
            <span style={styles.infoText}>
              {student.linkedin ? (
                <a target='_blank' href={student.linkedinLink}>
                  {student.linkedin}
                </a>
              ) : (
                '-'
              )}
            </span>
            <br />
          </div>
        </div>
        <div style={{ ...styles.row, ...styles.tableContainer }}>
          <DataTable
            height='170px'
            limit={0}
            skip={10}
            total={student.courses ? student.courses.length : 0}
            description={coursesColumns}
            data={student.courses || []}
            idField='id'
            loading={false}
            multiSelectable={false}
            selectable={false}
          />
        </div>
      </Dialog>
    );
  }
}
