import { combineReducers } from 'redux';
import { EManualsViewActions } from './manuals.actions';

export const MANUALS_PER_PAGE = 10;

const search = (state = '', action) => {
  if (
    action.type === EManualsViewActions.CHANGE_FILTER &&
    action.search !== undefined
  ) {
    return action.search;
  }

  return state;
};

const studentsSearch = (state = '', action) => {
  if (action.type === EManualsViewActions.CHANGE_STUDENTS_FILTER) {
    return action.search;
  }

  return state;
};

const skip = (state = 0, action) => {
  if (
    action.type === EManualsViewActions.CHANGE_FILTER &&
    action.skip !== undefined
  ) {
    return action.skip;
  }

  return state;
};

const limit = (state = MANUALS_PER_PAGE, action) => {
  if (
    action.type === EManualsViewActions.CHANGE_FILTER &&
    action.limit !== undefined
  ) {
    return action.limit;
  }

  return state;
};

const editEntity = (state = null, action) => {
  if (action.type === EManualsViewActions.EDIT_ENTITY) {
    return action.entity;
  }

  return state;
};

const isEditMode = (state = false, action) => {
  if (action.type === EManualsViewActions.EDIT_ENTITY) {
    return action.open;
  }

  return state;
};

export const manuals = combineReducers({
  search,
  skip,
  limit,
  editEntity,
  isEditMode,
  studentsSearch
});
