import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, Subheader } from 'material-ui';
import { ProfileAvatar } from '../../common/components/profile-avatar.component';
import { Theme } from '../../theme/theme';
import Autoscroll from 'autoscroll-react';
import Lightbox from 'react-image-lightbox';
import InfiniteScroll from 'react-infinite-scroller';
import { Loading } from '../../common/components/loading.component';

export class MessagesListView extends Component {
  static propTypes = {
    messages: PropTypes.array,
    hasMore: PropTypes.bool,
    onLoadOlder: PropTypes.func,
  };

  constructor() {
    super();

    this.state = {
      enlargedImage: null,
    };
  }

  renderMessage(message, key) {
    if (message.serverMessage) {
      return (
        <Subheader key={key} style={{ fontStyle: 'italic' }}>{message.content} ({message.createdAtDay}, {message.createdAt})</Subheader>
      );
    }

    const isFromHelpdesk = message.author.username === 'helpdesk';

    return (
      <ListItem
        onClick={() => message.messageType === 'TEXT' ? null : this.setState({ enlargedImage: message.content })}
        disabled={message.messageType === 'TEXT'}
        key={key}>
        <div style={{
          display: 'flex',
          flexDirection: isFromHelpdesk ? 'row-reverse' : 'row',
        }}>
          <ProfileAvatar size={40} src={message.author.profilePictureUrl} />
          <div style={{
            padding: 10,
            borderRadius: 10,
            marginLeft: 10,
            marginRight: 10,
            backgroundColor: isFromHelpdesk ? Theme.ACTIVE1_COLOR : Theme.DESCRIPTION_TEXT_COLOR,
          }}>
            {
              message.messageType === 'IMAGE' ? (
                <div>
                  <img
                    alt=""
                    src={message.content}
                    style={{
                      borderRadius: 5, height: 150, width: 150, objectFit: 'cover',
                    }} />
                </div>
              ) : (
                <div>
                  <span style={{ color: Theme.PRIMARY_COLOR }}>{message.content}</span>
                  <br />
                  <span style={{
                    fontSize: 11,
                    color: 'rgba(0, 50, 101, 0.54)',
                  }}>{`${message.createdAtDay} (${message.createdAt})`}</span>
                </div>
              )
            }
          </div>
        </div>
      </ListItem>
    );
  }

  render() {
    return (
      <div style={{ flex: 1, overflow: 'scroll', height: window.innerHeight * 0.5 }} onScroll={this.props.onScroll}>
        {this.state.enlargedImage && (
          <Lightbox
            mainSrc={this.state.enlargedImage}
            onCloseRequest={() => this.setState({ enlargedImage: null })}
          />
        )}
        <InfiniteScroll
          threshold={30}
          pageStart={0}
          isReverse={true}
          loadMore={() => this.props.onLoadOlder()}
          hasMore={this.props.hasMore}
          useWindow={false}
          initialLoad={false}
          loader={<Loading />}>
          <List>
            {this.props.messages.map((message, index) => this.renderMessage(message, index))}
          </List>
        </InfiniteScroll>
      </div>
    );
  }
}

export const MessagesList = Autoscroll(MessagesListView);
