import { graphql } from 'react-apollo';
import { withEditEntity } from '../../feed/components/with-edit-feed-item.container';
import { CreateUpdateNewsFormView } from './create-update-news-form.component';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { createUpdateNewsMutation } from '../graphql/create-update-news.mutation';

const mapDispatch = dispatch => ({
  navigate: route => dispatch(push(route)),
});

const withState = connect(null, mapDispatch);

const withMutation = graphql(createUpdateNewsMutation, {
  props: ({ ownProps, mutate }) => ({
    createUpdateNews: (newsId, news) => mutate({
      variables: {
        newsId,
        news,
      },
    }),
  }),
});

export const CreateUpdateNewsForm = withState(withMutation(withEditEntity(CreateUpdateNewsFormView)));
