import { graphql } from 'react-apollo';
import { waitingPhotoApprovalsQuery } from '../../students/graphql/waiting-photo-approvals.query';
import { PhotoApprovalsView } from './photo-approvals.component';

const withData = graphql(waitingPhotoApprovalsQuery, {
  name: 'approvals',
  options: {
    pollInterval: 10000,
    fetchPolicy: 'network-only',
  },
});

export const PhotoApprovals = withData(PhotoApprovalsView);
