import gql from 'graphql-tag';
import { studentFragment } from './students.fragment';

export const createStudentMutation = gql`
  mutation CreateStudent($student: StudentInput!) {
    createStudent(student: $student) {
      ...StudentInfo
    }
  }
  ${studentFragment}
`;
