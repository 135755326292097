import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DataBox } from '../../common/components/data-box.component';
import { SendMessageInput } from './send-message-input.component';
import { TargetPickerForm } from '../../feed/components/target-picker-form.container';
import { FlatButton, RaisedButton } from 'material-ui';
import { push } from 'react-router-redux';
import { store } from '../../store';
import { Loading } from '../../common/components/loading.component';

export class HelpdeskNewChatView extends Component {
  static propTypes = {
    sendMessage: PropTypes.func.isRequired,
  };

  constructor() {
    super();

    this.state = {
      groups: [],
      review: false,
      content: '',
      isLoading: false,
      messageType: 'TEXT',
    };
  }

  reviewMessage(messageType, content) {
    if (this.state.groups.length === 0) {
      alert('Please pick students');

      return;
    }

    this.setState({
      review: true,
      content,
      messageType,
    });
  }

  updateGroups(groups) {
    this.setState({
      groups: groups.filter(g => g !== 'users'),
    });
  }

  getTextCount() {
    const totalUsers = this.state.groups.filter(g => g.startsWith('user_')).length;
    const totalCourses = this.state.groups.filter(g => g.startsWith('course_')).length;
    const result = [];

    if (totalUsers > 0) {
      result.push(`${totalUsers} user(s)`);
    }

    if (totalCourses > 0) {
      result.push(`${totalCourses} course(s)`);
    }

    return result.join(' and ');
  }

  sendMessage() {
    this.setState({
      isLoading: true,
    }, () => {
      this.props.sendMessage(this.state.messageType, this.state.content, this.state.groups).then(() => {
        store.dispatch(push('helpdesk'));
      });
    });
  }

  render() {
    return (
      <div style={styles.container}>
        <DataBox title="Compose Help Center Message">
          {
            this.state.isLoading ? (
              <Loading />
            ) : (
              this.state.review ? (
                <div style={styles.reviewParent}>
                  <h2>Are you sure?</h2>
                  <h3>Send message to {this.getTextCount()}?</h3>
                  {
                    this.state.messageType === 'TEXT' ? (
                      <blockquote>
                        {this.state.content}
                      </blockquote>
                    ) : (
                      <div>
                        <img alt={''} src={this.state.content} style={{ maxWidth: 400 }} />
                      </div>
                    )
                  }
                  <div>
                    <RaisedButton
                      primary={true}
                      onClick={() => this.sendMessage()}
                      label="Yes, send this message" />
                    <FlatButton
                      style={{ marginLeft: 10 }}
                      onClick={() => {
                        this.setState({ review: false });
                      }}
                      label="Cancel" />
                  </div>
                </div>
              ) : (
                <div>
                  <TargetPickerForm
                    groups={this.state.groups}
                    disableAllUsers={true}
                    hideButtons={true}
                    onChange={groups => this.updateGroups(groups)} />
                  <SendMessageInput
                    content={this.state.content}
                    skipClearOnSend={true}
                    disablePicture={false}
                    disabled={this.state.groups.length === 0}
                    onSendMessage={this.reviewMessage.bind(this)} />
                </div>
              )
            )
          }
        </DataBox>
      </div>
    );
  }
}

const styles = {
  container: {
    padding: '1%',
  },
  reviewParent: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
};
