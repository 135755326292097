import React, { Component } from 'react';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import { logout, login } from '../../account/account.util';
import gql from 'graphql-tag';
import { client } from '../../core/apollo/apollo';
import config from '../../core/config/config';

const styles = {
  loginButton: {
    marginTop: 30,
    marginBottom: 10,
  },
  parent: {
    textAlign: 'center',
    paddingLeft: 50,
    paddingRight: 50,
  },
};

export class LoginFormView extends Component {
  state = {
    username: '',
    password: '',
    error: '',
    loading: false,
  };

  handleSubmit = async e => {
    e.preventDefault();
    const { username, password } = this.state;
    try {
      await login(username, password);
      await client.mutate({
        mutation: gql`
          mutation {
            amIAdmin
          }
        `,
      });

      window.location.href = config.basePath;
    } catch (err) {
      logout();
      this.setState({ error: err.toString() });
    }
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const { username, password, error } = this.state;

    return (
      <form onSubmit={this.handleSubmit} style={styles.parent}>
        <TextField name="username" floatingLabelText="Username" fullWidth={true} value={username} onChange={this.handleChange} />
        <TextField
          name="password"
          floatingLabelText="Password"
          type="password"
          fullWidth={true}
          value={password}
          onChange={this.handleChange}
        />
        <RaisedButton
          disabled={password === '' || username === ''}
          style={styles.loginButton}
          primary={true}
          type="submit"
          label="LOGIN"
          fullWidth={true}
        />
        {error}
      </form>
    );
  }
}
