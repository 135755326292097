import React, { Component } from 'react';
import Subheader from 'material-ui/Subheader';
import { List, ListItem } from 'material-ui/List';
import { RaisedButton } from 'material-ui';
import { Formik, Field } from 'formik';
import * as yup from 'yup';

import { Theme } from '../../theme/theme';
import { Loading } from '../../common/components/loading.component';
import { FontAwesome } from '../../common/components/font-awesome';
import { CreateStudentVariables } from '../../ApolloComponents';
import { FormikMaterialTextField } from '../../common/components/material-text-field.formik';

const styles = {
  header: {
    lineHeight: 'initial',
    paddingTop: 10
  },
  miniListItem: {
    paddingTop: 0,
    paddingBottom: 0
  },
  identifierField: {
    width: 230,
    marginLeft: 15
  },
  lecturerField: {
    width: 100,
    marginRight: 10
  },
  avatar: {
    marginTop: 12
  },
  headerNote: {
    color: Theme.ACTIVE2_COLOR
  }
};

interface Props {
  initialStudent?: Partial<CreateStudentVariables['student']>;
  createEditStudent: (vars: CreateStudentVariables) => any;
}

const defaultValues: CreateStudentVariables['student'] = {
  username: ''.toLowerCase(),
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: ''
};

const createStudentSchema = yup.object().shape({
  username: yup.string().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().required(),
  phoneNumber: yup.string().required()
});

export class CreateEditStudentFormView extends Component<
  Props,
  { error: string; isDone: boolean }
> {
  state = {
    error: '',
    isDone: false
  };

  render() {
    const { initialStudent, createEditStudent } = this.props;
    const { error, isDone } = this.state;

    return (
      <Formik<CreateStudentVariables['student']>
        initialValues={{
          ...defaultValues,
          ...initialStudent
        }}
        onSubmit={async (student, { setSubmitting }) => {
          try {
            await createEditStudent({ student });
          } catch (error) {
            return this.setState({ error });
          }
          this.setState({
            isDone: true
          });
          setSubmitting(false);
        }}
        validationSchema={createStudentSchema}
      >
        {({ isSubmitting, isValid, handleSubmit }) => {
          if (isSubmitting) {
            return <Loading />;
          }

          if (isDone) {
            return (
              <div style={{ textAlign: 'center', marginTop: 20 }}>
                <FontAwesome name='check' />
                <br />
                <br />
                Done!
              </div>
            );
          }

          return (
            <form onSubmit={handleSubmit}>
              <List>
                {error ? (
                  <Subheader style={styles.headerNote}>
                    {error.toString()}
                  </Subheader>
                ) : null}
                <Subheader style={styles.header}>User Information</Subheader>
                <ListItem innerDivStyle={styles.miniListItem} disabled={true}>
                  <Field
                    name='username'
                    floatingLabelText='Username'
                    component={FormikMaterialTextField}
                  />
                </ListItem>
                <ListItem innerDivStyle={styles.miniListItem} disabled={true}>
                  <Field
                    name='email'
                    floatingLabelText='Email'
                    component={FormikMaterialTextField}
                  />
                </ListItem>
                <ListItem innerDivStyle={styles.miniListItem} disabled={true}>
                  <Field
                    name='phoneNumber'
                    floatingLabelText='Phone Number (with country code)'
                    component={FormikMaterialTextField}
                  />
                </ListItem>
                <ListItem innerDivStyle={styles.miniListItem} disabled={true}>
                  <Field
                    name='firstName'
                    floatingLabelText='First Name'
                    component={FormikMaterialTextField}
                  />
                </ListItem>
                <ListItem innerDivStyle={styles.miniListItem} disabled={true}>
                  <Field
                    name='lastName'
                    floatingLabelText='Last Name'
                    component={FormikMaterialTextField}
                  />
                </ListItem>
                <ListItem disabled={true}>
                  <RaisedButton
                    type='submit'
                    disabled={!isValid}
                    primary={true}
                    label='SAVE'
                    fullWidth={true}
                  />
                </ListItem>
              </List>
            </form>
          );
        }}
      </Formik>
    );
  }
}
