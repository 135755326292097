import gql from 'graphql-tag';

export const waitingSnapchatApprovalsQuery = gql`
  query waitingSnapchatApprovals {
    waitingSnapchatApprovals {
      approvalId
      createdAt
      snapchatUsername
      validationCode
      user {
        id
        name
        profilePictureUrl
      }
    }
  }
`;
