import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Drawer from 'material-ui/Drawer';
import { FontAwesome } from './font-awesome';
import { Theme } from '../../theme/theme';

const styles = {
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
    color: Theme.SECONDARY_TEXT_COLOR,
    textTransform: 'uppercase',
  },
  closeIcon: {
    position: 'absolute',
    top: 10,
    left: 10,
    cursor: 'pointer',
  },
  parent: {
    paddingTop: 30,
    position: 'relative',
  },
  drawer: {
    marginTop: 64,
  },
  childContainer: {
    marginLeft: 20,
    marginRight: 20,
  },
};

export class ActionMenu extends Component {
  static propTypes = {
    title: PropTypes.string,
    open: PropTypes.bool.isRequired,
    children: PropTypes.any,
  };

  render() {
    return (
      <Drawer
        disableSwipeToOpen={true}
        open={this.props.open}
        docked={false}
        containerStyle={styles.drawer}
        width={350}
        openSecondary={true}>
        <div style={styles.parent}>
          <FontAwesome onClick={() => this.props.onRequestClose()} style={styles.closeIcon} name="times" />
          <div style={styles.title}>{this.props.title}</div>
        </div>
        <div style={styles.childContainer}>
          {this.props.open ? this.props.children : null}
        </div>
      </Drawer>
    );
  }
}
