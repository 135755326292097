import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, List, ListItem, TextField } from 'material-ui';

export class UsersPickerView extends Component {
  static propTypes = {
    updateFilter: PropTypes.func,
    onItemChange: PropTypes.func,
    checkedItems: PropTypes.array,
    search: PropTypes.string,
    skip: PropTypes.number,
    limit: PropTypes.number,
    students: PropTypes.shape({
      error: PropTypes.any,
      loading: PropTypes.bool,
      allStudents: PropTypes.shape({
        total: PropTypes.number,
        totalForFilter: PropTypes.number,
        items: PropTypes.arrayOf(PropTypes.any)
      })
    })
  };

  render() {
    const showList =
      this.props.students &&
      this.props.students.allStudents &&
      this.props.students.allStudents.items;


    return (
      <div style={{ maxHeight: '22vh', overflowY: 'scroll'}}>
        <List>
          <ListItem isKeyboardFocused={true} disabled={true}>
            <TextField
              value={this.props.search}
              hintText='Find Participants...'
              onChange={(event, newValue) =>
                this.props.updateFilter({ skip: 0, search: newValue })
              }
            />
          </ListItem>
          {showList
            ? this.props.students.allStudents.items.map((student, key) => (
                <ListItem
                  key={key}
                  leftCheckbox={
                    <Checkbox
                      onCheck={(e, checked) =>
                        this.props.onItemChange(`user_${student.id}`, checked)
                      }
                      checked={this.props.checkedItems.includes(
                        `user_${student.id}`
                      )}
                    />
                  }
                  primaryText={student.name}
                  secondaryText={student.major}
                />
              ))
            : null}
        </List>
      </div>
    );
  }
}
