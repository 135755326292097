import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StepLabel, Stepper, Step } from 'material-ui';

export class Wizard extends Component {
  static propTypes = {
    steps: PropTypes.arrayOf(PropTypes.any).isRequired,
    step: PropTypes.number,
  };

  constructor(props) {
    super(props);

    this.state = {
      currentStep: props.step || 0,
    };
  }

  render() {
    const Content = this.props.steps[this.state.currentStep];

    return (
      <div>
        <Stepper activeStep={this.state.currentStep}>
          {
            this.props.steps.map((definition, i) => (
              <Step key={i}>
                <StepLabel>{definition.title} </StepLabel>
              </Step>
            ))
          }
        </Stepper>
        <Content.component {...Content.props || {}} />
      </div>
    );
  }
}
