import { graphql, compose } from 'react-apollo';
import { approvePhotoMutation } from '../../students/graphql/approve-photo.mutation';
import { rejectPhotoMutation } from '../../students/graphql/reject-photo.mutation';
import { PhotoApprovalDialogView } from './photo-approval-dialog.component';

const withApproveMutation = graphql(approvePhotoMutation, {
  options: {
    refetchQueries: ['waitingPhotoApprovals', 'statistics'],
  },
  props: ({ ownProps, mutate }) => ({
    approve: approvalId => mutate({ variables: { approvalId } }),
  }),
});

const withRejectMutation = graphql(rejectPhotoMutation, {
  options: {
    refetchQueries: ['waitingPhotoApprovals', 'statistics'],
  },
  props: ({ ownProps, mutate }) => ({
    reject: (approvalId, rejectionReason) =>
      mutate({ variables: { rejectionReason, approvalId } }),
  }),
});

export const PhotoApprovalDialog = compose(
  withApproveMutation,
  withRejectMutation,
)(PhotoApprovalDialogView);
