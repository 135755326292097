import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import { AutoComplete, FlatButton } from 'material-ui';
import { DataTable } from '../../common/components/data-table.component';
import { FontAwesome } from '../../common/components/font-awesome';
import { ProfileAvatar } from '../../common/components/profile-avatar.component';

const styles = {
  actionIcon: {
    cursor: 'pointer',
    fontSize: 12,
    marginRight: 10
  },
  input: {
    marginLeft: 20
  }
};

export class ManageCourseStudentsDialogView extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    course: PropTypes.any,
    currentStudents: PropTypes.arrayOf(PropTypes.any),
    open: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    updateSearch: PropTypes.func.isRequired,
    removeStudentFromCourse: PropTypes.func.isRequired,
    addStudentToCourse: PropTypes.func.isRequired,
    search: PropTypes.string.isRequired,

    students: PropTypes.shape({
      error: PropTypes.any,
      loading: PropTypes.bool,
      allStudents: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.any)
      })
    })
  };

  addItemToCourse(userToAdd) {
    if (userToAdd && userToAdd.id) {
      this.props.addStudentToCourse({
        userId: userToAdd.id,
        courseId: this.props.course.id
      });
      this.props.updateSearch('');
    }
  }

  remove(user) {
    this.props.removeStudentFromCourse({
      userId: user.id,
      courseId: this.props.course.id
    });
  }

  render() {
    if (!this.props.course) {
      return null;
    }

    const actions = [
      <FlatButton
        label='Close'
        onClick={() => this.props.onRequestClose()}
        onTouchTap={() => this.props.onRequestClose()}
      />
    ];

    const tableDesc = [
      {
        headerName: '',
        width: 15,
        render: item => <ProfileAvatar src={item.profilePictureUrl} />
      },
      { headerName: 'Participant Name', width: 65, valueFrom: 'name' }
    ];

    if (!this.props.disabled) {
      tableDesc.push({
        headerName: 'Actions',
        width: 20,
        render: item => (
          <FontAwesome
            style={styles.actionIcon}
            onClick={() => this.remove(item)}
            name='trash'
          />
        )
      });
    }

    return (
      <Dialog
        autoDetectWindowHeight={false}
        title={`${this.props.disabled ? 'View' : 'Manage'} Course: ${
          this.props.course.fullName
        }`}
        actions={actions}
        modal={false}
        open={this.props.open}
        onRequestClose={() => this.props.onRequestClose()}
      >
        {this.props.disabled ? null : (
          <div>
            Find user to add:
            <AutoComplete
              filter={() => true}
              style={styles.input}
              dataSourceConfig={{
                text: 'name',
                value: 'id'
              }}
              searchText={this.props.search}
              onNewRequest={(chosenRequest, index) =>
                this.addItemToCourse(chosenRequest, index)
              }
              onUpdateInput={searchText => this.props.updateSearch(searchText)}
              floatingLabelText='Name / username / email'
              dataSource={
                this.props.students.loading || this.props.students.error
                  ? []
                  : this.props.students.allStudents.items || []
              }
            />
          </div>
        )}
        <DataTable
          height='200px'
          loading={this.props.loading || this.props.students.loading}
          skip={0}
          limit={0}
          total={this.props.course.students.length}
          idField='id'
          data={this.props.course.students}
          description={tableDesc}
        />
      </Dialog>
    );
  }
}
