import gql from 'graphql-tag';

export const allCoursesQuery = gql`
  query allCourses(
    $search: String
    $skip: Int
    $limit: Int
    $perferedIds: [String]
  ) {
    allCourses(
      search: $search
      skip: $skip
      limit: $limit
      perferedIds: $perferedIds
    ) {
      total
      totalForFilter
      items {
        id
        teacher {
          lastName
          fullName
          profilePictureUrl
          firstName
        }
        fullName
        name
        identifier
        isManual
        students {
          id
          username
          name
          profilePictureUrl
        }
      }
    }
  }
`;
