import { kebabCase } from 'lodash'
import { FloatingActionButton } from 'material-ui';
import TextField from 'material-ui/TextField';
import PropTypes from 'prop-types';
import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import { DataTable } from '../../common/components/data-table.component';
import { FiltersBar } from '../../common/components/filters-bar.component';
import { FontAwesome } from '../../common/components/font-awesome';
import { StatisticsBar } from '../../common/components/statistics-bar.component';
import { YesNoModal } from '../../common/components/yes-no-modal.component';

const styles = {
  addButton: {
    float: 'right',
  },
  actionIcon: {
    cursor: 'pointer',
    fontSize: 12,
    marginRight: 10,
  },
  table: {
    marginLeft: '3%',
    marginRight: '3%',
  },
};

export class FeedItemsView extends Component {
  static propTypes = {
    options: PropTypes.any,
    title: PropTypes.string.isRequired,
    stats: PropTypes.func.isRequired,
    tableDesc: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    skip: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    search: PropTypes.string.isRequired,
    updateFilter: PropTypes.func.isRequired,
    statisticsData: PropTypes.any,
    removeFeedItem: PropTypes.func.isRequired,
    unpublish: PropTypes.func.isRequired,
    error: PropTypes.any,
    loading: PropTypes.bool,
    data: PropTypes.shape({
      error: PropTypes.any,
      loading: PropTypes.bool,
      allFeedItems: PropTypes.shape({
        totalForFilter: PropTypes.number,
        items: PropTypes.arrayOf(PropTypes.any),
      }),
    }),
  };

  constructor() {
    super();

    this.state = {
      itemToRemove: null,
      itemToUnpublish: null,
    };
  }

  updatePagination(nextData) {
    if (nextData) {
      this.props.updateFilter({ skip: this.props.skip + this.props.limit });
    } else {
      let newSkip = this.props.skip - this.props.limit;

      if (newSkip < 0) {
        newSkip = 0;
      }

      this.props.updateFilter({ skip: newSkip });
    }
  }

  resetFilter() {
    this.props.updateFilter({ skip: 0, search: '' });
  }

  updateSearch(newValue) {
    this.props.updateFilter({ skip: 0, search: newValue });
  }

  removeModalResult(doRemove) {
    const item = this.state.itemToRemove;

    this.setState({
      itemToRemove: null,
    });

    if (doRemove) {
      this.props.removeFeedItem(item.id);
    }
  }

  handleUnPublishResult(doUnpublish) {
    const item = this.state.itemToUnpublish;

    this.setState({
      itemToUnpublish: null,
    });

    if (doUnpublish) {
      this.props.unpublish(item.id);
    }
  }

  render() {
    const hasItems = (this.props.data.allFeedItems && this.props.data.allFeedItems.items);
    const stats = this.props.stats(this.props.statisticsData);

    const tableDesc = [
      {
        headerName: '',
        width: 5,
        render: item => (item.mainCategory ?
          <img style={{ maxWidth: 30 }} src={item.mainCategory.icon} alt={item.mainCategory.name}/> : null),
      },
      ...this.props.tableDesc(this),
      {
        headerName: 'Visible?',
        width: 7,
        render: item => (item.platforms.includes('NEWS_FEED') ? 'Yes' : 'No'),
      },
      {
        headerName: 'Actions',
        width: 18,
        rowStyle: {
          overflow: 'visible',
        },
        render: item => (
          <div>
            {
              this.props.options.readonly || item.readonly ? null : (
                <Fragment>
                  <Link to={`${this.props.title.toLowerCase()}/edit/${item.id}`}>
                    <FontAwesome tooltip="Edit" style={styles.actionIcon} name="pencil-square-o"/>
                  </Link>
                  <FontAwesome
                    tooltip="Remove"
                    style={styles.actionIcon}
                    onClick={() => this.setState({ itemToRemove: item })}
                    name="trash"/>
                </Fragment>
              )
            }
            {
              item.published ? (
                <FontAwesome
                  onClick={() => this.setState({ itemToUnpublish: item })}
                  tooltip="Unpublish"
                  style={styles.actionIcon}
                  name="ban"/>
              ) : (
                <Link to={`${kebabCase(this.props.title)}/publish/${item.id}`}>
                  <FontAwesome tooltip="Publish" style={styles.actionIcon} name="paper-plane"/>
                </Link>
              )
            }
            {this.props.extraActions(item, this)}
          </div>
        ),
      },
    ];
    const totalForFilter = hasItems ? this.props.data.allFeedItems.totalForFilter : 0;
    const items = hasItems ? this.props.data.allFeedItems.items : [];

    return (
      <div>
        <YesNoModal
          title="Are you sure?"
          open={this.state.itemToRemove !== null}
          onNo={() => this.removeModalResult(false)}
          onYes={() => this.removeModalResult(true)}>
          {
            this.state.courseToRemove === null ? null : (
              <div>
                This will <strong>REMOVE</strong> the item completely (it won't cancel it!) <br/>
              </div>
            )
          }
        </YesNoModal>
        <YesNoModal
          title="Are you sure?"
          open={this.state.itemToUnpublish !== null}
          onNo={() => this.handleUnPublishResult(false)}
          onYes={() => this.handleUnPublishResult(true)}>
          {
            this.state.itemToUnpublish === null ? null : (
              <div>
                This will cause the item to be <strong>Unpublished</strong>, and it won't be visible on student's mobile
                app feed / Xibo
                feed, until you publish it again.<br/>
              </div>
            )
          }
        </YesNoModal>
        <StatisticsBar data={stats}/>
        <FiltersBar title={this.props.title}>
          <TextField
            hintText={`Search ${this.props.title}`}
            value={this.props.search}
            onChange={(event, newValue) => this.updateSearch(newValue)}/>
          {
            this.props.options.hideAddButton ? null : (
              <Link to={`${this.props.title.toLowerCase()}/create`}>
                <FloatingActionButton
                  style={styles.addButton}>
                  <FontAwesome name="plus"/>
                </FloatingActionButton>
              </Link>
            )
          }
        </FiltersBar>
        <DataTable
          loading={this.props.data.loading}
          skip={this.props.skip}
          limit={this.props.limit}
          total={totalForFilter}
          onNext={() => this.updatePagination(true)}
          onPrev={() => this.updatePagination(false)}
          onResetFilter={() => this.resetFilter()}
          style={styles.table}
          idField="id"
          data={items}
          description={tableDesc}/>
      </div>
    );
  }
}
