import gql from 'graphql-tag';
import { studentFragment } from './students.fragment';

export const allStudentsQuery = gql`
  query AllStudents($search: String, $skip: Int, $limit: Int, $perferedIds: [String], $filterType: UsersFilterType) {
    allStudents(search: $search, skip: $skip, limit: $limit, perferedIds: $perferedIds, filterType: $filterType) {
      totalForFilter
      total
      items {
        ...StudentInfo
      }
    }
  }
  ${studentFragment}
`;
