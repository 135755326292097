import { connect } from 'react-redux';
import { compose, graphql } from 'react-apollo';
import { StudentsView } from './students.component';
import { withStatistics } from '../../common/components/with-statistics.container';
import { updateStudentsFilter, editEntity } from '../students.actions';
import { allStudentsQuery } from '../graphql/allStudents.query';
import { deleteStudentMutation } from '../graphql/deleteStudent.mutation';

const mapStateToProps = ({ students }) => ({
  skip: students.skip,
  limit: students.limit,
  search: students.search,
  filterType: students.filterType,
  editModalOpen: students.isEditMode,
  editModeEntity: students.editEntity,
});

const mapDispatch = dispatch => ({
  updateFilter: filterChanges => dispatch(updateStudentsFilter(filterChanges)),
  toggleEditMode: entity => dispatch(editEntity(entity)),
});

const withState = connect(
  mapStateToProps,
  mapDispatch,
);

const withData = graphql(allStudentsQuery, {
  name: 'students',
  options: props => {
    let extraVars = {
      perferedIds: [],
    };

    if (props.search === '' && props.checkedItems && props.checkedItems.length > 0) {
      extraVars = {
        perferedIds: props.checkedItems.map(group => (group.startsWith('user_') ? group.replace('user_', '') : null)).filter(item => item),
      };
    }

    return {
      fetchPolicy: 'network-only',
      variables: {
        skip: props.skip,
        limit: props.limit,
        search: props.search,
        filterType: props.filterType,
        ...extraVars,
      },
    };
  },
});

export const withStudentsList = compose(
  withState,
  withData,
);

export const withDeleteStudent = graphql(deleteStudentMutation, {
  props: ({ mutate }) => ({
    deleteStudent: studentId => mutate({ variables: { studentId } }),
  }),
  options: {
    refetchQueries: ['AllStudents', 'statistics'],
  },
});

export const Students = withDeleteStudent(withStatistics(withStudentsList(StudentsView)));
