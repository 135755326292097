import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FlatButton, TextField } from 'material-ui';
import { ImageButtonPicker } from '../../common/components/image-button-picker.component';
import { FontAwesome } from '../../common/components/font-awesome';

export class SendMessageInput extends Component {
  static propTypes = {
    onSendMessage: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    disablePicture: PropTypes.bool,
    skipClearOnSend: PropTypes.bool,
    content: PropTypes.string,
  };

  constructor() {
    super();

    this.state = {
      content: '',
    };
  }

  componentDidMount() {
    if (this.props.content) {
      this.setState({ content: this.props.content });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.content && nextProps.content !== this.props.content) {
      this.setState({ content: nextProps.content });
    }
  }

  render() {
    return (
      <div style={{ flexDirection: 'row', display: 'flex' }}>
        {
          !this.props.disablePicture && (
            <div>
              <ImageButtonPicker
                label={<FontAwesome name="file-image-o" />}
                onFile={data => this.props.onSendMessage('IMAGE', data)} />
            </div>
          )
        }
        <TextField
          value={this.state.content}
          onChange={(event, newValue) => this.setState({ content: newValue })}
          multiLine={true}
          rows={1}
          ref={ref => this._input = ref}
          fullWidth={true}
          rowsMax={2}
          hintText="Type a message" />
        <div style={{ paddingTop: 10 }}>
          <FlatButton
            onClick={() => {
              this.props.onSendMessage('TEXT', this.state.content);

              if (!this.props.skipClearOnSend) {
                this.setState({ content: '' });
              }

              if (this._input) {
                this._input.focus();
              }
            }}
            disabled={this.state.content === '' || this.props.disabled}
            label="SEND" />
        </div>
      </div>
    );
  }
}
