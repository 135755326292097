import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme } from '../../theme/theme';

const styles = {
  textValue: {
    textAlign: 'center',
    fontSize: 22,
    marginBottom: 10,
  },
  separator: {
    borderRight: `1px ${Theme.DESCRIPTION_TEXT_COLOR} solid`,
  },
  nameValue: {
    textAlign: 'center',
    textTransform: 'uppercase',
    color: Theme.DESCRIPTION_TEXT_COLOR,
    fontSize: 12,
  },
  itemContainer: {
    padding: 5,
    display: 'inline-block',
  },
  statsContainer: {
    textAlign: 'center',
    width: '94%',
    marginHorizontal: '3%',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    marginTop: 10,
    marginBottom: 10,
  },
};

export class StatisticsBar extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
      color: PropTypes.string,
    })).isRequired,
  };

  render() {
    return (
      <div style={styles.statsContainer}>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
          {
            this.props.data.map((statItem, i) => (
              <div
                onClick={() => statItem.onClick ? statItem.onClick(statItem) : null}
                style={{
                  ...(statItem.highlight ? { backgroundColor: 'rgba(43, 188, 243, 0.1)' } : { backgroundColor: 'white' }),
                  ...styles.itemContainer,
                  ...(statItem.onClick ? { cursor: 'pointer' } : {}),
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  ...(i === this.props.data.length - 1 ? {} : styles.separator),
                }}
                key={i}>
                <div style={{ ...styles.textValue, color: statItem.color }}>{statItem.value}</div>
                <div style={styles.nameValue}>{statItem.name}</div>
              </div>
            ))
          }
        </div>
      </div>
    );
  }
}
