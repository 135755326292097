import { graphql, compose } from 'react-apollo';
import { approveProfilePictureMutation } from '../../students/graphql/approve-profile-picture.mutation';
import { rejectProfilePictureMutation } from '../../students/graphql/reject-profile-picture.mutation';
import { ProfilePictureApprovalDialogView } from './profile-pic-approval-dialog.component';

const withApproveMutation = graphql(approveProfilePictureMutation, {
  options: {
    refetchQueries: ['waitingProfilePictureApprovals', 'statistics'],
  },
  props: ({ ownProps, mutate }) => ({
    approve: approvalId => mutate({ variables: { approvalId } }),
  }),
});

const withRejectMutation = graphql(rejectProfilePictureMutation, {
  options: {
    refetchQueries: ['waitingProfilePictureApprovals', 'statistics'],
  },
  props: ({ ownProps, mutate }) => ({
    reject: (approvalId, rejectionReason) => mutate({ variables: { rejectionReason, approvalId } }),
  }),
});

export const ProfilePictureApprovalDialog = compose(
  withApproveMutation,
  withRejectMutation,
)(ProfilePictureApprovalDialogView);
