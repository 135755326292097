import gql from 'graphql-tag';

export const newsFeedNewsFragment = gql`
  fragment NewsFields on NewsFeedNewsItem {
    id
    published
    categories {
      id
      icon
      name
    }
    mainCategory {
      id
      icon
      name
    }
    author {
      id
      name
      profilePictureUrl
    }
    displayAuthor {
      name
      profilePictureUrl
      id
    }
    title
    description
    link
    posterImageUrl
    startTime
    endTime
    targets
    platforms
    readonly
  }
`;
