import React, { Component } from 'react';
import { Dialog, FlatButton } from 'material-ui';
import { GridList, GridTile } from 'material-ui';
import { Theme } from '../../theme/theme';
import PieChart from '../../students/components/pieChart.component';
import MultiLineChart from '../../students/components/lineChart.component';
import { Loading } from '../../common/components/loading.component';

const CONTENT_HEIGHT = 650;

const styles = {
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  infoSpan: {
    marginRight: 10,
    fontWeight: 'bold'
  },
  details: {
    marginLeft: 20
  },
  tableContainer: {
    marginTop: 10
  },
  infoText: {
    userSelect: 'text'
  },
  gridList: {
    width: '100%',
  },
  gridContainer: {
    display: 'flex',
    // flexWrap: 'wrap',
    justifyContent: 'space-around',
    marginLeft: '3%',
    marginRight: '3%',
  },
};



const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];


export class allUsersStatisticsModal extends Component {

    constructor(props) {
      super(props);
  
      this.state = {
        params: [],
        data: null,
        watchPieData: null,
        loading: true
      }
    }

   

    buildArray = () => {
      console.log("data arrived!", this.props.allUsersStatistics.allUsersStatistics)
      this.setState({ loading: true })
      let data = this.props.allUsersStatistics.allUsersStatistics
      let params = [
        { name: "User Logins", value: data.loginCount },
        { name: "Yogi Clickes", value: data.yogiClicks },
        { name: "User Sympthoms", value: data.allChatsSympthoms.length },
        { name: "User Reactions", value: data.userLikesCount },
        { name: "User Comments", value: data. userCommentCount },
        { name: "Chat Use", value: data.usingChats ? "Yes": "No"},
        { name: "Yogi Use", value: data.yogiClicks > 0 ? "Yes": "No"},
        { name: "Friends Use", value: data.usingGroups ? "Yes": "No"},
        { name: "Likes Use", value: data.usingLikes ? "Yes": "No"},
        { name: "Live Streaming Use", value: data.usingLiveStreaming ? "Yes": "No"},
        { name: "Modifications Use", value: data.usingModifications ? "Yes": "No"},
        { name: "Poses Use", value: data.usingPoses ? "Yes": "No"},
        { name: "Sessions Use", value: data.usingSessions ? "Yes": "No"},
        { name: "Upload Post Use", value: data.usingPost ? "Yes": "No"},
        { name: "Yoga Science Use", value: data.usingYogaScience ? "Yes": "No"},
        { name: "Videos Modification Use", value: data.usingYogaModification? "Yes": "No"},
        { name: "Videos Poses Use", value: data.usingYogaPoses? "Yes": "No"},
        { name: "Videos Sessions Use", value: data.usingYogaSessions? "Yes": "No"},
      ]

      let watchPieData = [
        { name: 'Watch Now', value: data.watchNow },
        { name: 'Watch Later', value: data.watchLater },
      ]

      this.setState({ watchPieData, data, params, loading: false })
     
    }

  

    render() {
      console.log("props inside render stat =>", this.props)
      if(this.props.allUsersStatistics.allUsersStatistics !== null && this.props.allUsersStatistics.allUsersStatistics !== undefined && this.state.params.length === 0) {
        this.buildArray()
      }
      if (this.state.loading) {
        return <Loading />;
      }
     
   

        return (
           
              <div style={{...styles.gridContainer,  flexDirection: 'column', height: '80vh', overflow: 'scroll'}}>
                <GridList cellHeight={CONTENT_HEIGHT} style={styles.gridList}>
                  <GridTile rows={1} cols={2}>
                    <div style={{ flexDirection: 'row', display: 'flex', flexWrap: 'wrap' }}>
                    {
                      this.state.params.map((resultData, index) => (
                        <div
                          key={index}
                          style={{
                            paddingBottom: 40, width: '33%', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column',
                          }}>
                          <div style={{
                            textAlign: 'center', fontSize: 14, fontWeight: 'bold', color: Theme.PRIMARY_COLOR,
                          }}>{resultData.name}</div>
                          <div style={{
                            textAlign: 'center', fontSize: 34, paddingTop: 5, color: Theme.SECONDARY_TEXT_COLOR,
                          }}>{resultData.value}</div>
                        </div>
                      ))
                    }
                  
                     
                    </div>
                  </GridTile>
                </GridList>
                <div style={{ display: 'flex'}}>
                {
                    this.state.data !== null &&
                    <PieChart 
                      firstValue={this.state.data.watchNow}
                      secondValue={this.state.data.watchLater}
                      firstLabel={"Watch Now"}
                      secondLabel={"Watch Later"}
                    />
                }
                {
                    this.state.data !== null &&
                    <PieChart 
                      firstValue={this.state.data.postsWithMedia}
                      secondValue={this.state.data.postsWithoutMedia}
                      firstLabel={"Posts with media"}
                      secondLabel={"Posts without media"}
                    />
                }
                </div>
                <div style={{ display: 'flex'}}>
                {
                    this.state.data !== null &&
                    <PieChart 
                      firstValue={this.state.data.finishedVideo}
                      secondValue={this.state.data.notFinishedVideo}
                      firstLabel={"Video finished"}
                      secondLabel={"Video not finished"}
                    />
                } 
                {
                  this.state.data !== null &&
                  <PieChart 
                    firstValue={this.state.data.usefulVideo}
                    secondValue={this.state.data.notUsefulVideo}
                    firstLabel={"Video Useful"}
                    secondLabel={"Video not useful"}
                  />
                }
                </div>
                {
                  this.state.data !== null &&
                  <MultiLineChart data={this.state.data.allChatsSympthoms} />
                }
                {
                   this.state.data !== null &&
                   <div>
                     <label style={{ fontWeight: 'bold'}}>Written materials:</label>
                     {
                       this.state.data.manualNames.map((manual) => (
                         <div>• {manual}</div>
                       ))
                     }
                   </div>
                }
              </div>
        )
        
    }
}