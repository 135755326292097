import gql from 'graphql-tag';

export const newsFeedSportsEventFragment = gql`
  fragment SportsEventFields on NewsFeedSportItem {
    id
    published
    title
    categories {
      id
      icon
      name
    }
    mainCategory {
      id
      icon
      name
    }
    allDay
    cancelled
    description
    locationName
    posterImageUrl
    startTime
    author {
      name
      profilePictureUrl
    }
    remindersCount
    targets
    platforms
    score
    type
    gender
    link
  }
`;
