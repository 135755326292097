import { SubscriptionClient, addGraphQLSubscriptions } from 'subscriptions-transport-ws2';
import ApolloClient, { createNetworkInterface } from 'apollo-client2';
import config from '../config/config';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import introspectionQueryResultData from '../network/fragmentTypes.json';

const port = config.server.port > 0 ? `:${config.server.port}` : '';

const networkInterface = createNetworkInterface({
  uri: `${config.server.protocol}://${config.server.host}${port}${config.server.path}`,
});



networkInterface.use([
  {
    applyMiddleware(req, next) {
      setTimeout(next, 500);
    },
  },
]);




const newUrl = 'Be.YOCAS.life:4000/'//'Be.YOCAS.life:4000/'//'localhost:4000/' 
const endPoint = newUrl === 'localhost:4000/' ? `ws://${newUrl}subscriptions` : `wss://${newUrl}subscriptions`;


const wsClient = new SubscriptionClient(endPoint, {
  reconnect: true
});



const networkInterfaceWithSubscriptions = addGraphQLSubscriptions(networkInterface, wsClient);

const cache = new InMemoryCache({
  addTypename: false,
  fragmentMatcher: new IntrospectionFragmentMatcher({
    introspectionQueryResultData
  }),
})


export const apolloClient = new ApolloClient({
    networkInterface: networkInterfaceWithSubscriptions,
    cache
  });



