import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, List, ListItem, TextField } from 'material-ui';

export class CoursesPickerView extends Component {
  static propTypes = {
    updateFilter: PropTypes.func,
    onItemChange: PropTypes.func,
    checkedItems: PropTypes.array,
    search: PropTypes.string,
    skip: PropTypes.number,
    limit: PropTypes.number,
    courses: PropTypes.shape({
      error: PropTypes.any,
      loading: PropTypes.bool,
      allCourses: PropTypes.shape({
        total: PropTypes.number,
        totalForFilter: PropTypes.number,
        items: PropTypes.arrayOf(PropTypes.any)
      })
    })
  };

  render() {
    const showList =
      this.props.courses &&
      this.props.courses.allCourses &&
      this.props.courses.allCourses.items;

    return (
      <div>
        <List>
          <ListItem isKeyboardFocused={true} disabled={true}>
            <TextField
              value={this.props.search}
              hintText='Find groups...'
              onChange={(event, newValue) =>
                this.props.updateFilter({ skip: 0, search: newValue })
              }
            />
          </ListItem>
          {showList
            ? this.props.courses.allCourses.items.map((course, key) => (
                <ListItem
                  key={key}
                  leftCheckbox={
                    <Checkbox
                      onCheck={(e, checked) =>
                        this.props.onItemChange(`course_${course.id}`, checked)
                      }
                      checked={this.props.checkedItems.includes(
                        `course_${course.id}`
                      )}
                    />
                  }
                  primaryText={`${course.identifier} (${course.fullName})`}
                  secondaryText={`${
                    course.teacher ? `${course.teacher.fullName}, ` : ''
                  }${course.students} students`}
                />
              ))
            : null}
        </List>
      </div>
    );
  }
}
