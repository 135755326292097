import { graphql } from 'react-apollo';
import { createStudentMutation } from '../graphql/createStudent.mutation';
import { CreateStudentVariables } from '../../ApolloComponents';
import { CreateEditStudentFormView } from './create-edit-student-form.component';

const withMutation = graphql(createStudentMutation, {
  options: {
    refetchQueries: ['AllStudents', 'statistics'],
  },
  props: ({ mutate }) => ({
    createEditStudent: (studentData: CreateStudentVariables) => mutate!({ variables: studentData }),
  }),
});

export const CreateEditStudentForm = withMutation(CreateEditStudentFormView as any) as any;
