import { graphql } from 'react-apollo';
import { modifySocialNetworkMutation } from '../graphql/modify-social-network.mutation';
import { StudentSocialNetworksModalView } from './student-social-networks-modal.component';

const withSocialNetworkMutation = graphql(modifySocialNetworkMutation, {
  options: {
    refetchQueries: ['statistics'],
  },
  props: ({ mutate }) => ({
    modify: (variables: any) => mutate!({ variables }),
  }),
});

export const StudentSocialNetworksModal = withSocialNetworkMutation(StudentSocialNetworksModalView as any) as any;
