import { graphql } from 'react-apollo';
import { categoriesQuery } from '../../common/graphql/categories.query';
import { CategoriesPickerView } from './categories-picker.component';

export const withCategories = graphql(categoriesQuery, {
  name: 'categoriesData',
  options: {
    fetchPolicy: 'network-only',
  },
});

export const CategoriesPicker = withCategories(CategoriesPickerView);
