import gql from 'graphql-tag';

export const createVideoMutation = gql`
  mutation createVideo(
    $existingId: String
    $order: String
    $name: String!
    $data: String
    $label: String!
    $duration: String
    $previewImage: String
    $yocasType: String
    $isUpdate: Boolean
  ) {
    createVideo(
      video: {
        existingId: $existingId
        order: $order
        name: $name
        data: $data
        label: $label
        duration: $duration
        previewImage: $previewImage
        yocasType: $yocasType
        isUpdate: $isUpdate
      }
    ) {
      id
      order
      name
      label
      data
    }
  }
`;
