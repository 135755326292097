import gql from 'graphql-tag';

export const userStatisticsQuery = gql`
 query($userId: ID){
    userStatistics(userId: $userId) { 
        loginCount
        yogiClicks
        allChatsSympthoms {
            name
            severity
            clickedAt
        }
        watchNow
        watchLater
        userLikesCount
        userCommentCount
        postsWithMedia
        postsWithoutMedia
        usingLiveStreaming
        usingYogaPoses
        usingYogaSessions
        usingYogaModification
        usingGroups
        usingChats
        usingYogaScience
        usingLikes
        usingPost
        usingModifications
        usingPoses
        usingSessions
        finishedVideo
        notFinishedVideo
        usefulVideo
        notUsefulVideo
        manualNames
    }
 }
`