import { getConnection } from '../network/connection';

export let client;

export let serverUrl;

export const initClient = config => {
  if (!client) {
    const port = config.server.port > 0 ? `:${config.server.port}` : '';
    serverUrl = `${config.server.protocol}://${config.server.host}${port}`;
    client = getConnection(
      `${serverUrl}${config.server.path}`,
      `${config.server.protocol === 'http' ? 'ws' : 'wss'}://${config.server.host}${port}/subscriptions`,
    );
  }

  return client;
};

export const getClient = () => client;
