import { graphql } from 'react-apollo';
import { StudentStatisticsModalView } from './student-statistics-modal.component';
import { userStatisticsQuery } from '../graphql/userStatistics.query';

const withUserStatisticsQuery = graphql(userStatisticsQuery, {
  name: 'userStatistics',
  options: (props: any) => {
    const userId = props.student && props.student.id;

    return {
      fetchPolicy: 'cache-and-network',
      skip: !userId,
      variables: {
        userId
      }
    };
  }
});

export const StudentStatisticsForm = withUserStatisticsQuery(
  StudentStatisticsModalView as any
) as any;
