import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Checkbox, DatePicker, RaisedButton, TextField } from 'material-ui';
import { CategoriesPicker } from '../../common/components/categories-picker.container';
import { DataBox } from '../../common/components/data-box.component';
import { Loading } from '../../common/components/loading.component';
import { ImageButtonPicker } from '../../common/components/image-button-picker.component';
import { FontAwesome } from '../../common/components/font-awesome';
import { AMPMTimePicker } from '../../common/components/time-picker.rn';
import { UserPickerDropdown } from '../../feed/components/user-dropdown.component';

const styles = {
  inline: {
    display: 'inline-block',
    marginHorizontal: 3,
  },
  table: {
    display: 'table',
    overflow: 'hidden',
    width: '100%',
  },
  fields: {
    width: '80%',
    display: 'table-cell',
  },
  poster: {
    display: 'table-cell',
    padding: 10,
  },
  posterImage: {
    maxWidth: 100,
  },
};

export class CreateUpdateNewsFormView extends Component {
  static propTypes = {
    editEntityId: PropTypes.string,
    editEntity: PropTypes.object,
    loading: PropTypes.bool,
    createUpdateNews: PropTypes.func.isRequired,
  };

  constructor() {
    super();

    this.state = {
      error: null,
      isUpdating: false,
      categories: [],
      mainCategory: null,
      title: '',
      description: '',
      posterImage: null,
      link: '',
      startDate: null,
      endDate: null,
      startTime: moment().format('hh:mm A'),
      endTime: moment().format('hh:mm A'),
      limitStart: false,
      limitEnd: false,
      displayAuthor: null,
    };
  }

  renderImage(image) {
    return image ? (
      <div>
        <img alt="" style={styles.posterImage} src={image} />
      </div>
    ) : (
      <div style={styles.posterPlaceholder}>
        <FontAwesome style={{ marginTop: 100 }} name="file-image-o" />
      </div>
    );
  }

  saveNews() {
    let {
      startDate, startTime, endDate, endTime,
    } = this.state;
    startTime = (startTime && startTime !== '') ? moment(startTime, 'hh:mm A').toDate() : null;
    endTime = (endTime && endTime !== '') ? moment(endTime, 'hh:mm A').toDate() : null;

    const mergedStartTime = startDate ? new Date(
      startDate.getFullYear(), startDate.getMonth(), startDate.getDate(),
      startTime ? startTime.getHours() : 0, startTime ? startTime.getMinutes() : 0, startTime ? startTime.getSeconds() : 0,
    ) : null;
    const mergedEndTime = endDate ? new Date(
      endDate.getFullYear(), endDate.getMonth(), endDate.getDate(),
      endTime ? endTime.getHours() : 0, endTime ? endTime.getMinutes() : 0, endTime ? endTime.getSeconds() : 0,
    ) : null;

    this.setState({ isUpdating: true });

    this.props.createUpdateNews(this.props.editEntityId, {
      posterImage: this.state.posterImage,
      link: this.state.link,
      title: this.state.title,
      description: this.state.description,
      categories: this.state.categories,
      mainCategory: this.state.mainCategory,
      displayAuthorId: this.state.displayAuthor ? this.state.displayAuthor.id : null,
      startTime: mergedStartTime ? moment.utc(mergedStartTime).toDate() : null,
      endTime: mergedEndTime ? moment.utc(mergedEndTime).toDate() : null,
    })
      .then(({ data }) => {
        this.setState({ isUpdating: false });
        this.props.navigate(`/admin/news/publish/${data.createUpdateNews.id}`);
      })
      .catch(e => this.setState({ isUpdating: false, error: e }));
  }

  componentDidMount() {
    const { editEntity } = this.props;
    this.setEditEntity(editEntity);
  }

  componentWillReceiveProps(nextProps) {
    const { editEntity } = nextProps;
    this.setEditEntity(editEntity);
  }

  setEditEntity(editEntity) {
    if (editEntity) {
      const startMoment = editEntity.startTime ? moment(editEntity.startTime) : null;
      const endMoment = editEntity.endTime ? moment(editEntity.endTime) : null;

      this.setState({
        categories: editEntity.categories.map(item => item.id),
        mainCategory: editEntity.mainCategory.id,
        title: editEntity.title,
        link: editEntity.link,
        description: editEntity.description,
        displayAuthor: editEntity.displayAuthor,
        posterImage: editEntity.posterImageUrl,
        startTime: editEntity.startTime ? startMoment.format('hh:mm A') : '',
        startDate: editEntity.startTime ? startMoment.toDate() : null,
        endTime: editEntity.endTime ? endMoment.format('hh:mm A') : '',
        endDate: editEntity.endTime ? endMoment.toDate() : null,
        limitStart: !!startMoment,
        limitEnd: !!endMoment,
      });
    }
  }

  isEmpty(field) {
    return !field || field === '';
  }

  isSubmitDisabled() {
    return this.isEmpty(this.state.link) ||
      this.isEmpty(this.state.title) ||
      this.isEmpty(this.state.description) ||
      this.state.mainCategory === null ||
      this.state.categories.length === 0;
  }

  render() {
    if (this.props.loading || this.state.isUpdating) {
      return (<Loading />);
    }

    return (
      <div style={{ paddingRight: '3%', paddingLeft: '3%', marginBottom: 20 }}>
        <DataBox title="News Item Details">
          <div style={{ width: '100%', textAlign: 'center', color: 'red' }}>
            {this.props.error || this.state.error}
          </div>
          <div style={styles.table}>
            <div style={styles.fields}>
              <TextField
                floatingLabelText="Link Title"
                value={this.state.title}
                fullWidth={true}
                onChange={(event, newValue) => this.setState({ title: newValue })}
              />
              <TextField
                floatingLabelText="Link Description"
                value={this.state.description}
                fullWidth={true}
                onChange={(event, newValue) => this.setState({ description: newValue })}
              />
              <TextField
                floatingLabelText="External URL"
                value={this.state.link}
                fullWidth={true}
                onChange={(event, newValue) => this.setState({ link: newValue })}
              />
              <CategoriesPicker
                categories={this.state.categories}
                mainCategory={this.state.mainCategory}
                onCategoriesChange={categories => this.setState({ categories })}
                onMainCategoryChange={mainCategory => this.setState({ mainCategory })} />
              <div style={{ marginTop: 10 }}>
                Override display author:
                <UserPickerDropdown fromMeetings={false} selectedUser={this.state.displayAuthor} onChange={user => this.setState({ displayAuthor: user })} />
              </div>
            </div>
            <div style={styles.poster}>
              {this.renderImage(this.state.posterImage)}
              <ImageButtonPicker
                label={`${this.state.posterImage ? 'Update' : 'Add'} Poster`}
                fullWidth={true}
                onFile={data => this.setState({ posterImage: data })} />
            </div>
          </div>
        </DataBox>
        <DataBox title="Limitations">
          You can pick the start date (and optional end date) to display this item on user's feed.<br /><br />

          <Checkbox
            label="Limit Start"
            checked={this.state.limitStart}
            onCheck={(e, newState) => this.setState({
              limitStart: newState,
              ...(newState === false) ? {
                startDate: null,
                startTime: '',
              } : {},
            })} />
          {
            this.state.limitStart ? (
              <div>
                <DatePicker
                  firstDayOfWeek={0}
                  style={styles.inline}
                  autoOk={true}
                  floatingLabelText="Start Date"
                  value={this.state.startDate}
                  onChange={(event, startDate) => this.setState({ startDate })}
                  container="inline"
                  mode="landscape" />
                <AMPMTimePicker
                  label="Start Time"
                  value={this.state.startTime}
                  onChange={(e, v) => this.setState({ startTime: v })} />
              </div>
            ) : null
          }
          <Checkbox
            label="Limit End"
            checked={this.state.limitEnd}
            onCheck={(e, newState) => this.setState({
              limitEnd: newState,
              ...(newState === false) ? {
                endTime: '',
                endDate: null,
              } : {},
            })} />
          {
            this.state.limitEnd ? (
              <div>
                <DatePicker
                  firstDayOfWeek={0}
                  style={styles.inline}
                  autoOk={true}
                  floatingLabelText="End Date"
                  value={this.state.endDate}
                  onChange={(event, endDate) => this.setState({ endDate })}
                  container="inline"
                  mode="landscape" />
                <AMPMTimePicker
                  label="End Time"
                  value={this.state.endTime}
                  onChange={(e, v) => this.setState({ endTime: v })} />
              </div>
            ) : null
          }

        </DataBox>

        <RaisedButton
          disabled={this.isSubmitDisabled()}
          onClick={() => this.saveNews()}
          primary={true}
          fullWidth={true}
          label="Continue" />
      </div>
    );
  }
}

