import React, { Component } from 'react';
import Chart from 'chart.js';
import moment from 'moment';

const colors = [
    { borderColor: 'rgba(255, 99, 132, 1)', backgroundColor: 'rgba(255, 99, 132, 0.5)' },
    { borderColor: 'rgba(54, 162, 235, 1)', backgroundColor: 'rgba(54, 162, 235, 0.5)' },
    { borderColor: 'rgba(255, 206, 86, 1)', backgroundColor: 'rgba(255, 206, 86, 0.5)' },
    { borderColor: 'rgba(75, 192, 192, 1)', backgroundColor: 'rgba(75, 192, 192, 0.5)' },
    { borderColor: 'rgba(153, 102, 255, 1)', backgroundColor: 'rgba(153, 102, 255, 0.5)' },
    { borderColor: 'rgba(255, 159, 64, 1)', backgroundColor: 'rgba(255, 159, 64, 0.5)' },
    { borderColor: 'rgba(199, 199, 199, 1)', backgroundColor: 'rgba(199, 199, 199, 0.5)' },
    { borderColor: 'rgba(163, 255, 118, 1)', backgroundColor: 'rgba(163, 255, 118, 0.5)' },
    { borderColor: 'rgba(255, 105, 180, 1)', backgroundColor: 'rgba(255, 105, 180, 0.5)' },
    { borderColor: 'rgba(255, 165, 0, 1)', backgroundColor: 'rgba(255, 165, 0, 0.5)' }
];


class MultiLineChart extends Component {
  constructor(props) {
    super(props);
    this.canvasRef = null;
    this.chart = null;
    
    this.state = {
        dates: null,
        datasets: null
    }
  }

  componentDidMount() {
    this.initializeChart();
    this.generateData()
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.destroy();
    }

  }

    generateData  = () => {
        let data = this.props.data
        let dates = []
        let sympthomsNames = []
        data.map(elem => {
            if(!sympthomsNames.includes(elem.name)) sympthomsNames.push(elem.name)
            dates.push(elem.clickedAt)
        })

        let datasets = []
        sympthomsNames.map((sympthom, idx) => {
            let severities = []
            let sympthomData = data.filter(elem => elem.name === sympthom)
            dates.sort((a, b) => new Date(a) - new Date(b));
            dates.map(date => {
                let filteredByDate = sympthomData.filter(elem => elem.clickedAt === date)
                if(filteredByDate.length > 0) {
                    let filtered = filteredByDate[0]
                    severities.push(parseInt(filtered.severity))
                } else {
                    severities.push(0)
                }
            })
            datasets.push({
                label: sympthom,
                data: severities,
                fill: false,
                borderColor: colors[idx].borderColor,
                backgroundColor: colors[idx].backgroundColor, 
                lineTension: 0.1
            })
        })

        console.log("datasets =>", datasets)

        let datesForGraph = []
        dates.map(date => {
            console.log("date =>", date)
            let modified = moment(date).format('MM/DD/YYYY HH:mm:ss')
            datesForGraph.push(modified)
        })
        console.log("dates =>", dates)
        console.log("datesForGroups =>", datesForGraph)
        this.setState({ datasets, dates: datesForGraph}, () => this.initializeChart())
    }

  initializeChart = () => {
    const ctx = this.canvasRef.getContext('2d');
    console.log("this.state.dates =>", this.state.dates)
    this.chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: this.state.dates,
        datasets: this.state.datasets
      },
      options: {scales: {
        xAxes: [{
            type: 'time',
            time: {
                parser: 'MM/DD/YYYY HH:mm:ss',
                tooltipFormat: 'lll',  // 'lll' for more detailed datetime info in tooltips
                displayFormats: {
                    minute: 'MM/DD/YYYY HH:mm'  // Adjusting format to show date and time
                }
            },
            scaleLabel: {
                display: true,
                labelString: 'Date and Time'
            },
            ticks: {
                autoSkip: false,
                maxRotation: 90,
                minRotation: 90
            }
        }]
    }
        }
    });
  }

  render() {
    return (
      <div style={{ width: '600px', height: '400px' }}>
        <canvas
          ref={element => this.canvasRef = element}
          width="600"
          height="400"
        />
      </div>
    );
  }
}

export default MultiLineChart;
