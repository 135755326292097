import gql from 'graphql-tag';

export const createManualCourseMutation = gql`
  mutation createManualCourse($existingId: String, $identifier: String!, $courseName: String!, $lecturerImage: String, $lecturerFirstName: String, $lecturerLastName: String) {
    createManualCourse(course: {
      existingId: $existingId,
      identifier: $identifier,
      courseName: $courseName,
      teacherLastName: $lecturerLastName,
      teacherFirstName: $lecturerFirstName,
      teacherImageBlob: $lecturerImage
    }) {
      id
      teacher {
        lastName
        fullName
        profilePictureUrl
        firstName
      }
      fullName
      name
      identifier
      isManual
    }
  }
`;
