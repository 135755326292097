import gql from 'graphql-tag';

export const statisticsQuery = gql`
  query statistics {
    statistics {
      averageEventsCategory
      averageEventsPerDay
      averageNewsCategory
      averagePollsCategory
      averageUsersPerCourse
      averageVotes
      courses
      coursesCreatedManually
      futureEvents
      profilePicApprovals
      publishedEvents
      publishedNews
      publishedPolls
      users
      withNonDefaultProfilePicture
    }
  }
`;
