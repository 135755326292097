import React, { Component } from 'react';
import { Checkbox, List, ListItem } from 'material-ui';
import { FiltersBar } from '../../common/components/filters-bar.component';
import TextField from 'material-ui/TextField';
import { FloatingActionButton, RaisedButton } from 'material-ui';
import { FontAwesome } from '../../common/components/font-awesome';
import {CreateMeetingModal} from './create-meeting-modal';
import { ActionMenu } from '../../common/components/action-menu.component';
import { DataTable } from '../../common/components/data-table.component';
import moment from 'moment';
import { apolloClient } from '../../core/apollo/apollo-subs';
import { meetingCreatedSubscription } from '../graphql/meeting-added.subscription';
import { updateMeetingStartedMutation } from '../graphql/update-host-joined.mutation';
import './meetings.css';

const jitsiContainerId = "jitsi-container-id";


export class Video extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    componentWillMount = () => {
        console.log("props video =>", this.props.match.params.name)
        let meetingName = this.props.match.params.name
        this.initialiseJitsi(meetingName)
    }

    loadJitsiScript = () => {
        let resolveLoadJitsiScriptPromise = null;

        const loadJitsiScriptPromise = new Promise(resolve => {
          resolveLoadJitsiScriptPromise = resolve;
        });
        const script = document.createElement("script");
        script.src = "https://meet.jit.si/external_api.js";
        script.async = true;
        script.onload = () => resolveLoadJitsiScriptPromise(true);
        document.body.appendChild(script);
        return loadJitsiScriptPromise;
    }

    initialiseJitsi = async (meetingName) => {
        const { data } = this.props;
        const { getUser: user } = data;


        if (!data || data.loading || !data.getUser) {
          return null;
        }
        console.log("user =>", user)

        console.log('room in jitsi =>', meetingName)
        if (!window.JitsiMeetExternalAPI) {
          await this.loadJitsiScript();
        }
     
        let roomName = meetingName
        roomName = roomName.replace(/'/g, "")
        roomName = roomName.replace(/&/g, "and")

        console.log("roomName =>", roomName)


        const _jitsi = new window.JitsiMeetExternalAPI("v.dualitmeestdomian.com", {
          parentNode: document.getElementById(jitsiContainerId),
          roomName,
          userInfo: {
            displayName: user.username 
          },
          configOverwrite: {
            disableAudioLevels: true,
          },
          interfaceConfigOverwrite: {
            TOOLBAR_ALWAYS_VISIBLE: true
          },
          onload: () => {
            console.log('video loaded!')
          },
        });

        _jitsi.addEventListener('videoConferenceLeft', async () => {
          console.log('call ended');
          _jitsi.executeCommand('hangup');
          this.props.history.push({
              pathname: '/admin/meetings',
             
          })
         
      });

    

        this.setState({ jitsi:_jitsi });

    }

    render(){
        return (
            <div className="video-container">
                 <div 
                        id={jitsiContainerId} 
                        style={{ 
                            height: "100%" , 
                            position:"fixed", 
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            // zIndex: 1020,
                        }} 
                    >
                
                    </div>
            </div>
        )
    }
}