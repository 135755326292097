import gql from 'graphql-tag';

export const createManualMutation = gql`
  mutation createManual(
    $existingId: String
    $name: String!
    $data: String
    $label: String!
  ) {
    createManual(
      manual: {
        existingId: $existingId
        name: $name
        data: $data
        label: $label
      }
    ) {
      id
      name
      label
      data
    }
  }
`;
