import gql from 'graphql-tag';

export const studentsQuery = gql`
  query Students($search: String) {
    allStudents(search: $search) {
      items {
        name
        profilePictureUrl
        id
      }
    }
  }
`;
