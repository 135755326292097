import { graphql, compose } from 'react-apollo';
import { allCoursesQuery } from '../graphql/all-courses.query';
import { CoursesView } from './courses.component';
import { connect } from 'react-redux';
import { updateCoursesFilter, editEntity } from '../courses.actions';
import { removeCourseMutation } from '../graphql/remove-course.mutation';
import { withStatistics } from '../../common/components/with-statistics.container';

const mapStateToProps = ({ courses }) => ({
  skip: courses.skip,
  limit: courses.limit,
  search: courses.search,
  editModalOpen: courses.isEditMode,
  editModeEntity: courses.editEntity,
});

const mapDispatch = dispatch => ({
  updateFilter: filterChanges => dispatch(updateCoursesFilter(filterChanges)),
  toggleEditMode: entity => dispatch(editEntity(entity)),
});

const withState = connect(mapStateToProps, mapDispatch);

const withData = graphql(allCoursesQuery, {
  name: 'courses',
  options: props => {
    let extraVars = {
      perferedIds: [],
    };

    if (props.search === '' && props.checkedItems && props.checkedItems.length > 0) {
      extraVars = {
        perferedIds: props.checkedItems.map(group => group.startsWith('course_') ? group.replace('course_', '') : null).filter(item => item),
      };
    }

    return {
      fetchPolicy: 'network-only',
      variables: {
        skip: props.skip,
        limit: props.limit,
        search: props.search,
        ...extraVars,
      },
    };
  },
});

export const withCourses = compose(withState, withData);

const withMutation = graphql(removeCourseMutation, {
  options: {
    refetchQueries: ['allCourses', 'statistics'],
  },
  props: ({ ownProps, mutate }) => ({
    removeCourse: courseId => mutate({ variables: { courseId } }),
  }),
});

export const Courses = withCourses(withMutation(withStatistics(CoursesView)));
