import { combineReducers } from 'redux';
import { EStudentsViewActions } from './students.actions';
import { LOCATION_CHANGE } from 'react-router-redux';

export const STUDENTS_PER_PAGE = 10;

const search = (state = '', action) => {
  if (action.type === EStudentsViewActions.CHANGE_FILTER && action.search !== undefined) {
    return action.search;
  }

  if (action.type === LOCATION_CHANGE) {
    return '';
  }

  return state;
};

const filterType = (state = null, action) => {
  if (action.type === EStudentsViewActions.CHANGE_FILTER && action.filterType !== undefined) {
    return action.filterType;
  }

  if (action.type === LOCATION_CHANGE) {
    return null;
  }

  return state;
};

const skip = (state = 0, action) => {
  if (action.type === EStudentsViewActions.CHANGE_FILTER && action.skip !== undefined) {
    return action.skip;
  }

  if (action.type === LOCATION_CHANGE) {
    return 0;
  }

  return state;
};

const limit = (state = STUDENTS_PER_PAGE, action) => {
  if (action.type === EStudentsViewActions.CHANGE_FILTER && action.limit !== undefined) {
    return action.limit;
  }

  if (action.type === LOCATION_CHANGE) {
    return STUDENTS_PER_PAGE;
  }

  return state;
};

export const students = combineReducers({
  search,
  skip,
  limit,
  filterType,
});
