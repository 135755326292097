import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStudentsList } from '../../students/components/students.container';
import { MenuItem, SelectField, TextField } from 'material-ui';
import { ProfileAvatar } from '../../common/components/profile-avatar.component';

export class UserPickerDropdownView extends Component {
  static propTypes = {
    updateFilter: PropTypes.func,
    search: PropTypes.string,
    skip: PropTypes.number,
    limit: PropTypes.number,
    students: PropTypes.shape({
      error: PropTypes.any,
      loading: PropTypes.bool,
      allStudents: PropTypes.shape({
        total: PropTypes.number,
        totalForFilter: PropTypes.number,
        items: PropTypes.arrayOf(PropTypes.any)
      })
    }),
    selectedUser: PropTypes.any,
    onChange: PropTypes.func
  };

  constructor() {
    super();

    this.state = {
      currentUsers: []
    }

    this._input = null;
  }

  handleChange(value) {
    console.log('inside handle change!')
    this.props.onChange(value);
  }



  selectionRenderer(value) {
    if (value) {
      return (
        <div
          style={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around'
          }}
        >
          <ProfileAvatar src={value.profilePictureUrl} size={20} />
          {value.name}
        </div>
      );
    }

    return '';
  }

  componentDidMount = () => {
    const users =
    this.props.students &&
    this.props.students.allStudents &&
    this.props.students.allStudents.items
    
    if(users !== null && users !== undefined) {
      this.setState({ currentUsers: users})
    }
  }

  componentWillReceiveProps = (newProps) => {
    const users =
    newProps.students &&
    newProps.students.allStudents &&
    newProps.students.allStudents.items
    
    if(users !== null && users !== undefined) {
      if(this.state.currentUsers.length === 0 && users.length > 0) {
        let newUsers = []
        users.map(user => {
          if(!this.state.currentUsers.includes(user)) newUsers.push(user) 
        })
        this.setState({ currentUsers: [...this.state.currentUsers, ...newUsers]})
      }
      if(this.state.currentUsers.length > 0 && users.length > 0) {
        if(JSON.stringify(this.state.currentUsers[0]) !== JSON.stringify(users[0])) {
          let newUsers = []
          users.map(user => {
            if(!this.state.currentUsers.includes(user)) newUsers.push(user) 
          })
          this.setState({ currentUsers: [...this.state.currentUsers, ...newUsers]})
        }
      }
    }
  }

  get users() {
    const users =
      this.props.students &&
      this.props.students.allStudents &&
      this.props.students.allStudents.items
        ? this.props.students.allStudents.items
        : [];


    if (this.props.selectedUser) {
      const without = users.filter(u => u.id !== this.props.selectedUser.id);

      return [this.props.selectedUser, ...without];
    }

    return users;
  }

  opened() {
    this.focus();
  }

  focus() {
    if (this._input && this._input.input) {
      this._input.input.focus();
    }
  }

  handleKeyDown(e) {
    e.stopPropagation();
    this.focus();
  }

  componentDidUpdate() {
    setTimeout(() => {
      this.focus();
    }, 0);
  }

  updatePagination = (nextData) => {
    if (nextData) {
      this.props.updateFilter({ skip: this.props.skip + this.props.limit });
    } else {
      let newSkip = this.props.skip - this.props.limit;

      if (newSkip < 0) {
        newSkip = 0;
      }

      this.props.updateFilter({ skip: newSkip });
    }
  }

  onScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) { 
      // this.updatePagination(true)
    }
  }

  render() {
    const data = this.state.currentUsers.map(student => (
      <MenuItem key={student.id} value={student}>
        <div
          style={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <ProfileAvatar src={student.profilePictureUrl} size={30} />
          {student.name}
        </div>
      </MenuItem>
    ));

    return (
      <div onClick={() => this.opened()}>
        <SelectField
          floatingLabelText={this.props.fromMeetings ? this.props.isPart ? 'Add Participants to Live Streaming' :  'Choose Live Streaming Host*' : 'Find Participants'}
          selectionRenderer={this.selectionRenderer}
          value={this.props.selectedUser}
          onChange={(event, index, value) => this.handleChange(value)}
        >
          <MenuItem disabled={true}>
            <TextField
              onKeyDown={e => this.handleKeyDown(e)}
              ref={ref => (this._input = ref)}
              floatingLabelText={this.props.fromMeetings ? this.props.isPart ? 'Add Participants to Live Streaming' :  'Choose Live Streaming Host' : 'Find Participants'}
              value={this.props.search}
              fullWidth={true}
              onChange={(event, newValue) => {
                this.props.updateFilter({ search: newValue });
              }}
            />
          </MenuItem>
          <MenuItem value={null} primaryText='' />
           {data}
        </SelectField>
      </div>
    );
  }
}

export const UserPickerDropdown = withStudentsList(UserPickerDropdownView);


const styles = {
  listContainer: {
   
  }
};