import { accountsClient } from './account.init';
import { client } from '../core/apollo/apollo';
import { AuthorizationMiddleware } from '../core/network/authorization-middleware';
import { getUserQuery } from '../auth/graphql/getUser.query';

export const refreshSession = async () => {
  const tokens = await accountsClient.refreshSession();
  if (tokens) {
    AuthorizationMiddleware.setToken(tokens.accessToken);
  } else {
    AuthorizationMiddleware.setToken(null);
  }

  return tokens;
};

export const logout = async () => {
  await accountsClient.logout();
  AuthorizationMiddleware.setToken(null);
  window.location.reload();
};

export const login = async (username, password) => {
  try {
    const { tokens } = await accountsClient.loginWithService('password', {
      user: {
        username
      },
      password
    });

    AuthorizationMiddleware.setToken(tokens.accessToken);

    return { tokens };
  } catch (err) {
    throw err;
  }
};

export const getUser = async () => {
  try {
    const response = await client.query({
      query: getUserQuery
    });
    return response.data.getUser;
  } catch (err) {
    return null;
  }
};
