export class AuthorizationMiddleware {
  static token = null;

  static setToken(token) {
    AuthorizationMiddleware.token = token;
  }

  static getHeader() {
    return {
      'accounts-access-token': AuthorizationMiddleware.token || '',
    };
  }

  static isLoggedIn() {
    return AuthorizationMiddleware.token !== null;
  }
}
