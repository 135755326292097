import { graphql } from 'react-apollo';
import { CreateEventFormView } from './create-events-form.component';
import { createUpdateEventMutation } from '../graphql/create-update-event.mutation';
import { withEditEntity } from '../../feed/components/with-edit-feed-item.container';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

const mapDispatch = dispatch => ({
  navigate: route => dispatch(push(route)),
});

const withState = connect(null, mapDispatch);

const withMutation = graphql(createUpdateEventMutation, {
  props: ({ ownProps, mutate }) => ({
    createUpdateEvent: (id, event) => mutate({
      variables: {
        eventId: id,
        event,
      },
    }),
  }),
});

export const CreateEventForm = withState(withMutation(withEditEntity(CreateEventFormView)));
