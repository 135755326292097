import gql from 'graphql-tag';
import { newsFeedEventFragment } from '../../events/graphql/news-feed-event-item.fragment';
import { newsFeedPollFragment } from '../../polls/graphql/news-feed-poll-item.fragment';
import { newsFeedNewsFragment } from '../../news/graphql/news-feed-news-item.fragment';
import { newsFeedSportsEventFragment } from '../../sports-events/graphql/news-feed-sports-event-item.fragment';

export const allFeedItemsQuery = gql`
  query allFeedItems($type: String!, $search: String, $skip: Int, $limit: Int) {
    allFeedItems(type: $type, search: $search, skip: $skip, limit: $limit) {
      totalForFilter
      items {
        ...EventFields
        ...PollFields
        ...NewsFields
        ...SportsEventFields
      }
    }
  }

  ${newsFeedEventFragment}
  ${newsFeedPollFragment}
  ${newsFeedNewsFragment}
  ${newsFeedSportsEventFragment}
`;
