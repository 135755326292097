import gql from 'graphql-tag';

export const analyticsQuery = gql`
    query analytics($params: [AnalyticsParam]!, $startTime: String!, $endTime: String!) {
        analytics(params: $params, startTime: $startTime, endTime: $endTime) {
            category
            action
            identifier
            value
        }
    }
`;
