import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, DatePicker, GridList, GridTile, RaisedButton, TextField, Toggle } from 'material-ui';
import { CategoriesPicker } from '../../common/components/categories-picker.container';
import { ImageButtonPicker } from '../../common/components/image-button-picker.component';
import { DataBox } from '../../common/components/data-box.component';
import moment from 'moment';
import { FontAwesome } from '../../common/components/font-awesome';
import { Theme } from '../../theme/theme';
import { Loading } from '../../common/components/loading.component';
import { AMPMTimePicker } from '../../common/components/time-picker.rn';

const styles = {
  table: {
    display: 'table',
    overflow: 'hidden',
    width: '100%',
  },
  inline: {
    display: 'inline-block',
    marginHorizontal: 3,
  },
  fields: {
    width: '80%',
    display: 'table-cell',
  },
  poster: {
    display: 'table-cell',
    padding: 10,
  },
  posterPlaceholder: {
    textAlign: 'center',
    minHeight: 280,
    border: `1px ${Theme.DESCRIPTION_TEXT_COLOR} solid`,
  },
  posterImage: {
    maxWidth: 100,
  },
};

export class CreateEventFormView extends Component {
  static propTypes = {
    editEntityId: PropTypes.string,
    editEntity: PropTypes.object,
    loading: PropTypes.bool,
    createUpdateEvent: PropTypes.func.isRequired,
  };

  constructor() {
    super();

    this.state = {
      error: null,
      isUpdating: false,
      categories: [],
      mainCategory: null,
      eventName: null,
      eventLocation: null,
      description: '',
      posterImage: null,
      startDate: new Date(),
      endDate: null,
      startTime: moment().format('hh:mm A'),
      endTime: moment().format('hh:mm A'),
      allDay: false,
      lastEventDate: null,
      lastEventImages: [],
      whoToContact: '',
      xiboText: '',
      pinned: false,
    };
  }

  componentDidMount() {
    const { editEntity } = this.props;
    this.setEditEntity(editEntity);
  }

  addLastEventImage(imageData) {
    this.setState({ lastEventImages: [...this.state.lastEventImages, imageData] });
  }

  next() {
    this.setState({ isUpdating: true });

    let {
      startDate, startTime, endDate, endTime,
    } = this.state;
    startTime = (startTime && startTime !== '') ? moment(startTime, 'hh:mm A').toDate() : null;
    endTime = (endTime && endTime !== '') ? moment(endTime, 'hh:mm A').toDate() : null;

    const mergedStartTime = new Date(
      startDate.getFullYear(), startDate.getMonth(), startDate.getDate(),
      startTime ? startTime.getHours() : 0, startTime ? startTime.getMinutes() : 0, startTime ? startTime.getSeconds() : 0,
    );
    const mergedEndTime = new Date(
      endDate.getFullYear(), endDate.getMonth(), endDate.getDate(),
      endTime ? endTime.getHours() : 0, endTime ? endTime.getMinutes() : 0, endTime ? endTime.getSeconds() : 0,
    );

    this.props.createUpdateEvent(this.props.editEntityId, {
      location: this.state.eventLocation,
      name: this.state.eventName,
      description: this.state.description,
      startTime: moment.utc(mergedStartTime).toDate(),
      endTime: moment.utc(mergedEndTime).toDate(),
      allDay: this.state.allDay,
      whoToContact: this.state.whoToContact,
      xiboText: this.state.xiboText,
      previousEventDate: this.state.lastEventDate,
      previousEventImages: this.state.lastEventImages.filter(item => item !== '' && item !== null),
      poster: this.state.posterImage,
      mainCategory: this.state.mainCategory,
      categories: this.state.categories,
      pinned: this.state.pinned,
    })
      .then(({ data }) => {
        this.setState({ isUpdating: false });
        this.props.navigate(`/admin/events/publish/${data.createUpdateEvent.id}`);
      })
      .catch(e => this.setState({ isUpdating: false, error: e }));
  }

  componentWillReceiveProps(nextProps) {
    const { editEntity } = nextProps;
    this.setEditEntity(editEntity);
  }

  setEditEntity(editEntity) {
    if (editEntity) {
      const startMoment = moment(editEntity.startTime);
      const endMoment = moment(editEntity.endTime);
      const prevMoment = editEntity.previousEventDate ? moment(editEntity.previousEventDate).toDate() : null;

      this.setState({
        eventName: editEntity.title,
        eventLocation: editEntity.locationName,
        description: editEntity.description,
        categories: editEntity.categories.map(item => item.id),
        mainCategory: editEntity.mainCategory.id,
        posterImage: editEntity.posterImageUrl,
        whoToContact: editEntity.whoToContact || '',
        xiboText: editEntity.xiboText || '',
        lastEventDate: prevMoment,
        allDay: editEntity.allDay,
        pinned: editEntity.pinned,
        startTime: editEntity.allDay ? '' : startMoment.format('hh:mm A'),
        startDate: startMoment.toDate(),
        endTime: editEntity.allDay ? '' : endMoment.format('hh:mm A'),
        endDate: endMoment.toDate(),
        lastEventImages: editEntity.previousEventImages,
      });
    }
  }

  renderImage(image) {
    return image ? (
      <div>
        <img alt="" style={styles.posterImage} src={image}/>
      </div>
    ) : (
      <div style={styles.posterPlaceholder}>
        <FontAwesome style={{ marginTop: 100 }} name="file-image-o"/>
      </div>
    );
  }

  isSubmitDisabled() {
    return this.state.eventName === null ||
      this.state.eventName === '' ||
      this.state.mainCategory === null ||
      this.state.startDate === null ||
      this.state.endDate === null ||
      this.state.categories.length === 0;
  }

  removeImage(toRemove) {
    this.setState({
      lastEventImages: this.state.lastEventImages.filter((image, index) => index !== toRemove),
    });
  }

  redIfError(value) {
    if (value === '') {
      return {
        borderColor: 'red',
      };
    }

    return {};
  }

  render() {
    if (this.props.loading || this.state.isUpdating) {
      return (<Loading/>);
    }

    return (
      <div style={{ paddingRight: '3%', paddingLeft: '3%', marginBottom: 20 }}>
        <DataBox title="Event Details">
          <div style={{ width: '100%', textAlign: 'center', color: 'red' }}>
            {this.props.error || this.state.error}
          </div>
          <div style={styles.table}>
            <div style={styles.fields}>
              <TextField
                floatingLabelText="Event Name"
                value={this.state.eventName || ''}
                fullWidth={true}
                underlineStyle={this.redIfError(this.state.eventName)}
                onChange={(event, newValue) => this.setState({ eventName: newValue })}
              />
              <TextField
                floatingLabelText="Event Location"
                value={this.state.eventLocation || ''}
                fullWidth={true}
                underlineStyle={this.redIfError(this.state.eventLocation)}
                onChange={(event, newValue) => this.setState({ eventLocation: newValue })}
              />
              <TextField
                multiLine={true}
                rows={1}
                rowsMax={2}
                fullWidth={true}
                floatingLabelText="Event Description"
                value={this.state.description}
                onChange={(event, newValue) => this.setState({ description: newValue })}
              />
              <TextField
                fullWidth={true}
                floatingLabelText="Who To Contact?"
                value={this.state.whoToContact}
                onChange={(event, newValue) => this.setState({ whoToContact: newValue })}
              />
              <CategoriesPicker
                categories={this.state.categories}
                mainCategory={this.state.mainCategory}
                onCategoriesChange={categories => this.setState({ categories })}
                onMainCategoryChange={mainCategory => this.setState({ mainCategory })}/>
              <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                <Toggle
                  style={{ width: '25%', marginTop: 10 }}
                  label="Pinned Event"
                  onToggle={(e, newValue) => this.setState({ pinned: newValue })}
                  toggled={this.state.pinned}
                />
                <span style={{ marginLeft: 20, color: 'grey', fontSize: 12 }}>Pinned events are visible after event's end time</span>
              </div>
            </div>
            <div style={styles.poster}>
              {this.renderImage(this.state.posterImage)}
              <ImageButtonPicker
                label={`${this.state.posterImage ? 'Update' : 'Add'} Poster`}
                fullWidth={true}
                onFile={data => this.setState({ posterImage: data })}/>
            </div>
          </div>
        </DataBox>
        <DataBox title="Date & Time">
          <Checkbox
            label="All Day Event"
            checked={this.state.allDay}
            onCheck={(e, newState) => this.setState({
              allDay: newState,
              ...(newState === true) ? {
                endTime: '',
                startTime: '',
              } : {},
            })}/>
          <DatePicker
            firstDayOfWeek={0}
            style={styles.inline}
            autoOk={true}
            floatingLabelText="Start Date"
            value={this.state.startDate}
            onChange={(event, startDate) => this.setState({
              startDate,
              endDate: (this.state.endDate === null || this.state.endDate.getTime() < startDate.getTime()) ? startDate : this.state.endDate,
            })}
            container="inline"
            mode="landscape"/>
          {!this.state.allDay && (
            <AMPMTimePicker
              label="Start Time"
              value={this.state.startTime}
              onChange={(e, v) => this.setState({ startTime: v })}/>
          )}
          <br/>
          <DatePicker
            firstDayOfWeek={0}
            minDate={this.state.startDate}
            style={styles.inline}
            autoOk={true}
            floatingLabelText="End Date"
            value={this.state.endDate}
            onChange={(event, endDate) => this.setState({ endDate })}
            container="inline"
            mode="landscape"/>
          {!this.state.allDay && (
            <AMPMTimePicker
              label="End Time"
              value={this.state.endTime}
              onChange={(e, v) => this.setState({ endTime: v })}/>
          )
          }
        </DataBox>
        <DataBox title="Previous Event" contentHeight="auto">
          <div style={{ display: 'flex', flexDiretion: 'row', alignItems: 'center' }}>
            <DatePicker
              firstDayOfWeek={0}
              maxDate={new Date()}
              autoOk={true}
              floatingLabelText="Previous Event Date"
              value={this.state.lastEventDate}
              onChange={(event, lastEventDate) => this.setState({ lastEventDate })}
              container="inline"
              mode="landscape"/>
            <FontAwesome tooltip="Clear" name="times" onClick={() => this.setState({ lastEventDate: null })}/>
          </div>
          <ImageButtonPicker
            label="Add Image"
            onFile={data => this.addLastEventImage(data)}/>
          <GridList style={styles.gridList} cols={4}>
            {this.state.lastEventImages.map((image, index) => (
              <GridTile key={index} style={{ height: 'auto', width: 'auto' }}>
                <img
                  style={{
                    backgroundSize: 'cover',
                    borderRadius: 10,
                    backgroundImage: `url(${image})`,
                    width: 130,
                    height: 130,
                    margin: 7,
                  }}
                  alt=""/>
                <FontAwesome
                  style={{
                    color: 'black', position: 'absolute', top: 10, left: 10,
                  }}
                  name="trash"
                  onClick={() => this.removeImage(index)}/>
              </GridTile>
            ))}
          </GridList>
        </DataBox>
        <RaisedButton
          disabled={this.isSubmitDisabled()}
          onClick={() => this.next()}
          primary={true}
          fullWidth={true}
          label="Continue"/>
      </div>
    );
  }
}

