import { graphql } from 'react-apollo';
import { LiveFeedDialogView } from './live-feed-approval-dialog.component';
import { respondToEventLiveFeedMutation } from '../../students/graphql/respond-event-live-image.mutation';

const withApproveMutation = graphql(respondToEventLiveFeedMutation, {
  options: {
    refetchQueries: ['waitingLiveFeedApprovals', 'statistics'],
  },
  props: ({ ownProps, mutate }) => ({
    approve: approvalId => mutate({ variables: { approvalId, approve: true } }),
    reject: (approvalId, rejectionReason) => mutate({ variables: { approvalId, approve: false, rejectionReason } }),
  }),
});

export const LiveFeedDialog = withApproveMutation(LiveFeedDialogView);
