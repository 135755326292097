import gql from 'graphql-tag';

export const allManualsQuery = gql`
  query allManuals(
    $search: String
    $skip: Int
    $limit: Int
    $perferedIds: [String]
  ) {
    allManuals(
      search: $search
      skip: $skip
      limit: $limit
      perferedIds: $perferedIds
    ) {
      total
      totalForFilter
      items {
        id
        name
        label
        data
      }
    }
  }
`;
