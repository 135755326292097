import gql from 'graphql-tag';

export const sendMessageMutation = gql`
    mutation sendMessageHelpdesk ($content: String, $messageType: MessageType!, $imageData: String, $chatId: String!){
        sendMessageHelpdesk(chatId: $chatId, content: $content, messageType: $messageType, imageData: $imageData) {
            id
            createdAt(momentFormat: "LT")
            createdAtDay: createdAt(momentFormat: "ll")
            serverMessage
            content
            imageThumb: content(imageParams: { height: "300", width: "300", crop: FIT })
            author {
                id
                username
                name
                profilePictureUrl
            }
            messageType
        }
    }
`;
