import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FlatButton } from 'material-ui';
import { getMetadata, getThumbnails } from 'video-metadata-thumbnails';
import { BlobServiceClient } from '@azure/storage-blob';
import uuid from 'react-uuid';

const blobSasUrl =
  'https://fortshavendiag.blob.core.windows.net/?sv=2019-10-10&ss=bfqt&srt=sco&sp=rwdlacupx&se=2029-02-10T20:50:35Z&st=2020-05-01T11:50:35Z&spr=https,http&sig=pu%2Fr4N1wQRevEeo8mWDIxev2Au2n%2Bv6%2BBZ0jP0KN9cA%3D';
const blobServiceClient = new BlobServiceClient(blobSasUrl);
const containerName = 'video-preview-images';
const containerClient = blobServiceClient.getContainerClient(containerName);

export class VideoButtonPicker extends Component {
  static propTypes = {
    label: PropTypes.any,
    onFile: PropTypes.func.isRequired,
    onDuration: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool
  };

  onInputChange(e) {
    Array.from(e.target.files).forEach(async file => {
      const reader = new FileReader();
      reader.onload = r => {
        this.props.onFile(r.target.result);
      };
      reader.readAsDataURL(file);
      const metadata = await getMetadata(file);
      this.props.onDuration(metadata.duration.toString());
      const thumbnails = await getThumbnails(file, {
        quality: 0.9,
        end: 10
      });
      this.uploadPreviewImageToAzure(thumbnails[5].blob);
    });
  }

  async uploadPreviewImageToAzure(file) {
    try {
      let fileName = uuid();
      const blockBlobClient = containerClient.getBlockBlobClient(fileName);
      let res = await blockBlobClient.uploadBrowserData(file, {
        blobHTTPHeaders: {
          blobContentType: 'image/jpg'
        }
      });
      console.log(res, 'res');
      this.props.onPreviewImage(
        'https://fortshavendiag.blob.core.windows.net/video-preview-images/' +
          fileName
      );
    } catch (error) {
      console.log(error);
    }
  }

  componentDidMount() {
    if (this.input) {
      this.input.addEventListener(
        'change',
        this.onInputChange.bind(this),
        false
      );
    }
  }

  componentWillUnmount() {
    if (this.input) {
      this.input.removeEventListener(
        'change',
        this.onInputChange.bind(this),
        false
      );
    }
  }

  render() {
    return (
      <FlatButton
        fullWidth={this.props.fullWidth}
        disabled={this.props.disabled}
        style={{ marginTop: 10, marginBottom: 10 }}
        containerElement='label'
        label={this.props.label}
      >
        <input accept='video/*' type='file' ref={ref => (this.input = ref)} />
      </FlatButton>
    );
  }
}
