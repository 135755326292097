import gql from 'graphql-tag';
import { newsFeedPollFragment } from './news-feed-poll-item.fragment';

export const createUpdatePollMutation = gql`
  mutation createUpdatePoll($pollId: String, $poll: PollInput!) {
    createUpdatePoll(pollId: $pollId, poll: $poll) {
      ...PollFields
    }
  }

  ${newsFeedPollFragment}
`;
