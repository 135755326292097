import gql from 'graphql-tag';

export const resetProfilePictureMutation = gql`
  mutation resetProfilePicture($userId: String!) {
    resetProfilePicture(userId: $userId) {
      id
      defaultProfilePicture
      profilePictureUrl
    }
  }
`;
