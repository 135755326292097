import { connect } from 'react-redux';
import { allFeedItemsQuery } from '../graphql/all-feed-items.query';
import { FeedItemsView } from './feed.component';
import { graphql } from 'react-apollo';
import { updateFeedSearch } from '../feed.actions';
import { removeFeedItemMutation } from '../graphql/remove-feed-item.mutation';
import { withStatistics } from '../../common/components/with-statistics.container';
import { publishMutation } from '../graphql/publish-feed-item.mutation';

export const createFeedContainer = (type, title, stats, tableDesc, wrapper, extraActions = () => [], options) => {
  const mapStateToProps = ({ feed }) => ({
    skip: feed.skip,
    limit: feed.limit,
    search: feed.search,
    type,
    stats,
    tableDesc,
    title,
    extraActions,
    options: options || {},
  });

  const mapDispatch = dispatch => ({
    updateFilter: filterChanges => dispatch(updateFeedSearch(filterChanges)),
  });

  const withState = connect(mapStateToProps, mapDispatch);

  const withData = graphql(allFeedItemsQuery, {
    options: props => ({
      fetchPolicy: 'network-only',
      variables: {
        type: props.type,
        skip: props.skip,
        limit: props.limit,
        search: props.search,
      },
    }),
  });

  const withMutation = graphql(removeFeedItemMutation, {
    options: {
      refetchQueries: ['allFeedItems'],
    },
    props: ({ ownProps, mutate }) => ({
      removeFeedItem: itemId => mutate({ variables: { itemId } }),
    }),
  });

  const withUnpublish = graphql(publishMutation, {
    options: {
      refetchQueries: ['allFeedItems'],
    },
    props: ({ ownProps, mutate }) => ({
      unpublish: itemId => mutate({ variables: { itemId } }),
    }),
  });

  const componentWrapper = wrapper || (value => value);

  return withState(withData(withMutation(withStatistics(withUnpublish(componentWrapper(FeedItemsView))))));
};
