import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { analyticsQuery } from '../graphql/analytics.query';
import { Loading } from '../../common/components/loading.component';
import { DatePicker, RaisedButton } from 'material-ui';
import { Theme } from '../../theme/theme';

export class AnalyticsView extends Component {
  static propTypes = {
    startTime: PropTypes.any,
    endTime: PropTypes.any,
    client: PropTypes.any,
    params: PropTypes.any,
  };

  constructor() {
    super();

    this.state = {
      startTime: moment().startOf('day'),
      endTime: moment().endOf('day'),
      loading: true,
      data: [],
    };
  }

  componentWillMount() {
    if (this.props.startTime && this.props.endTime) {
      this.setState({
        startTime: this.props.startTime,
        endTime: this.props.endTime,
      }, () => {
        this.reloadData();
      });
    } else {
      this.reloadData();
    }
  }

  reloadData() {
    this.setState({ loading: true });
    const { startTime, endTime } = this.state;
    const params = this.props.params;

    this.props.client.query({
      query: analyticsQuery,
      fetchPolicy: 'network-only',
      variables: {
        startTime: startTime.utc(),
        endTime: endTime.utc(),
        params: params.map(item => ({
          category: item.category,
          action: item.action,
          identifier: item.identifier || null,
          flags: item.flags || {},
        })),
      },
    }).then(({ data }) => {
      this.setState({
        data: data.analytics,
        loading: false,
      });
    });
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    }

    return (
      <div>
        <div style={{ flexDirection: 'column' }}>
          <DatePicker
            firstDayOfWeek={0}
            autoOk={true}
            floatingLabelText="Start date"
            style={{ display: 'inline-block', marginRight: 10 }}
            value={this.state.startTime.toDate()}
            onChange={(event, startDate) => this.setState({ startTime: moment(startDate).startOf('day') })}
            container="inline"
            mode="landscape" />
          <DatePicker
            firstDayOfWeek={0}
            autoOk={true}
            style={{ display: 'inline-block', marginRight: 10 }}
            floatingLabelText="End date"
            value={this.state.endTime.toDate()}
            onChange={(event, endDate) => this.setState({ endTime: moment(endDate).endOf('day') })}
            container="inline"
            mode="landscape" />
          <RaisedButton
            onClick={() => this.reloadData()}
            style={{ marginRight: 30 }}
            label="Search" />
        </div>
        <br /><br />
        <div style={{ flexDirection: 'row', display: 'flex', flexWrap: 'wrap' }}>
          {
            this.state.data.map((resultData, index) => (
              <div
                key={index}
                style={{
                  paddingBottom: 40, width: '33%', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column',
                }}>
                <div style={{
                  textAlign: 'center', fontSize: 14, fontWeight: 'bold', color: Theme.PRIMARY_COLOR,
                }}>{this.props.params[index].name}</div>
                <div style={{
                  textAlign: 'center', fontSize: 34, paddingTop: 5, color: Theme.SECONDARY_TEXT_COLOR,
                }}>{resultData.value}{ this.props.params[index].flags && this.props.params[index].flags.unique && (<span style={{ fontSize: 11 }}>users</span>)}</div>
              </div>
            ))
          }
        </div>
      </div>
    );
  }
}
