import React, { Component } from 'react';
import { createFeedContainer } from '../../feed/components/feed-items';
import { Theme } from '../../theme/theme';
import { ProfileAvatar } from '../../common/components/profile-avatar.component';
import moment from 'moment';
import { toggleCancelEventMutation } from '../graphql/toggle-cancel-event.mutation';
import { graphql } from 'react-apollo';
import { FontAwesome } from '../../common/components/font-awesome';
import { Dialog, RaisedButton } from 'material-ui';
import { EventRSVPList } from './event-rsvp-list.container';
import { Analytics } from '../../analytics/components/analytics.container';

const eventsStatistics = data => [
  {
    name: 'Published Events',
    value: data.publishedEvents || 0,
    color: Theme.ACTIVE1_COLOR
  },
  {
    name: 'Future Events',
    value: data.futureEvents || 0,
    color: Theme.ACTIVE2_COLOR
  },
  {
    name: 'Average Per Day',
    value: data.averageEventsPerDay || 0,
    color: Theme.PRIMARY_COLOR
  },
  {
    name: 'Average Per Category',
    value: data.averageEventsCategory || 0,
    color: Theme.PRIMARY_COLOR
  }
];

const tableColumns = componentContext => [
  { headerName: 'Title', width: 15, valueFrom: 'title' },
  { headerName: 'Location', width: 12, valueFrom: 'locationName' },
  {
    headerName: 'Time',
    width: 12,
    style: {
      fontSize: 10
    },
    render: item => (
      <span>
        {moment(item.startTime).format(item.allDay ? 'L' : 'L LT')} to
        <br />
        {moment(item.endTime).format(item.allDay ? 'L' : 'L LT')}
      </span>
    )
  },
  {
    headerName: 'Author',
    width: 15,
    render: item => {
      if (item && item.author && item.author.name) {
        return (
          <div>
            <ProfileAvatar size={30} src={item.author.profilePictureUrl} />
            <div
              style={{
                display: 'inline-block',
                lineHeight: '30px',
                verticalAlign: 'top',
                marginLeft: 5
              }}
            >
              {item.author.name}
            </div>
          </div>
        );
      }

      return <span />;
    }
  },
  {
    header: () => (
      <FontAwesome
        style={{ fontSize: 12, color: 'white', marginRight: 10 }}
        name='bell'
      />
    ),
    width: 5,
    valueFrom: 'remindersCount'
  }
];

const wrapper = graphql(toggleCancelEventMutation, {
  options: {
    refetchQueries: ['allFeedItems']
  },
  props: ({ ownProps, mutate }) => ({
    cancelEvent: eventId => mutate({ variables: { eventId } })
  })
});

export const EventsTable = createFeedContainer(
  'EVENT',
  'Events',
  eventsStatistics,
  tableColumns,
  wrapper,
  (item, componentContext) => [
    <FontAwesome
      tooltip='RSVP'
      onClick={() => componentContext.props.onRSVPClicked(item)}
      key={0}
      style={{ cursor: 'pointer', fontSize: 12, marginRight: 10 }}
      name='thumbs-o-up'
    />,
    <FontAwesome
      tooltip='Analytics'
      onClick={() => componentContext.props.onAnalyticsClicked(item)}
      key={1}
      style={{ cursor: 'pointer', fontSize: 12, marginRight: 10 }}
      name='line-chart'
    />,
    <span
      key={2}
      style={{ cursor: 'pointer' }}
      onClick={() => componentContext.props.cancelEvent(item.id)}
    >
      {item.cancelled ? 'Un-Cancel' : 'Cancel'}
    </span>
  ]
);

export class Events extends Component {
  constructor() {
    super();

    this.state = {
      rsvpModalEvent: null,
      analytics: null
    };
  }

  showRSVP(event) {
    this.setState({ rsvpModalEvent: event });
  }

  openAnalytics(event) {
    this.setState({
      analytics: event
    });
  }

  getAnalyticsParams() {
    return [
      {
        category: 'Feed',
        action: 'EventView',
        identifier: this.state.analytics.id,
        name: 'Total Views'
      },
      {
        category: 'Feed',
        action: 'EventView',
        identifier: this.state.analytics.id,
        name: 'Total Views (Unique)',
        flags: { unique: true }
      },
      {
        category: 'Feed',
        action: 'ExportEvent',
        identifier: this.state.analytics.id,
        name: 'Export to calendar (Unique)',
        flags: { unique: true }
      },
      {
        category: 'Feed',
        action: 'NavigateToAttendeeList',
        identifier: this.state.analytics.id,
        name: 'Navigate to Event Wall (Unique)',
        flags: { unique: true }
      },
      {
        category: 'Feed',
        action: 'NavigateToEventWall',
        identifier: this.state.analytics.id,
        name: 'Navigate to Attendees List (Unique)',
        flags: { unique: true }
      },
      {
        category: 'Feed',
        action: 'FeedPosterClick',
        identifier: this.state.analytics.id,
        name: 'Poster Clicks'
      },
      {
        category: 'Feed',
        action: 'LastImagesGalleryView',
        identifier: this.state.analytics.id,
        name: 'Last Event Gallery Clicks'
      },
      {
        category: 'Feed',
        action: 'RSVP',
        identifier: this.state.analytics.id,
        name: 'RSVP (Unique)',
        flags: { unique: true }
      },
      {
        category: 'Feed',
        action: 'ToggleReminder',
        identifier: this.state.analytics.id,
        name: 'Reminder Set (Unique)',
        flags: { unique: true }
      }
    ];
  }

  render() {
    return (
      <div>
        <Dialog
          onRequestClose={() => this.showRSVP(null)}
          title={
            this.state.rsvpModalEvent
              ? `RSVP: ${this.state.rsvpModalEvent.title}`
              : ''
          }
          actions={[
            <RaisedButton
              primary={true}
              label='Close'
              onClick={() => this.showRSVP(null)}
              onTouchTap={() => this.showRSVP(null)}
            />
          ]}
          open={this.state.rsvpModalEvent !== null}
        >
          <EventRSVPList
            eventId={
              this.state.rsvpModalEvent ? this.state.rsvpModalEvent.id : null
            }
          />
        </Dialog>
        <Dialog
          onRequestClose={() => this.openAnalytics(null)}
          title={
            this.state.analytics
              ? `Analytics for event: ${this.state.analytics.title}`
              : ''
          }
          actions={[
            <RaisedButton
              primary={true}
              label='Close'
              onClick={() => this.openAnalytics(null)}
              onTouchTap={() => this.openAnalytics(null)}
            />
          ]}
          contentStyle={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)'
          }}
          open={this.state.analytics !== null}
        >
          {this.state.analytics && (
            <Analytics params={this.getAnalyticsParams()} />
          )}
        </Dialog>
        <EventsTable
          onAnalyticsClicked={event => this.openAnalytics(event)}
          onRSVPClicked={event => this.showRSVP(event)}
        />
      </div>
    );
  }
}
