export const EManualsViewActions = {
  CHANGE_FILTER: 'manuals/CHANGE_FILTER',
  CHANGE_STUDENTS_FILTER: 'manuals/CHANGE_STUDENTS_FILTER',
  EDIT_ENTITY: 'manuals/EDIT_ENTITY'
};

export const updateManualsFilter = filterChanges => ({
  type: EManualsViewActions.CHANGE_FILTER,
  ...filterChanges
});

export const updateStudentsSearch = search => ({
  type: EManualsViewActions.CHANGE_STUDENTS_FILTER,
  search
});

export const editEntity = editableEntity => ({
  type: EManualsViewActions.EDIT_ENTITY,
  open: !!editableEntity,
  entity: editableEntity
});
