import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, FlatButton, RaisedButton } from 'material-ui';
import { ProfileAvatar } from '../../common/components/profile-avatar.component';
import { Loading } from '../../common/components/loading.component';

export class StudentProfilePictureModalView extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    student: PropTypes.any,
    onRequestClose: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  constructor() {
    super();

    this.state = {
      loading: false
    };
  }

  renderNothingToDo(student, usesDefault) {
    return usesDefault ? (
      <div style={{ textAlign: 'center' }}>
        <span>
          <strong>{student.name} is using the default profile picture!</strong>
        </span>
        <br />
        <br />
        <ProfileAvatar
          style={{ marginTop: 20 }}
          size={200}
          src={student.profilePictureUrl}
        />
      </div>
    ) : null;
  }

  resetToDefault() {
    this.setState({ loading: true });
    this.props
      .reset({ userId: this.props.student.id })
      .catch(() => null)
      .then(() => this.setState({ loading: false }));
  }

  renderManagement(student, usesDefault) {
    if (usesDefault) {
      return null;
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around'
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <span>
            <strong>Current Profile Picture</strong>
          </span>
          <br />
          <br />
          <ProfileAvatar
            style={{ marginTop: 20 }}
            size={200}
            src={student.profilePictureUrl}
          />
        </div>
        <div style={{ textAlign: 'center' }}>
          <RaisedButton
            onClick={() => this.resetToDefault()}
            onTouchTap={() => this.resetToDefault()}
            label='Reset To Default'
            primary={true}
          />
          <br />
          <ProfileAvatar
            style={{ marginTop: 20 }}
            size={200}
            src={student.defaultProfilePicture}
          />
        </div>
      </div>
    );
  }

  render() {
    if (!this.props.isOpen) {
      return null;
    }

    const actions = [
      <FlatButton
        label='Close'
        onClick={() => this.props.onRequestClose()}
        onTouchTap={() => this.props.onRequestClose()}
      />
    ];

    const { student } = this.props;
    const usesDefault =
      student.profilePictureUrl === student.defaultProfilePicture;

    return (
      <Dialog
        autoDetectWindowHeight={false}
        title={`${student.name}'s Profile Picture Management`}
        actions={actions}
        modal={false}
        open={this.props.isOpen}
        onRequestClose={() => this.props.onRequestClose()}
      >
        {this.state.loading ? (
          <Loading />
        ) : (
          <div>
            {this.renderNothingToDo(student, usesDefault)}
            {this.renderManagement(student, usesDefault)}
          </div>
        )}
      </Dialog>
    );
  }
}
