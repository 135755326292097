import gql from 'graphql-tag';

export const meetingCreatedSubscription = gql`
    subscription {
        meetingCreated {
            id
            hostId
            hostEmail
            hostName
            meetingName
            meetingNumber
            meetingTime
            isStarted
            isFinished
            meetingCode
            participantsList {
              userId
              userEmail
              userPhoneNumber
              registeredAt
           }
        }
    }
`