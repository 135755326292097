import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import { FlatButton } from 'material-ui';

export class YesNoModal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    disableYes: PropTypes.bool,
    title: PropTypes.string,
    onYes: PropTypes.func,
    onNo: PropTypes.func,
    showClose: PropTypes.bool,
    hideButtons: PropTypes.bool
  };

  render() {
    const actions = this.props.hideButtons
      ? []
      : this.props.showClose
      ? [
          <FlatButton
            label='Close'
            onTouchTap={() => this.props.onNo()}
            onClick={() => this.props.onNo()}
          />
        ]
      : [
          <FlatButton
            label='No'
            onTouchTap={() => this.props.onNo()}
            onClick={() => this.props.onNo()}
          />,
          <FlatButton
            label='Yes'
            disabled={this.props.disableYes}
            onClick={() => this.props.onYes()}
            onTouchTap={() => this.props.onYes()}
          />
        ];

    return (
      <Dialog
        title={this.props.title}
        actions={actions}
        modal={true}
        open={this.props.open}
      >
        {this.props.children}
      </Dialog>
    );
  }
}
