import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, RaisedButton, TextField } from 'material-ui';
import { Loading } from '../../common/components/loading.component';
import { Image } from '../../common/components/image.component';
import { Theme } from '../../theme/theme';

const INITIAL_STATE = {
  isLoading: false,
  isDone: false,
  error: null,
  duringRejection: false,
  rejectionReason: ''
};

const styles = {
  changeArrow: {
    verticalAlign: 'top',
    marginLeft: 15,
    marginRight: 15,
    marginTop: 80
  },
  button: {
    marginLeft: 10
  },
  parent: {
    textAlign: 'center'
  }
};

export class PhotoApprovalDialogView extends Component {
  static propTypes = {
    approve: PropTypes.func.isRequired,
    reject: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func,
    approval: PropTypes.object,
    open: PropTypes.bool.isRequired
  };

  constructor() {
    super();

    this.state = INITIAL_STATE;
  }

  reject() {
    this.setState({ isLoading: true, isDone: false, error: null });

    this.props
      .reject(this.props.approval.id, this.state.rejectionReason)
      .then(({ data }) => {
        if (!data.rejectPhoto) {
          throw new Error('אופס, משהו השתבש!');
        }

        this.setState({ isLoading: false, error: null, isDone: true });
      })
      .catch(e => {
        this.setState({ isLoading: false, error: e });
      });
  }

  askForRejectionReason() {
    this.setState({
      isLoading: false,
      isDone: false,
      error: null,
      duringRejection: true,
      rejectionReason: ''
    });
  }

  approve() {
    this.setState({
      isLoading: true,
      isDone: false,
      error: null,
      duringRejection: false
    });

    this.props
      .approve(this.props.approval.id)
      .then(({ data }) => {
        if (!data.approvePhoto) {
          throw new Error('אופס, משהו השתבש!');
        }

        this.setState({ isLoading: false, error: null, isDone: true });
      })
      .catch(e => {
        this.setState({ isLoading: false, error: e });
      });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.approval === null && this.props.approval !== null) {
      this.setState(INITIAL_STATE, () => {
        this.props.onRequestClose();
      });
    }
  }

  close() {
    this.props.onRequestClose();
  }

  cancel() {
    if (this.state.duringRejection && !this.state.isDone && !this.state.error) {
      this.setState({ duringRejection: false });
    } else {
      this.close();
    }
  }

  renderContent(approval) {
    if (this.state.isLoading) {
      return <Loading />;
    }

    if (this.state.error !== null) {
      return <div>{this.state.error.toString()}</div>;
    }

    if (this.state.isDone) {
      return (
        <div style={styles.parent}>
          <div>
            {this.props.approval.user.name} photo has been{' '}
            <strong>
              {this.state.duringRejection ? 'rejected' : 'approved'}
            </strong>
            .
          </div>
        </div>
      );
    }

    let innerContent;

    if (this.state.duringRejection) {
      innerContent = (
        <div>
          <span>
            Please tell {approval.user.name} what's wrong with his photo:
          </span>
          <br />
          <TextField
            hintText='Rejection reason'
            style={{ marginLeft: 30, marginRight: 30 }}
            fullWidth={true}
            onChange={(event, newValue) =>
              this.setState({ rejectionReason: newValue })
            }
          />
        </div>
      );
    } else {
      innerContent = (
        <div style={{ display: 'inline-block' }} key='new'>
          <Image
            style={{ borderRadius: 15 }}
            size={160}
            src={approval.imageUrl}
          />
          <br />
          <div>
            <strong>{this.props.approval.label}</strong>
          </div>
        </div>
      );
    }

    return <div style={styles.parent}>{innerContent}</div>;
  }

  render() {
    if (!this.props.approval) {
      return null;
    }

    const { approval } = this.props;

    const actions =
      this.state.isDone || this.state.error
        ? [
            <RaisedButton
              secondary={true}
              style={styles.button}
              label='Close'
              onClick={() => this.cancel()}
              onTouchTap={() => this.cancel()}
            />
          ]
        : [
            <RaisedButton
              disabled={this.state.isLoading}
              style={{ ...styles.button, float: 'left' }}
              label={this.state.duringRejection ? 'Back' : 'Cancel'}
              onClick={() => this.cancel()}
              onTouchTap={() => this.cancel()}
            />,
            <RaisedButton
              backgroundColor={Theme.ACTIVE2_COLOR}
              disabled={
                this.state.isLoading ||
                (this.state.duringRejection &&
                  this.state.rejectionReason === '')
              }
              secondary={true}
              style={styles.button}
              label='Reject'
              onClick={() =>
                this.state.duringRejection
                  ? this.reject()
                  : this.askForRejectionReason()
              }
              onTouchTap={() =>
                this.state.duringRejection
                  ? this.reject()
                  : this.askForRejectionReason()
              }
            />
          ];

    if (!this.state.duringRejection && !this.state.isDone) {
      actions.push(
        <RaisedButton
          disabled={this.state.isLoading || this.state.duringRejection}
          style={styles.button}
          label='Approve'
          primary={true}
          onClick={() => this.approve()}
          onTouchTap={() => this.approve()}
        />
      );
    }

    return (
      <Dialog
        title={
          this.state.isDone
            ? 'Done!'
            : `${this.state.duringRejection ? 'Reject' : 'Approve'} ${
                approval.user.name
              }'s photo?`
        }
        actions={actions}
        modal={true}
        open={this.props.open}
      >
        {this.renderContent(approval)}
      </Dialog>
    );
  }
}
