import { graphql } from 'react-apollo';
import { ManageCourseStudentsDialogView } from './manage-course-students.component';
import { removeStudentFromCourseMutation } from '../graphql/remove-student-from-course.mutation';
import { addStudentToCourseMutation } from '../graphql/add-student-to-course.mutation';
import { studentsQuery } from '../graphql/students.query';
import { connect } from 'react-redux';
import { updateStudentsSearch } from '../courses.actions';

const withRemoveMutation = graphql(removeStudentFromCourseMutation, {
  options: {
    refetchQueries: ['allCourses', 'statistics'],
  },
  props: ({ ownProps, mutate }) => ({
    removeStudentFromCourse: variables => mutate({ variables }),
  }),
});

const withAddMutation = graphql(addStudentToCourseMutation, {
  options: {
    refetchQueries: ['allCourses', 'statistics'],
  },
  props: ({ ownProps, mutate }) => ({
    addStudentToCourse: variables => mutate({ variables }),
  }),
});

const mapStateToProps = ({ courses }) => ({
  search: courses.studentsSearch,
});

const mapDispatch = dispatch => ({
  updateSearch: search => dispatch(updateStudentsSearch(search)),
});

const withState = connect(mapStateToProps, mapDispatch);

const withAutocompleteQuery = graphql(studentsQuery, {
  name: 'students',
  options: props => ({
    fetchPolicy: 'network-only',
    variables: {
      search: props.search,
    },
  }),
});

export const ManageCourseStudentsDialog =
  withState(withAddMutation(withRemoveMutation(withAutocompleteQuery(ManageCourseStudentsDialogView))));
