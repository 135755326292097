export const PLATFORMS = [
  {
    identifier: 'NEWS_FEED',
    name: 'Mobile App News Feed',
  },
  // {
  //   identifier: 'XIBO',
  //   name: 'Xibo Digital Signage',
  // },
];
