import gql from 'graphql-tag';

export const newsFeedEventFragment = gql`
  fragment EventFields on NewsFeedEventItem {
    id
    published
    title
    categories {
      id
      icon
      name
    }
    mainCategory {
      id
      icon
      name
    }
    pinned
    allDay
    cancelled
    description
    locationName
    posterImageUrl
    startTime
    endTime
    previousEventDate
    previousEventImages
    author {
      name
      profilePictureUrl
    }
    remindersCount
    whoToContact
    targets
    platforms
  }
`;
