import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { YesNoModal } from '../../common/components/yes-no-modal.component';
import { MenuItem, SelectField } from 'material-ui';
import { Loading } from '../../common/components/loading.component';

export class RemoveCategoryModalView extends Component {
  static propTypes = {
    categories: PropTypes.array,
    removeEntity: PropTypes.any,
    remove: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func.isRequired,
  };

  constructor() {
    super();

    this.state = {
      transferCategory: null,
      loading: false,
      error: null,
      done: false,
    };
  }

  removeAndTransfer(category) {
    this.setState({ loading: true });

    this.props.remove(category.id, this.state.transferCategory.id)
      .then(() => {
        this.setState({ error: null, done: true, loading: false });
      })
      .catch(e => this.setState({ error: e, loading: false, done: false }));
  }

  close() {
    this.setState({
      done: false,
      loading: false,
      transferCategory: null,
      error: null,
    }, () => {
      this.props.onRequestClose();
    });
  }

  render() {
    const { removeEntity } = this.props;

    if (removeEntity === null) {
      return null;
    }

    return (
      <YesNoModal
        onYes={() => this.removeAndTransfer(removeEntity)}
        onNo={() => this.close()}
        hideButtons={this.state.loading}
        showClose={this.state.done}
        disableYes={this.state.transferCategory === null}
        open={removeEntity !== null}
        title={`Remove Category: ${removeEntity.name}`}>
        {
          this.state.done ? 'Done! Category was removed, and feed items were transferred.' : this.state.loading ? <Loading /> : (
            <div>
              <strong>Are you sure? remove this category?</strong><br /><br />
              <strong>This action is permanently!</strong><br /><br />
              You have to choose another category to transfer the existing feed items to:
              <SelectField
                floatingLabelText="Select Category"
                value={this.state.transferCategory}
                onChange={(event, index, value) => {
                  this.setState({ transferCategory: value });
                }}>
                {
                  this.props.categories.filter(cat => cat.id !== removeEntity.id).map(category => (
                    <MenuItem
                      value={category}
                      key={category.id}
                      primaryText={category.name} />
                  ))
                }
              </SelectField>
            </div>
          )
        }
      </YesNoModal>);
  }
}
