import gql from 'graphql-tag';

export const waitingLiveFeedApprovalsQuery = gql`
  query waitingLiveFeedApprovals {
    waitingEventLiveFeedImagesApprovals {
      createdAt
      updatedAt
      id
      photoUrl
      uploadedBy {
        id
        username
        name
        profilePictureUrl
      }
      event {
        title
      }
    }
  }
`;
