import React, { Component } from 'react';
import { createFeedContainer } from '../../feed/components/feed-items';
import moment from 'moment';
import { FontAwesome } from '../../common/components/font-awesome';
import { Dialog, RaisedButton } from 'material-ui';
import { Analytics } from '../../analytics/components/analytics.container';

const tableColumns = () => [
  { headerName: 'Title', width: 20, valueFrom: 'title' },
  { headerName: 'Gender', width: 8, valueFrom: 'gender' },
  { headerName: 'Type', width: 15, valueFrom: 'type' },
  { headerName: 'Score', width: 7, valueFrom: 'score' },
  {
    headerName: 'Time',
    width: 12,
    style: {
      fontSize: 10
    },
    render: item => (
      <span>{moment(item.startTime).format(item.allDay ? 'L' : 'L LT')}</span>
    )
  },
  {
    header: () => (
      <FontAwesome
        style={{ fontSize: 12, color: 'white', marginRight: 10 }}
        name='bell'
      />
    ),
    width: 5,
    valueFrom: 'remindersCount'
  }
];

export const SportsEventsTable = createFeedContainer(
  'SPORTS_EVENT',
  'Sports Events',
  () => [],
  tableColumns,
  null,
  (item, componentContext) => [
    <FontAwesome
      tooltip='Analytics'
      onClick={() => componentContext.props.onAnalyticsClicked(item)}
      key={1}
      style={{ cursor: 'pointer', fontSize: 12, marginRight: 10 }}
      name='line-chart'
    />,
    <a href={item.link} target={'_blank'} key={2} style={{ cursor: 'pointer' }}>
      MSJSports.com
    </a>
  ],
  { readonly: true, hideTargets: true, hideAddButton: true }
);

export class SportsEvents extends Component {
  constructor() {
    super();

    this.state = {
      analytics: null
    };
  }

  openAnalytics(event) {
    this.setState({
      analytics: event
    });
  }

  getAnalyticsParams() {
    return [
      {
        category: 'Feed',
        action: 'EventView',
        identifier: this.state.analytics.id,
        name: 'Total Views'
      },
      {
        category: 'Feed',
        action: 'EventView',
        identifier: this.state.analytics.id,
        name: 'Total Views (Unique)',
        flags: { unique: true }
      },
      {
        category: 'Feed',
        action: 'ExportEvent',
        identifier: this.state.analytics.id,
        name: 'Export to calendar (Unique)',
        flags: { unique: true }
      },
      {
        category: 'Feed',
        action: 'NavigateToAttendeeList',
        identifier: this.state.analytics.id,
        name: 'Navigate to Event Wall (Unique)',
        flags: { unique: true }
      },
      {
        category: 'Feed',
        action: 'NavigateToEventWall',
        identifier: this.state.analytics.id,
        name: 'Navigate to Attendees List (Unique)',
        flags: { unique: true }
      },
      {
        category: 'Feed',
        action: 'RSVP',
        identifier: this.state.analytics.id,
        name: 'RSVP (Unique)',
        flags: { unique: true }
      },
      {
        category: 'Feed',
        action: 'ToggleReminder',
        identifier: this.state.analytics.id,
        name: 'Reminder Set (Unique)',
        flags: { unique: true }
      }
    ];
  }

  render() {
    return (
      <div>
        <Dialog
          onRequestClose={() => this.openAnalytics(null)}
          title={
            this.state.analytics
              ? `Analytics for sports event: ${this.state.analytics.title}`
              : ''
          }
          actions={[
            <RaisedButton
              primary={true}
              label='Close'
              onClick={() => this.openAnalytics(null)}
              onTouchTap={() => this.openAnalytics(null)}
            />
          ]}
          contentStyle={{
            position: 'absolute',
            left: '50%',
            top: '30%',
            transform: 'translate(-50%, -50%)'
          }}
          open={this.state.analytics !== null}
        >
          {this.state.analytics && (
            <Analytics params={this.getAnalyticsParams()} />
          )}
        </Dialog>
        <SportsEventsTable
          onAnalyticsClicked={event => this.openAnalytics(event)}
        />
      </div>
    );
  }
}
