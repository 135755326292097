import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MenuItem, SelectField } from 'material-ui';

export class CategoriesPickerView extends Component {
  static propTypes = {
    categoriesData: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      error: PropTypes.any,
      categories: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
      })),
    }).isRequired,
    categories: PropTypes.arrayOf(PropTypes.string),
    mainCategory: PropTypes.string,
    onCategoriesChange: PropTypes.func,
    onMainCategoryChange: PropTypes.func,
  };

  constructor(props) {
    super(props);

    console.log("Categories Data", this.props.categoriesData)
    console.log("Categories!", this.props.categories)

    this.state = {
      categories: props.categories || [],
      mainCategory: props.mainCategory || null,
    };

  }

  componentWillReceiveProps(newProps) {
    if ((newProps.categories || []).join(',') !== (this.state.categories || []).join(',')) {
      this.setState({
        categories: newProps.categories,
      });
      console.log("CategoriesProps", this.props.categories)

    }

    if (newProps.mainCategory !== this.state.mainCategory) {
      this.setState({
        mainCategory: newProps.mainCategory,
      });
    }
  }

  render() {
    let categories = [];

    if (this.props.categoriesData && this.props.categoriesData.categories) {
      categories = this.props.categoriesData.categories;
    }
    console.log("CategoriesRender", categories)

    return (
      <div>
        <SelectField
          floatingLabelText="Relevant Categories"
          value={this.state.categories}
          multiple={true}
          onChange={(event, index, values) => {
            this.props.onCategoriesChange(values);
            this.setState({ categories: values });
          }}>
          {
            categories.map(category => (
              <MenuItem
                value={category.id}
                key={category.id}
                insetChildren={true}
                checked={this.state.categories && this.state.categories.includes(category.id)}
                primaryText={category.name} />
            ))

          }
        </SelectField>
        <SelectField
          style={{ marginLeft: 10 }}
          floatingLabelText="Main Category"
          value={this.state.mainCategory}
          onChange={(event, index, value) => {
            this.props.onMainCategoryChange(value);
            this.setState({ mainCategory: value });
          }}>
          {
            this.state.categories.map(category => (
              <MenuItem
                value={category}
                key={category}
                primaryText={
                  (((this.props.categoriesData || {}).categories || []).find(cat => cat.id === category) || {}).name || ''} />
            ))
          }
        </SelectField>
      </div>
    );
  }
}
