import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, FlatButton, RaisedButton, TextField } from 'material-ui';
import EmojiPicker from 'emojione-picker';
import config from '../../core/config/config';
import { Loading } from '../../common/components/loading.component';

const PREDEFINED_ICONS = [
  'academic.png',
  'enrichment.png',
  'hangout.png',
  'studentLeadership.png',
  'sports.png',
  'social.png',
  'ministry.png',
  'studentEvents.png',
  'specialEvents.png',
  'other.png'
];

export class AddEditCategoryModalView extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    category: PropTypes.any,
    createUpdate: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func.isRequired
  };

  constructor() {
    super();

    this.state = {
      categoryName: '',
      icon: null,
      tempCustomUrl: '',
      updateId: null,
      loading: false,
      done: false
    };

    const serverConfig = config.server;
    this.serverUrl = `${serverConfig.protocol}://${serverConfig.host}${
      serverConfig.port !== 0 ? `:${serverConfig.port}` : ''
    }`;
  }

  componentWillMount() {
    this.checkForUpdate(this.props.category);
  }

  componentWillReceiveProps(nextProps) {
    this.checkForUpdate(nextProps.category);
  }

  checkForUpdate(category) {
    if (
      category &&
      !this.isCreate(category) &&
      category.id &&
      category.id !== this.state.updateId
    ) {
      this.setState({
        updateId: category.id,
        categoryName: category.name,
        icon: category.icon
      });
    }
  }

  save() {
    this.setState({ done: false, loading: true });

    this.props
      .createUpdate(this.state.updateId, {
        name: this.state.categoryName,
        icon: this.state.icon
      })
      .then(() => {
        this.setState({ done: true, loading: false });
      })
      .catch(e =>
        this.setState({ error: e.message, done: false, loading: false })
      );
  }

  isCreate(category) {
    return category !== null && Object.keys(category).length === 0;
  }

  setFromEmoji(unicode) {
    const iconUrlOnServer = `${this.serverUrl}/emojione/${unicode}.png`;

    this.setState({
      icon: iconUrlOnServer
    });
  }

  close() {
    this.setState(
      {
        done: false,
        loading: false,
        updateId: null,
        categoryName: '',
        tempCustomUrl: '',
        icon: null
      },
      () => {
        this.props.onRequestClose();
      }
    );
  }

  render() {
    if (this.props.category === null) {
      return null;
    }

    const actions = this.state.loading
      ? []
      : this.state.done
      ? [
          <FlatButton
            label='Close'
            onClick={() => this.close()}
            onTouchTap={() => this.close()}
          />
        ]
      : [
          <FlatButton
            label='Cancel'
            onClick={() => this.close()}
            onTouchTap={() => this.close()}
          />,
          <RaisedButton
            label='Save'
            disabled={
              this.state.categoryName === '' || this.state.icon === null
            }
            primary={true}
            onClick={() => this.save()}
            onTouchTap={() => this.save()}
          />
        ];

    return (
      <Dialog
        autoDetectWindowHeight={false}
        title={
          !this.isCreate(this.props.category)
            ? `Edit Category: ${this.props.category.name}`
            : 'Create New Feed Category'
        }
        actions={actions}
        modal={true}
        open={this.props.isOpen}
        onRequestClose={() => this.close()}
      >
        {this.state.loading ? (
          <Loading />
        ) : this.state.done ? (
          `Category ${this.state.categoryName} ${
            this.isCreate(this.props.category) ? ' created!' : ' updated!'
          }`
        ) : (
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <div style={{ height: 40, width: 40, marginRight: 20 }}>
                {this.state.icon ? (
                  <img
                    src={this.state.icon}
                    alt='Category Icon'
                    style={{ maxWidth: 40 }}
                  />
                ) : null}
              </div>
              <TextField
                fullWidth={true}
                floatingLabelText='Category Name'
                value={this.state.categoryName}
                onChange={(event, newValue) =>
                  this.setState({ categoryName: newValue })
                }
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ marginRight: 10 }}>
                <p>Icon from Emoji:</p>
                <EmojiPicker
                  search={true}
                  onChange={data => this.setFromEmoji(data.unicode)}
                />
              </div>
              <div
                style={{ paddingLeft: 10, borderLeft: '1px lightgrey solid' }}
              >
                <div>
                  <p>Icon from predefined icons:</p>
                  {PREDEFINED_ICONS.map(fileName => {
                    const imageUrl = `${this.serverUrl}/icons/${fileName}`;

                    return (
                      <img
                        key={fileName}
                        onClick={() => this.setState({ icon: imageUrl })}
                        alt={fileName}
                        src={imageUrl}
                        style={{ maxWidth: 25, margin: 5, cursor: 'pointer' }}
                      />
                    );
                  })}
                </div>
                <div>
                  <p>Icon from custom URL:</p>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                  >
                    <TextField
                      fullWidth={true}
                      floatingLabelText='Custom URL'
                      value={this.state.tempCustomUrl}
                      onChange={(event, newValue) =>
                        this.setState({ tempCustomUrl: newValue })
                      }
                    />
                    <FlatButton
                      label='Set'
                      disabled={this.state.tempCustomUrl === ''}
                      onClick={() =>
                        this.setState({
                          icon: this.state.tempCustomUrl,
                          tempCustomUrl: ''
                        })
                      }
                      onTouchTap={() =>
                        this.setState({
                          icon: this.state.tempCustomUrl,
                          tempCustomUrl: ''
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Dialog>
    );
  }
}
