import { graphql } from 'react-apollo';
import { StudentVideosModalView } from './student-videos-modal.component';
import { videosQuery } from '../graphql/videos.query';

const withVideosQuery = graphql(videosQuery, {
  name: 'videosViewsByUser',
  options: (props: any) => {
    const userId = props.student && props.student.id;

    return {
      fetchPolicy: 'cache-and-network',
      skip: !userId,
      variables: {
        userId
      }
    };
  }
});

export const StudentVideosModal = withVideosQuery(
  StudentVideosModalView as any
) as any;
