import React, { Component } from 'react';
import CircularProgress from 'material-ui/CircularProgress';
import { Theme } from '../../theme/theme';

const styles = {
  loadingContainer: {
    width: '100%',
    height: '100%',
    paddingTop: 20,
  },
  loading: {
    display: 'block',
    margin: '0 auto',
  },
};

export class Loading extends Component {
  render() {
    if (this.props.small) {
      return (
        <CircularProgress style={styles.loading} color={Theme.SECONDARY_COLOR} size={30} thickness={4} />
      );
    }
    return (
      <div style={styles.loadingContainer}>
        <CircularProgress style={styles.loading} color={Theme.SECONDARY_COLOR} size={60} thickness={7} />
      </div>
    );
  }
}
