import { combineReducers } from 'redux';
import { EVideosViewActions } from './videos.actions';

export const VIDEOS_PER_PAGE = 10;

const search = (state = '', action) => {
  if (
    action.type === EVideosViewActions.CHANGE_FILTER &&
    action.search !== undefined
  ) {
    return action.search;
  }

  return state;
};

const studentsSearch = (state = '', action) => {
  if (action.type === EVideosViewActions.CHANGE_STUDENTS_FILTER) {
    return action.search;
  }

  return state;
};

const skip = (state = 0, action) => {
  if (
    action.type === EVideosViewActions.CHANGE_FILTER &&
    action.skip !== undefined
  ) {
    return action.skip;
  }

  return state;
};

const limit = (state = VIDEOS_PER_PAGE, action) => {
  if (
    action.type === EVideosViewActions.CHANGE_FILTER &&
    action.limit !== undefined
  ) {
    return action.limit;
  }

  return state;
};

const editEntity = (state = null, action) => {
  if (action.type === EVideosViewActions.EDIT_ENTITY) {
    return action.entity;
  }

  return state;
};

const isEditMode = (state = false, action) => {
  if (action.type === EVideosViewActions.EDIT_ENTITY) {
    return action.open;
  }

  return state;
};

export const videos = combineReducers({
  search,
  skip,
  limit,
  editEntity,
  isEditMode,
  studentsSearch
});
