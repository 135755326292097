import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import { AppRouter } from './router';
import { initClient } from './core/apollo/apollo';
import config from './core/config/config';
import { ApolloProvider } from 'react-apollo';
// import injectTapEventPlugin from 'react-tap-event-plugin';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { Theme } from './theme/theme';

// injectTapEventPlugin();
const client = initClient(config);

const muiTheme = getMuiTheme({
  palette: {
    textColor: Theme.SECONDARY_TEXT_COLOR,
    primary1Color: Theme.PRIMARY_COLOR,
    accent1Color: Theme.SECONDARY_COLOR
  },
  datePicker: {
    selectColor: Theme.SECONDARY_COLOR
  },
  timePicker: {
    selectColor: Theme.SECONDARY_COLOR,
    headerColor: Theme.SECONDARY_COLOR
  },
  textField: {
    hintColor: Theme.PRIMARY_COLOR,
    floatingLabelColor: Theme.PRIMARY_COLOR
  }
});

ReactDOM.render(
  <MuiThemeProvider muiTheme={muiTheme}>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <AppRouter />
      </Provider>
    </ApolloProvider>
  </MuiThemeProvider>,
  document.getElementById('root')
);
