export const EVideosViewActions = {
  CHANGE_FILTER: 'videos/CHANGE_FILTER',
  CHANGE_STUDENTS_FILTER: 'videos/CHANGE_STUDENTS_FILTER',
  EDIT_ENTITY: 'videos/EDIT_ENTITY'
};

export const updateVideosFilter = filterChanges => ({
  type: EVideosViewActions.CHANGE_FILTER,
  ...filterChanges
});

export const updateStudentsSearch = search => ({
  type: EVideosViewActions.CHANGE_STUDENTS_FILTER,
  search
});

export const editEntity = editableEntity => ({
  type: EVideosViewActions.EDIT_ENTITY,
  open: !!editableEntity,
  entity: editableEntity
});
