import gql from 'graphql-tag';

export const helpdeskChatQuery = gql`
    query helpdeskChat($id: String!, $cursor: String) {
        helpdeskChat(id: $id) {
            id
            title
            users(withMe: false) {
                id
                username
                name
                profilePictureUrl
            }
            messages(cursor: $cursor) {
                messages {
                    id
                    createdAt(momentFormat: "LT")
                    createdAtDay: createdAt(momentFormat: "ll")
                    serverMessage
                    content
                    imageThumb: content(imageParams: { height: "300", width: "300", crop: FIT })
                    messageType
                    author {
                        id
                        username
                        name
                        profilePictureUrl
                    }
                }
                hasMore
                cursor
            }
        }
    }
`;
