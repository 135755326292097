import React, { Component } from 'react';
import PropTypes from 'prop-types';
import defaultProfileImage from '../../public/default_profile.png';
import { Avatar } from 'material-ui';

export class ProfileAvatar extends Component {
  static propTypes = {
    style: PropTypes.any,
    src: PropTypes.string,
    size: PropTypes.number,
  };

  render() {
    return (
      <Avatar
        style={this.props.style || null}
        src={this.props.src || defaultProfileImage}
        size={this.props.size || 40}
      />
    );
  }
}
