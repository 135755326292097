import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField';
import Subheader from 'material-ui/Subheader';
import { List, ListItem } from 'material-ui/List';
import { VideoButtonPicker } from '../../common/components/video-button-picker.component';
import { RaisedButton } from 'material-ui';
import { Theme } from '../../theme/theme';
import { Loading } from '../../common/components/loading.component';
import { FontAwesome } from '../../common/components/font-awesome';
import SelectField from 'material-ui-superselectfield'
import { ProfileAvatar } from '../../common/components/profile-avatar.component';
import axios from 'axios';
import { select } from 'async';

const styles = {
  header: {
    lineHeight: 'initial',
    paddingTop: 10
  },
  miniListItem: {
    paddingTop: 0,
    paddingBottom: 0
  },
  identifierField: {
    width: 230,
    marginLeft: 15
  },
  dropdownField: {
    width: 230,
    marginLeft: 15,
    color: 'rgb(54, 161, 70)'
  },
  lecturerField: {
    width: 100,
    marginRight: 10
  },
  avatar: {
    marginTop: 12
  },
  headerNote: {
    color: Theme.ACTIVE2_COLOR
  },
  dropdownOptionStyle: {
    color: 'rgb(54, 161, 70)'
  }
};

export class CreateEditVideoFormView extends Component {
  static propTypes = {
    video: PropTypes.any.isRequired,
    disabled: PropTypes.bool,
    createVideo: PropTypes.func.isRequired
  };

  constructor() {
    super();

    this.state = {
      isLoading: false,
      isDone: false,
      error: null,
      order: '',
      yocasType: {},
      name: '',
      label: '',
      data: '',
      duration: '',
      previewImage: '',
      isUpdate: false
    };
  }

  componentDidMount() {
    const { video } = this.props;

    if (Object.keys(video).length > 0) {
      let yocasTypeInit = {}
      if (video.yocasType) {
        yocasTypeInit = {
          value: video.yocasType,
          label: video.yocasType.charAt(0).toUpperCase() + video.yocasType.slice(1)
        }
      }
      console.log(video, 'videovideovideo')
      //previewImage
      this.setState({
        order: video.order,
        name: video.name,
        label: video.label,
        data: video.data ? video.data : '',
        yocasType: yocasTypeInit,
        previewImage: video.previewImage,
        duration: video.duration,
        isUpdate: video.data ? false : true
      });
    }
  }

  onFileLoad(data) {
    this.setState({
      data: data,
      isUpdate: true
    });
  }

  onPreviewImageLoad(previewImage) {
    this.setState({
      previewImage: previewImage
    });
  }

  onDurationLoad(duration) {
    this.setState({
      duration: duration
    });
  }

  async onSubmit() {
    this.setState({
      isLoading: true,
      error: null
    });

    this.props
      .createVideo({
        existingId: this.props.video.id,
        order: this.state.order,
        name: this.state.name,
        label: this.state.label,
        data: this.state.data,
        duration: this.state.duration,
        previewImage: this.state.previewImage,
        yocasType: this.state.yocasType.value,
        isUpdate: false
      })
      .then(() => {
        this.setState({
          isDone: true,
          isLoading: false
        });
      })
      .catch(err => {
        this.setState({
          error: err,
          isLoading: false
        });
      });
  }

  isFormValid() {
    return (
      this.state.name &&
      this.state.label.length > 2 &&
      this.state.data &&
      this.state.previewImage &&
      this.state.duration &&
      Object.keys(this.state.yocasType).length > 0
    );
  }

  render() {
    if (this.state.isLoading) {
      return <Loading />;
    }

    if (this.state.isDone) {
      return (
        <div style={{ textAlign: 'center', marginTop: 20 }}>
          <FontAwesome name='check' />
          <br />
          <br />
          Done!
        </div>
      );
    }

    return (
      <div>
        <List>
          {this.state.error ? (
            <Subheader style={styles.headerNote}>
              {this.state.error.toString()}
            </Subheader>
          ) : null}
          <Subheader style={styles.header}>Video Information</Subheader>
          <ListItem innerDivStyle={styles.miniListItem} disabled={true}>
            <TextField
              disabled={this.props.disabled}
              floatingLabelText='Video Name'
              fullWidth={true}
              value={this.state.name}
              onChange={(event, newValue) => this.setState({ name: newValue })}
            />
          </ListItem>
          <ListItem innerDivStyle={styles.miniListItem} disabled={true}>
            <TextField
              style={styles.identifierField}
              disabled={this.props.disabled}
              floatingLabelText='Video Label'
              fullWidth={true}
              value={this.state.label}
              onChange={(event, newValue) => this.setState({ label: newValue })}
            />
          </ListItem>
          <ListItem innerDivStyle={styles.miniListItem} disabled={true}>
            <TextField
              style={styles.identifierField}
              disabled={this.props.disabled}
              floatingLabelText='Video Order'
              fullWidth={true}
              value={this.state.order}
              onChange={(event, newValue) => this.setState({ order: newValue })}
            />
          </ListItem>
          <ListItem innerDivStyle={styles.miniListItem} disabled={true}>
            <SelectField
              style={styles.dropdownField}
              disabled={this.props.disabled}
              value={this.state.yocasType}
              onChange={(selected) => { this.setState({ yocasType: selected }) }}>
              <span style={styles.dropdownOptionStyle} value={'Pose'} label='Pose'>
                Pose
            </span>
              <span style={styles.dropdownOptionStyle} value={'session'} label='Session'>
                Session
            </span>
              <span style={styles.dropdownOptionStyle} value={'modification'} label='Modification'>
                Modification
            </span>
            </SelectField>
          </ListItem>
          <ListItem innerDivStyle={styles.miniListItem} disabled={true}>
            {this.props.disabled ? null : (
              <VideoButtonPicker
                disabled={this.props.disabled}
                label='Select video file'
                onFile={data => this.onFileLoad(data)}
                onDuration={duration => this.onDurationLoad(duration)}
                onPreviewImage={previewImage =>
                  this.onPreviewImageLoad(previewImage)
                }
              />
            )}
          </ListItem>
          {this.props.disabled ? null : (
            <ListItem disabled={true}>
              <RaisedButton
                onClick={() => this.onSubmit()}
                disabled={this.props.disabled || !this.isFormValid()}
                primary={true}
                label='SAVE'
                fullWidth={true}
              />
            </ListItem>
          )}
        </List>
      </div>
    );
  }
}
