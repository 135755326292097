import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Checkbox, DatePicker, RaisedButton, TextField } from 'material-ui';
import { CategoriesPicker } from '../../common/components/categories-picker.container';
import { DataBox } from '../../common/components/data-box.component';
import { Loading } from '../../common/components/loading.component';
import { AMPMTimePicker } from '../../common/components/time-picker.rn';

const styles = {
  inline: {
    display: 'inline-block',
    marginHorizontal: 3
  }
};

export class CreateUpdatePollFormView extends Component {
  static propTypes = {
    editEntityId: PropTypes.string,
    editEntity: PropTypes.object,
    loading: PropTypes.bool,
    createUpdatePoll: PropTypes.func.isRequired
  };

  constructor() {
    super();

    this.state = {
      error: null,
      isUpdating: false,
      categories: [],
      mainCategory: null,
      limitStart: false,
      limitEnd: false,
      question: '',
      optionA: '',
      optionB: '',
      optionC: '',
      optionD: '',
      optionE: '',
      startDate: null,
      endDate: null,
      startTime: moment().format('hh:mm A'),
      endTime: moment().format('hh:mm A')
    };
  }

  getTransformedOptions() {
    return [
      this.state.optionA,
      this.state.optionB,
      this.state.optionC,
      this.state.optionD,
      this.state.optionE
    ].filter(item => item !== '');
  }

  savePoll() {
    console.log('inside save poll');
    let { startDate, startTime, endDate, endTime } = this.state;
    startTime =
      startTime && startTime !== ''
        ? moment(startTime, 'hh:mm A').toDate()
        : null;
    endTime =
      endTime && endTime !== '' ? moment(endTime, 'hh:mm A').toDate() : null;

    const mergedStartTime = startDate
      ? new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate(),
          startTime ? startTime.getHours() : 0,
          startTime ? startTime.getMinutes() : 0,
          startTime ? startTime.getSeconds() : 0
        )
      : null;
    const mergedEndTime = endDate
      ? new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate(),
          endTime ? endTime.getHours() : 0,
          endTime ? endTime.getMinutes() : 0,
          endTime ? endTime.getSeconds() : 0
        )
      : null;

    this.setState({ isUpdating: true });

    console.log('question', this.state.question);
    console.log('categories', this.state.categories);
    console.log('main category', this.state.mainCategory);
    console.log('possiblesAnswers', this.getTransformedOptions());
    console.log(
      'startTime',
      mergedStartTime ? moment.utc(mergedStartTime).toDate() : null
    );
    console.log(
      'endTime',
      mergedEndTime ? moment.utc(mergedEndTime).toDate() : null
    );

    this.props
      .createUpdatePoll(this.props.editEntityId, {
        question: this.state.question,
        categories: this.state.categories,
        mainCategory: this.state.mainCategory,
        possibleAnswers: this.getTransformedOptions(),
        startTime: mergedStartTime
          ? moment.utc(mergedStartTime).toDate()
          : null,
        endTime: mergedEndTime ? moment.utc(mergedEndTime).toDate() : null
      })
      .then(({ data }) => {
        console.log('got data', data, this.props.editEntityId);
        this.setState({ isUpdating: false });
        this.props.navigate(`/admin/polls/publish/${data.createUpdatePoll.id}`);
      })
      .catch(e => this.setState({ isUpdating: false, error: e }));
  }

  componentDidMount() {
    const { editEntity } = this.props;
    console.log('editentity', editEntity);
    this.setEditEntity(editEntity);
  }

  componentWillReceiveProps(nextProps) {
    const { editEntity } = nextProps;
    console.log('editentity', editEntity);
    this.setEditEntity(editEntity);
  }

  setEditEntity(editEntity) {
    if (editEntity) {
      const answers = editEntity.possibleAnswers.map(item => item.answer);
      const startMoment = editEntity.startTime
        ? moment(editEntity.startTime)
        : null;
      const endMoment = editEntity.endTime ? moment(editEntity.endTime) : null;

      this.setState({
        question: editEntity.question,
        categories: editEntity.categories.map(item => item.id),
        mainCategory: editEntity.mainCategory.id,
        optionA: answers[0] || '',
        optionB: answers[1] || '',
        optionC: answers[2] || '',
        optionD: answers[3] || '',
        optionE: answers[4] || '',
        startTime: editEntity.startTime ? startMoment.format('hh:mm A') : '',
        startDate: editEntity.startTime ? startMoment.toDate() : null,
        endTime: editEntity.endTime ? endMoment.format('hh:mm A') : '',
        endDate: editEntity.endTime ? endMoment.toDate() : null,
        limitStart: !!startMoment,
        limitEnd: !!endMoment
      });
    }
  }

  isEmpty(field) {
    return !field || field === '';
  }

  isSubmitDisabled() {
    return (
      this.getTransformedOptions().length < 2 ||
      this.isEmpty(this.state.question) ||
      this.state.mainCategory === null ||
      this.state.categories.length === 0
    );
  }

  render() {
    if (this.props.loading || this.state.isUpdating) {
      return <Loading />;
    }

    return (
      <div style={{ paddingRight: '3%', paddingLeft: '3%', marginBottom: 20 }}>
        <DataBox title='Poll Details'>
          <div style={{ width: '100%', textAlign: 'center', color: 'red' }}>
            {this.props.error || this.state.error}
          </div>
          <div style={{ overflow: 'hidden' }}>
            <TextField
              floatingLabelText='Poll Question'
              value={this.state.question}
              fullWidth={true}
              onChange={(event, newValue) =>
                this.setState({ question: newValue })
              }
            />
            <CategoriesPicker
              categories={this.state.categories}
              mainCategory={this.state.mainCategory}
              onCategoriesChange={categories => this.setState({ categories })}
              onMainCategoryChange={mainCategory =>
                this.setState({ mainCategory })
              }
            />
          </div>
        </DataBox>
        <DataBox title='Limitations'>
          You can pick the start date (and optional end date) to display this
          item on user's feed.
          <br />
          <br />
          <Checkbox
            label='Limit Start'
            checked={this.state.limitStart}
            onCheck={(e, newState) =>
              this.setState({
                limitStart: newState,
                ...(newState === false
                  ? {
                      startDate: null,
                      startTime: ''
                    }
                  : {})
              })
            }
          />
          {this.state.limitStart ? (
            <div>
              <DatePicker
                firstDayOfWeek={0}
                style={styles.inline}
                autoOk={true}
                floatingLabelText='Start Date'
                value={this.state.startDate}
                onChange={(event, startDate) => this.setState({ startDate })}
                container='inline'
                mode='landscape'
              />
              <AMPMTimePicker
                label='Start Time'
                value={this.state.startTime}
                onChange={(e, v) => this.setState({ startTime: v })}
              />
            </div>
          ) : null}
          <Checkbox
            label='Limit End'
            checked={this.state.limitEnd}
            onCheck={(e, newState) =>
              this.setState({
                limitEnd: newState,
                ...(newState === false
                  ? {
                      endTime: '',
                      endDate: null
                    }
                  : {})
              })
            }
          />
          {this.state.limitEnd ? (
            <div>
              <DatePicker
                firstDayOfWeek={0}
                style={styles.inline}
                autoOk={true}
                floatingLabelText='End Date'
                value={this.state.endDate}
                onChange={(event, endDate) => this.setState({ endDate })}
                container='inline'
                mode='landscape'
              />
              <AMPMTimePicker
                label='End Time'
                value={this.state.endTime}
                onChange={(e, v) => this.setState({ endTime: v })}
              />
            </div>
          ) : null}
        </DataBox>
        <DataBox title='Possible Answers'>
          <TextField
            floatingLabelText='Option A'
            value={this.state.optionA}
            fullWidth={true}
            onChange={(event, newValue) => this.setState({ optionA: newValue })}
          />
          <TextField
            floatingLabelText='Option B'
            value={this.state.optionB}
            fullWidth={true}
            onChange={(event, newValue) => this.setState({ optionB: newValue })}
          />
          <TextField
            floatingLabelText='Option C'
            value={this.state.optionC}
            fullWidth={true}
            onChange={(event, newValue) => this.setState({ optionC: newValue })}
          />
          <TextField
            floatingLabelText='Option D'
            value={this.state.optionD}
            fullWidth={true}
            onChange={(event, newValue) => this.setState({ optionD: newValue })}
          />
          <TextField
            floatingLabelText='Option E'
            value={this.state.optionE}
            fullWidth={true}
            onChange={(event, newValue) => this.setState({ optionE: newValue })}
          />
        </DataBox>
        <RaisedButton
          disabled={this.isSubmitDisabled()}
          onClick={() => this.savePoll()}
          primary={true}
          fullWidth={true}
          label='Continue'
        />
      </div>
    );
  }
}
