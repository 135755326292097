import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme } from '../../theme/theme';

const styles = {
  childContainer: {
    width: '75%',
    display: 'inline-block',
    marginLeft: 15,
  },
  titleContainer: {
    marginLeft: 10,
    borderRight: `1px ${Theme.DESCRIPTION_TEXT_COLOR} solid`,
    display: 'inline-block',
    textAlign: 'center',
    verticalAlign: 'middle',
    color: Theme.SECONDARY_TEXT_COLOR,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: 18,
    flex: 0.8,
    paddingRight: 10,
  },
  container: {
    paddingTop: 7,
    paddingBottom: 7,
    width: '94%',
    marginHorizontal: '3%',
    margin: '0 auto',
    backgroundColor: Theme.TOP_BAR_COLOR,
    marginTop: 10,
    marginBottom: 10,
  },
};

export class FiltersBar extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.any,
  };

  render() {
    return (
      <div style={styles.container}>
        <div style={styles.titleContainer}>
          {this.props.title}
        </div>
        <div style={styles.childContainer}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
