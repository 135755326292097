import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DataTable } from '../../common/components/data-table.component';
import { Loading } from '../../common/components/loading.component';
import { ProfileAvatar } from '../../common/components/profile-avatar.component';

export class EventRSVPListView extends Component {
  static propsTypes = {
    eventId: PropTypes.string,
    goingList: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      error: PropTypes.any,
      eventGoingList: PropTypes.array
    })
  };

  render() {
    if (this.props.goingList.loading) {
      return <Loading />;
    }

    const list =
      this.props.goingList &&
      this.props.goingList.eventGoingList &&
      this.props.goingList.eventGoingList.users
        ? this.props.goingList.eventGoingList.users
        : [];

    const tableDesc = [
      {
        headerName: '',
        width: 15,
        render: item => <ProfileAvatar src={item.profilePictureUrl} />
      },
      { headerName: 'Participant Name', width: 65, valueFrom: 'name' }
    ];

    return (
      <DataTable
        height='200px'
        loading={this.props.goingList.loading}
        skip={0}
        limit={0}
        total={list.length}
        idField='id'
        data={list}
        description={tableDesc}
      />
    );
  }
}
