import gql from 'graphql-tag';

export const modifySocialNetworkMutation = gql`
    mutation modifySocialNetwork($socialNetwork: SocialNetwork!, $userId: String!, $newValue: String, $reset: Boolean) {
        modifySocialNetwork(socialNetwork: $socialNetwork, userId: $userId, newValue: $newValue, reset: $reset) {
            id
            facebook
            facebookName
            linkedin
            linkedinLink
        }
    }
`;
