import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, FlatButton } from 'material-ui';
import { Loading } from '../../common/components/loading.component';
import { DataTable } from '../../common/components/data-table.component';

export class StudentVideosModalView extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    student: PropTypes.any,
    onRequestClose: PropTypes.func,
    deletePhoto: PropTypes.func,
    videosViewsByUser: PropTypes.shape({
      loading: PropTypes.bool,
      views: PropTypes.arrayOf(PropTypes.any)
    })
  };

  constructor() {
    super();

    this.state = {
      loading: false
    };
  }

  renderNothingToDo() {
    return (
      <div style={{ textAlign: 'center' }}>
        <span>
          <strong>{this.props.student.name} has no videos!</strong>
        </span>
      </div>
    );
  }

  getVideoTime(duration) {
    let minutes = Math.floor(duration / 60);
    let seconds = duration - minutes * 60;
    let res = '' + minutes + ':' + (seconds < 10 ? '0' : '');
    res += '' + seconds;
    return res;
  }

  render() {
    const videosColumns = [
      {
        headerName: 'Video Name',
        width: 40,
        valueFrom: 'videoName'
      },
      {
        headerName: 'Date',
        width: 40,
        render: item => item.updatedAt.slice(0, 15)
      },
      {
        headerName: 'Time Watched',
        width: 20,
        render: item => this.getVideoTime(parseInt(item.pauseMinute))
      },
      {
        headerName: 'Completed?',
        width: 20,
        render: item => (item.isCompleted ? 'Yes' : 'No')
      },
      {
        headerName: 'Survey Answer',
        width: 40,
        render: item => item.surveyAnswer
      }
    ];

    if (!this.props.isOpen) {
      return null;
    }

    const actions = [
      <FlatButton
        label='Close'
        onClick={() => this.props.onRequestClose()}
        onTouchTap={() => this.props.onRequestClose()}
      />
    ];

    const { student } = this.props;
    const videosData = this.props.videosViewsByUser.videosViewsByUser.views;
    const count = (videosData || []).length;
    console.log(this.props);
    return (
      <Dialog
        autoDetectWindowHeight={false}
        title={`${student.name}'s Videos`}
        actions={actions}
        modal={false}
        open={this.props.isOpen}
        onRequestClose={() => this.props.onRequestClose()}
      >
        {this.state.loading || this.props.videosViewsByUser.loading ? (
          <Loading />
        ) : (
          <div>
            {count ? (
              <div style={{ ...styles.row, ...styles.tableContainer }}>
                <DataTable
                  height='170px'
                  limit={0}
                  skip={10}
                  total={videosData ? videosData.length : 0}
                  description={videosColumns}
                  data={videosData || []}
                  idField='id'
                  loading={false}
                  multiSelectable={false}
                  selectable={false}
                />
              </div>
            ) : (
              this.renderNothingToDo()
            )}
          </div>
        )}
      </Dialog>
    );
  }
}

const styles = {
  photosList: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  photo: {
    maxWidth: '150px',
    maxHeight: '150px',
    marginRight: '15px',
    flex: '0 0 150px',
    cursor: 'pointer',
    textAlign: 'center',
    overflow: 'hidden'
  },
  photoHover: {
    opacity: 0.7
  },
  containerInModal: {
    textAlign: 'center'
  },
  photoInModal: {
    maxWidth: '500px',
    maxHeight: '500px'
  }
};
