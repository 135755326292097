import { graphql } from 'react-apollo';
import { removeCategoryMutation } from '../graphql/remove-category.mutation';
import { RemoveCategoryModalView } from './remove-category.component';

const withAddEditMutation = graphql(removeCategoryMutation, {
  props: ({ mutate }) => ({
    remove: (categoryId, moveTo) => mutate({
      variables: {
        categoryId,
        moveTo,
      },
      refetchQueries: ['categories'],
    }),
  }),
});

export const RemoveCategoryModal = withAddEditMutation(RemoveCategoryModalView);
