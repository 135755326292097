import { AccountsClient } from '@accounts/client';
import { AccountsClientPassword } from '@accounts/client-password';
import { AccountsGraphQLClient } from '@accounts/graphql-client';
import { initClient } from '../core/apollo/apollo';
import config from '../core/config/config';

const graphQLInterface = new AccountsGraphQLClient({
  graphQLClient: initClient(config),
});

export const accountsClient = new AccountsClient({}, graphQLInterface);

export const accountsPassword = new AccountsClientPassword(accountsClient, { hashPassword: password => password });
