import gql from 'graphql-tag';

export const eventGoingQuery = gql`
  query eventGoingList($eventId: String!) {
    eventGoingList(eventId: $eventId) {
      users {
          name
          profilePictureUrl
          id
      }
    }
  }
`;
