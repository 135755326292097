import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'material-ui';
import InputMask from 'react-input-mask';

export class AMPMTimePicker extends Component {
  static propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.any,
  };

  constructor() {
    super();

    this.state = {
      partOfDay: '',
    };
  }

  componentWillMount() {
    this.setState({
      partOfDay: this.props.value.toLowerCase().includes('am') ? 'am' : 'pm',
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      partOfDay: nextProps.value.toLowerCase().includes('am') ? 'am' : 'pm',
    });
  }

  updateTo(partOfDay) {
    const value = this.props.value;
    const cleanTime = value.toLowerCase().replace('am', '').replace('pm', '').trim();

    this.setState({
      partOfDay,
    });

    this.props.onChange(null, `${cleanTime} ${partOfDay.toUpperCase()}`);
  }

  render() {
    const value = this.props.value;
    const cleanTime = value.toLowerCase().replace('am', '').replace('pm', '').trim();

    const formatChars = {
      1: '[0-1]',
      2: (cleanTime || '').startsWith('1') ? '[0-2]' : '[0-9]',
      3: '[0-5]',
      4: '[0-9]',
    };

    return (
      <div style={{ display: 'inline-block' }}>
        <TextField floatingLabelText={this.props.label}>
          <InputMask
            value={cleanTime}
            onChange={event => this.props.onChange(event, `${event.target.value} ${this.state.partOfDay.toUpperCase()}`)}
            mask="12:34"
            formatChars={formatChars}
            maskChar=" " />
        </TextField>
        <div style={{marginTop: '3%', display: 'flex', width: '20%', justifyContent: 'space-between'}}>
          <div
            onClick={() => this.updateTo('am')}
            style={this.state.partOfDay === 'am' ? { fontWeight: 'bold', cursor: 'pointer' } : { cursor: 'pointer'}}>AM </div>
          <div
            onClick={() => this.updateTo('pm')}
            style={this.state.partOfDay === 'pm' ? { fontWeight: 'bold', cursor: 'pointer' } : { cursor: 'pointer'}}>PM </div>
        </div>
      </div>
    );
  }
}
