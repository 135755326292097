import { graphql } from 'react-apollo';

import { StudentPhotosModalView } from './student-photos-modal.component';
import { deleteUserPhotoMutation } from '../graphql/delete-user-photo.mutation';
import { photosQuery } from '../graphql/photos.query';

const withPhotosQuery = graphql(photosQuery, {
  name: 'photosData',
  options: (props: any) => {
    const userId = props.student && props.student.id;

    return {
      fetchPolicy: 'cache-and-network',
      skip: !userId,
      variables: {
        filter: {
          userId,
        },
      },
    };
  },
});

const withDeletePhotoMutation = graphql(deleteUserPhotoMutation, {
  props: ({ ownProps, mutate }: any) => ({
    deletePhoto: (photoId: string) =>
      mutate!({
        variables: { photoId },
        update: (store: any) => {
          const userId = ownProps.student && ownProps.student.id;
          const options = {
            query: photosQuery,
            variables: {
              filter: {
                userId,
              },
            },
          };

          const data = store.readQuery(options);
          const photos = data.photos.filter((photo: any) => photo.id !== photoId);

          store.writeQuery({ ...options, data: { ...data, photos } });
        },
      }),
  }),
});

export const StudentPhotosModal = withPhotosQuery(withDeletePhotoMutation(StudentPhotosModalView as any)) as any;
