import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Toolbar, ToolbarGroup, ToolbarTitle } from 'material-ui';
import { Theme } from '../../theme/theme';
import { Paper } from 'material-ui';

const styles = {
  child: {
    width: '100%',
    padding: 10,
    overflow: 'scroll',
    flex: 1,
  },
  toolbarTitle: {
    color: Theme.PRIMARY_TEXT_COLOR,
  },
  toolbar: {
    backgroundColor: Theme.SECONDARY_COLOR,
  },
  container: {
    height: '100%',
    marginTop: 10,
  },
};

export class DataBox extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.any,
    style: PropTypes.any,
    contentHeight: PropTypes.any,
  };

  render() {
    return (
      <div style={{
        ...styles.container, marginBottom: 10, height: this.props.contentHeight || null, ...(this.props.style || {}),
      }}>
        <Toolbar style={styles.toolbar}>
          <ToolbarGroup>
            <ToolbarTitle style={styles.toolbarTitle} text={this.props.title} />
          </ToolbarGroup>
        </Toolbar>
        <Paper style={styles.child} zDepth={1}>
          {this.props.children}
        </Paper>
      </div>
    );
  }
}
