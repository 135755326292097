import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DataTable } from '../../common/components/data-table.component';
import { FontAwesome } from '../../common/components/font-awesome';
import { FloatingActionButton } from 'material-ui';
import { AddEditCategoryModal } from './add-edit-category.container';
import { RemoveCategoryModal } from './remove-category.container';

const PER_PAGE = 5;

const styles = {
  actionIcon: {
    cursor: 'pointer',
    fontSize: 12,
    marginRight: 10,
  },
};

export class ManageCategoriesView extends Component {
  static propTypes = {
    height: PropTypes.number,
    categoriesData: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      error: PropTypes.any,
      categories: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        icon: PropTypes.string,
        id: PropTypes.string,
      })),
    }).isRequired,
  };

  constructor() {
    super();

    this.state = {
      skip: 0,
      modalEntity: null,
      removeEntity: null,
    };
  }

  renderCategoryIcon(category) {
    if (category.icon) {
      return <img alt={category.name} style={{ maxWidth: 30 }} src={category.icon} />;
    }

    return null;
  }

  editCategory(category) {
    this.setState({ modalEntity: category });
  }

  removeCategory(category) {
    this.setState({ removeEntity: category });
  }

  createNew() {
    this.setState({ modalEntity: {} });
  }

  render() {
    const total = this.props.categoriesData.categories ? this.props.categoriesData.categories.length : 0;
    const data = this.props.categoriesData.categories ? this.props.categoriesData.categories : [];

    console.log("data", data)

    const tableDesc = [
      { headerName: '', width: 10, render: item => this.renderCategoryIcon(item) },
      { headerName: 'Manage Feed Categories', width: 70, valueFrom: 'name' },
      {
        headerName: 'Actions',
        width: 20,
        rowStyle: {
          overflow: 'visible',
        },
        render: item => (
          <div>
            <FontAwesome
              style={styles.actionIcon}
              onClick={() => this.editCategory(item)}
              tooltip="Edit"
              name="pencil-square-o" />
            <FontAwesome
              style={styles.actionIcon}
              onClick={() => this.removeCategory(item)}
              tooltip="Remove"
              name="trash" />
          </div>
        ),
      },
    ];

    return (
      <div style={{
        marginTop: 20, overflow: 'scroll', display: 'flex', flexDirection: 'row', height: this.props.height || null,
      }}>
        <AddEditCategoryModal
          category={this.state.modalEntity}
          onRequestClose={() => this.setState({ modalEntity: null })}
          isOpen={this.state.modalEntity !== null} />
        <RemoveCategoryModal
          onRequestClose={() => this.setState({ removeEntity: null })}
          categories={this.props.categoriesData.categories || []}
          removeEntity={this.state.removeEntity} />
        <DataTable
          customHeaderOverlay={
            <FloatingActionButton mini={true} onClick={() => this.createNew()}>
              <FontAwesome name="plus" />
            </FloatingActionButton>
          }
          description={tableDesc}
          data={data.slice(this.state.skip, this.state.skip + PER_PAGE)}
          idField="id"
          onNext={() => this.setState({ skip: this.state.skip + PER_PAGE })}
          onPrev={() => this.setState({ skip: this.state.skip - PER_PAGE })}
          limit={PER_PAGE}
          skip={this.state.skip}
          total={total}
          loading={this.props.categoriesData.loading} />
      </div>
    );
  }
}
