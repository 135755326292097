import { graphql, compose } from 'react-apollo';
import { SettingsView } from './settings.component';
import { settingsQuery } from '../../students/graphql/settings.query';
import { updateSettingsMutation } from '../../students/graphql/update-settings.mutation';

export const withUpdateSettings = graphql(updateSettingsMutation, {
  props: ({ mutate }) => ({
    updateSettings: changes => mutate({
      variables: { changes },
      updateQueries: {
        settings: (prev, { mutationResult: { data: { updateSettings } } }) => ({
          settings: updateSettings,
        }),
      },
    }),
  }),
});

export const withSettings = graphql(settingsQuery, { name: 'settings' });

console.log("withsettings", withSettings)

export const Settings = compose(withSettings, withUpdateSettings)(SettingsView);
