import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DataBox } from '../../common/components/data-box.component';
import { TargetPickerForm } from '../../feed/components/target-picker-form.container';
import {
  FlatButton,
  List,
  ListItem,
  RaisedButton,
  TextField,
  Checkbox
} from 'material-ui';
import { Loading } from '../../common/components/loading.component';

export class PublishNotificationView extends Component {
  static propTypes = {
    publish: PropTypes.func.isRequired
  };

  constructor() {
    super();

    this.state = {
      loading: false,
      groups: [],
      title: '',
      content: '',
      isSms: false,
      review: false,
      count: 0,
      done: false
    };
  }

  getTextCount() {
    if (this.state.groups.includes('all')) {
      return 'all students';
    }

    const totalUsers = this.state.groups.filter(g => g.startsWith('user_'))
      .length;
    const totalCourses = this.state.groups.filter(g => g.startsWith('course_'))
      .length;
    const result = [];

    if (totalUsers > 0) {
      result.push(`${totalUsers} user(s)`);
    }

    if (totalCourses > 0) {
      result.push(`${totalCourses} course(s)`);
    }

    return result.join(' and ');
  }

  updateGroups(groups) {
    this.setState({
      groups: groups.filter(g => g !== 'users')
    });
  }

  sendMessage() {
    this.setState({ loading: true }, () => {
      this.props
        .publish(
          this.state.groups,
          this.state.title,
          this.state.content,
          this.state.isSms
        )
        .then(result => {
          this.setState({
            done: true,
            loading: false,
            count: result.data.publishNotification
          });
        })
        .catch(e => console.log(e));
    });
  }

  render() {
    return (
      <div style={styles.container}>
        <DataBox title='Publish App Notification'>
          {this.state.loading ? (
            <Loading />
          ) : this.state.done ? (
            <div style={{ textAlign: 'center' }}>
              <h1>Done!</h1>
              <h3>
                Your notification has been sent to {this.state.count}{' '}
                participant(s)!
              </h3>
            </div>
          ) : this.state.review ? (
            <div style={styles.reviewParent}>
              <h2>Are you sure?</h2>
              <h3>Send message to {this.getTextCount()}?</h3>
              <div id='iphone'>
                <div
                  id='ios-push-container'
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/ios.png)`
                  }}
                >
                  <p id='ios-push-title'>MOUNT ROAR</p>
                  <p id='ios-push-body'>
                    <span style={{ fontWeight: 'bold' }}>
                      {this.state.title}
                    </span>
                    <br />
                    {this.state.content}
                  </p>
                </div>
              </div>
              <div>
                <RaisedButton
                  primary={true}
                  onClick={() => this.sendMessage()}
                  label='Yes, send this message'
                />
                <FlatButton
                  style={{ marginLeft: 10 }}
                  onClick={() => {
                    this.setState({ review: false });
                  }}
                  label='Cancel'
                />
              </div>
            </div>
          ) : (
            <div>
              <TargetPickerForm
                groups={this.state.groups}
                disableAllUsers={false}
                hideButtons={true}
                onChange={groups => this.updateGroups(groups)}
              />
              <List>
                <ListItem disabled={true}>
                  <TextField
                    value={this.state.title}
                    onChange={(event, newValue) =>
                      this.setState({ title: newValue })
                    }
                    multiLine={false}
                    fullWidth={true}
                    floatingLabelText='Notification Title'
                    floatingLabelFixed={true}
                    maxLength='40'
                    hintText='max 40 chars'
                  />
                </ListItem>
                <ListItem disabled={true}>
                  <TextField
                    value={this.state.content}
                    onChange={(event, newValue) =>
                      this.setState({ content: newValue })
                    }
                    multiLine={true}
                    rows={2}
                    fullWidth={true}
                    rowsMax={4}
                    floatingLabelFixed={true}
                    floatingLabelText='Notification text'
                  />
                </ListItem>
                <ListItem>
                  <Checkbox
                    label='is SMS?'
                    checked={this.state.isSms}
                    onCheck={(e, newState) =>
                      this.setState({ isSms: !this.state.isSms })
                    }
                  />
                </ListItem>
              </List>
              <RaisedButton
                disabled={
                  this.state.content === '' ||
                  this.state.title === '' ||
                  this.state.groups.length === 0
                }
                primary={true}
                onClick={() => this.setState({ review: true })}
                label='Review Notification'
              />
            </div>
          )}
        </DataBox>
      </div>
    );
  }
}

const styles = {
  container: {
    padding: '1%'
  }
};
