import gql from 'graphql-tag';

export const newsFeedPollFragment = gql`
  fragment PollFields on NewsFeedPollItem {
    id
    published
    categories {
      id
      icon
      name
    }
    mainCategory {
      id
      icon
      name
    }
    question
    totalVotes
    possibleAnswers {
      answer
      value
    }
    author {
      name
      profilePictureUrl
    }
    startTime
    endTime
    targets
    platforms
  }
`;
