export const ECoursesViewActions = {
  CHANGE_FILTER: 'courses/CHANGE_FILTER',
  CHANGE_STUDENTS_FILTER: 'courses/CHANGE_STUDENTS_FILTER',
  EDIT_ENTITY: 'courses/EDIT_ENTITY',
};

export const updateCoursesFilter = filterChanges => ({
  type: ECoursesViewActions.CHANGE_FILTER,
  ...filterChanges,
});

export const updateStudentsSearch = search => ({
  type: ECoursesViewActions.CHANGE_STUDENTS_FILTER,
  search,
});

export const editEntity = editableEntity => ({
  type: ECoursesViewActions.EDIT_ENTITY,
  open: !!editableEntity,
  entity: editableEntity,
});
