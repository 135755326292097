import { graphql } from 'react-apollo';
import { withEditEntity } from '../../feed/components/with-edit-feed-item.container';
import { CreateUpdatePollFormView } from './create-update-poll-form.component';
import { createUpdatePollMutation } from '../graphql/create-update-poll.mutation';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

const mapDispatch = dispatch => ({
  navigate: route => dispatch(push(route)),
});

const withState = connect(null, mapDispatch);

const withMutation = graphql(createUpdatePollMutation, {
  props: ({ ownProps, mutate }) => ({
    createUpdatePoll: (pollId, poll) => mutate({
      variables: {
        pollId,
        poll,
      },
    }),
  }),
});

export const CreateUpdatePollForm = withState(withMutation(withEditEntity(CreateUpdatePollFormView)));
