import { HelpdeskView } from './helpdesk.component';
import { graphql } from 'react-apollo';
import { helpdeskChatsQuery } from '../graphql/helpdesk-chats.query';
import { compose } from 'redux';
import { withMarkAsResolved } from './helpdesk-chat-screen.container';

const withChatsList = graphql(helpdeskChatsQuery, {
  name: 'chats',
  options: {
    fetchPolicy: 'network-only',
    pollInterval: 10 * 1000,
  },
});

export const Helpdesk = compose(withMarkAsResolved, withChatsList)(HelpdeskView);
