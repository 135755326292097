import gql from 'graphql-tag';
import { newsFeedEventFragment } from './news-feed-event-item.fragment';

export const createUpdateEventMutation = gql`
  mutation createUpdateEvent($eventId: String, $event: EventInput!) {
    createUpdateEvent(eventId: $eventId, event: $event) {
      ...EventFields
    }
  }

  ${newsFeedEventFragment}
`;
