import gql from 'graphql-tag';

export const videosQuery = gql`
  query videosViewsByUser($userId: String) {
    videosViewsByUser(userId: $userId) {
      views {
        _id
        userId
        videoItemID
        isCompleted
        updatedAt
        pauseMinute
        videoName
        surveyAnswer
      }
    }
  }
`;
