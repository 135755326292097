import React, { Component } from 'react';
import { GridList, GridTile } from 'material-ui/GridList';
import { DataBox } from '../../common/components/data-box.component';
import { ProfilePictureApprovals } from './profile-pic-approvals.container';
import { PhotoApprovals } from './photo-approvals.container';
import { LiveFeedApprovals } from './live-feed-approvals.container';
import { SnapchatApprovals } from './snapchat-approvals.container';

const CONTENT_HEIGHT = 400;

const styles = {
  gridList: {
    width: '100%',
  },
  gridContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    marginLeft: '3%',
    marginRight: '3%',
  },
};

export class Approvals extends Component {
  renderGrid() {
    return (
      <div style={styles.gridContainer}>
        <GridList cellHeight={CONTENT_HEIGHT} style={styles.gridList}>
          <GridTile rows={1} cols={1}>
            <DataBox title="Profile Picture Approvals">
              <ProfilePictureApprovals height={CONTENT_HEIGHT - 80} />
            </DataBox>
          </GridTile>
          <GridTile rows={1} cols={1}>
            <DataBox title="Photo Approvals">
              <PhotoApprovals height={CONTENT_HEIGHT - 80} />
            </DataBox>
          </GridTile>
          <GridTile rows={1} cols={1}>
            <DataBox title="Snapchat Approvals">
              <SnapchatApprovals height={CONTENT_HEIGHT - 80} />
            </DataBox>
          </GridTile>
          <GridTile rows={1} cols={1}>
            <DataBox title="Events Live Feed Approvals">
              <LiveFeedApprovals height={CONTENT_HEIGHT - 80} />
            </DataBox>
          </GridTile>
        </GridList>
      </div>
    );
  }

  render() {
    return <div>{this.renderGrid()}</div>;
  }
}
