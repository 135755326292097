import React, { Component } from 'react';
import Paper from 'material-ui/Paper';
import { Theme } from '../../theme/theme';
import { Toolbar, ToolbarGroup, ToolbarTitle } from 'material-ui/Toolbar';
import logo from '../../public/logo.png';
import { LoginForm } from './login-form.container';

const styles = {
  logo: {
    maxWidth: 100,
    marginBottom: 30
  },
  toolbar: {
    backgroundColor: Theme.SECONDARY_COLOR
  },
  parent: {
    textAlign: 'center',
    backgroundColor: Theme.PRIMARY_COLOR,
    width: '100%',
    height: '100%',
    paddingTop: 50
  },
  formContainer: {
    height: 330,
    width: 400,
    margin: '0 auto',
    textAlign: 'center',
    position: 'relative'
  }
};

export class LoginPage extends Component {
  render() {
    return (
      <div style={styles.parent}>
        <img style={styles.logo} src={logo} alt='Yocas' />
        <Paper style={styles.formContainer} zDepth={3}>
          <Toolbar style={styles.toolbar}>
            <ToolbarGroup>
              <ToolbarTitle text='Login to Yocas' />
            </ToolbarGroup>
          </Toolbar>
          <LoginForm />
        </Paper>
      </div>
    );
  }
}
