import { graphql } from 'react-apollo';
import { waitingProfilePictureApprovalsQuery } from '../../students/graphql/waiting-profile-pictures-approvals.query';
import { ProfilePictureApprovalsView } from './profile-pic-approvals.component';

const withData = graphql(waitingProfilePictureApprovalsQuery, {
  name: 'approvals',
  options: {
    pollInterval: 10000,
    fetchPolicy: 'network-only',
  },
});

export const ProfilePictureApprovals = withData(ProfilePictureApprovalsView);
