import gql from 'graphql-tag';

export const createUpdateCategoryMutation = gql`
  mutation createUpdateCategory($updateId: String, $category: CategoryInput!) {
    createUpdateCategory(category: $category, updateId: $updateId) {
      name
      id
      icon
    }
  }
`;
