import React, { Component } from 'react';
import { routerMiddleware, routerReducer } from 'react-router-redux';
import createHistory from 'history/createBrowserHistory';
import { ConnectedRouter, push } from 'react-router-redux';
import { Route } from 'react-router';
import { LoginPage } from './auth/components/login.view';
import { Main } from './main/components/main.container';
import config from './core/config/config';
import { store } from './store';
import { refreshSession } from './account/account.util';

export const history = createHistory({ basename: config.basePath });
export const middleware = routerMiddleware(history);
export const reducer = routerReducer;

export class AppRouter extends Component {
  state = {
    loading: true,
  };

  async componentDidMount() {
    let requestedPath = window.location.pathname;
    if (requestedPath === '/') {
      requestedPath = '/admin/dashboard';
    }

    try {
      const tokens = await refreshSession();
      if (!tokens) {
        requestedPath = '/login';
      }
    } catch (e) {
      requestedPath = '/login';
    }

    store.dispatch(push(requestedPath));

    this.setState({ loading: false });
  }

  render() {
    if (this.state.loading) {
      return null;
    }

    return (
      <ConnectedRouter history={history}>
        <div>
          <Route exact={true} path="/login" component={LoginPage} />
          <Route path="/admin/" component={Main} />
        </div>
      </ConnectedRouter>
    );
  }
}
