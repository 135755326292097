import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Subheader , Toggle} from 'material-ui';
import { Loading } from '../../common/components/loading.component';
import { StaticData } from './static-data.container';


const styles = {
  center: {
    textAlign: 'center',
  },
  button: {
    width: 200,
  },
  buttonsContainer: {
    paddingLeft: 20,
    paddingRight: 20,
    textAlign: 'center',
    width: '80%',
  },
};

export class SettingsView extends Component {
  static propTypes = {
    updateSettings: PropTypes.func.isRequired,
    settings: PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.any,
      settings: PropTypes.array,
    }),
    height: PropTypes.number,
  };

  constructor() {
    super();

    this.state = {
      helpdeskAutoResponse: null,
      isCheckedData : false,
      isCheckedUsers : false
    };
  }

  update(fieldName, fieldValue) {
    this.props.updateSettings([
      {
        name: fieldName,
        value: fieldValue,
      },
    ]);
  }


  render() {
    if (this.props.settings.loading) {
      return <Loading />;
    }

    const { settings } = this.props.settings;
   
    console.log("settings3", settings)
   
    const autoStulistLoad = settings.find(v => v.name === 'autoStulistLoad').value === 'true'
    const allowUsersToPost = settings.find(v => v.name === 'allowUsersToPost').value === 'true'
    this.isCheckedData = autoStulistLoad
    this.isCheckedUsers = allowUsersToPost

    return (
      <div style={{ ...styles.buttonsContainer, height: this.props.height || null }}>
        <Subheader>Static Data Import</Subheader>
        <Toggle
          onToggle={(e, newState) => this.update('autoStulistLoad', newState)}
          toggled={autoStulistLoad}
          label="Automatically update students data"
        />
        
        <StaticData />

        <Subheader>Users Permissions</Subheader>
          <Toggle
          onToggle={(e, newState) => this.update('allowUsersToPost', newState)}
          toggled={allowUsersToPost}
          label="Allow users to post"
        />
      </div>
    );
  }
}
