
import { graphql } from 'react-apollo';
import { allUsersStatisticsModal } from './allUsersStatistics.component';
import { allUsersStatisticsQuery } from './allUsersStatistics.query';

const withAllUsersStatisticsQuery = graphql(allUsersStatisticsQuery, {
  name: 'allUsersStatistics',
  options: (props) => {
    return {
      fetchPolicy: 'cache-and-network',
      variables: {
      }
    };
  }
});

export const AllUsersStatisticsForm = withAllUsersStatisticsQuery(
    allUsersStatisticsModal 
) 
