import { graphql } from 'react-apollo/index';
import { sendHelpdeskBulkMessageMutation } from '../graphql/send-bulk-message.mutation';
import { HelpdeskNewChatView } from './helpdeks-new-chat.component';

const withSendMessageMutation = graphql(sendHelpdeskBulkMessageMutation, {
  props: ({ ownProps, mutate }) => ({
    sendMessage: (messageType, content, groups) => mutate({
      variables: { messageType, content, groups },
    }),
  }),
});

export const HelpdeskNewChat = withSendMessageMutation(HelpdeskNewChatView);
