const config = {
  basePath: '/',
  server: {
    protocol: 'https', //https //http
    host: 'Be.YOCAS.life', // Be.YOCAS.life // localhost
    port: 5000,
    path: '/graphql',
    profileImagesUploadPath: '/upload/profileImages'
  },
  cleanStorage: false
};

export default config;
