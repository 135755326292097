import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField';
import Subheader from 'material-ui/Subheader';
import { List, ListItem } from 'material-ui/List';
import { PdfButtonPicker } from '../../common/components/pdf-button-picker.component';
import { RaisedButton } from 'material-ui';
import { Theme } from '../../theme/theme';
import { Loading } from '../../common/components/loading.component';
import { FontAwesome } from '../../common/components/font-awesome';
import { ProfileAvatar } from '../../common/components/profile-avatar.component';

const styles = {
  header: {
    lineHeight: 'initial',
    paddingTop: 10
  },
  miniListItem: {
    paddingTop: 0,
    paddingBottom: 0
  },
  identifierField: {
    width: 230,
    marginLeft: 15
  },
  lecturerField: {
    width: 100,
    marginRight: 10
  },
  avatar: {
    marginTop: 12
  },
  headerNote: {
    color: Theme.ACTIVE2_COLOR
  }
};

export class CreateEditManualFormView extends Component {
  static propTypes = {
    manual: PropTypes.any.isRequired,
    disabled: PropTypes.bool,
    createManual: PropTypes.func.isRequired
  };

  constructor() {
    super();

    this.state = {
      isLoading: false,
      isDone: false,
      error: null,
      name: '',
      label: '',
      data: ''
    };
  }

  componentDidMount() {
    const { manual } = this.props;

    if (Object.keys(manual).length > 0) {
      this.setState({
        name: manual.name,
        label: manual.label,
        data: manual.data ? manual.data : ''
      });
    }
  }

  onFileLoad(data) {
    this.setState({
      data: data
    });
  }

  onSubmit() {
    this.setState({
      isLoading: true,
      error: null
    });

    this.props
      .createManual({
        existingId: this.props.manual.id,
        name: this.state.name,
        label: this.state.label,

        data:
          this.state.data && this.state.data.startsWith('data:application/pdf')
            ? this.state.data
            : null
      })
      .then(() => {
        this.setState({
          isDone: true,
          isLoading: false
        });
      })
      .catch(err => {
        this.setState({
          error: err,
          isLoading: false
        });
      });
  }

  isFormValid() {
    return this.state.name && this.state.label.length > 2 && this.state.data;
  }

  render() {
    if (this.state.isLoading) {
      return <Loading />;
    }

    if (this.state.isDone) {
      return (
        <div style={{ textAlign: 'center', marginTop: 20 }}>
          <FontAwesome name='check' />
          <br />
          <br />
          Done!
        </div>
      );
    }

    return (
      <div>
        <List>
          {this.state.error ? (
            <Subheader style={styles.headerNote}>
              {this.state.error.toString()}
            </Subheader>
          ) : null}
          <Subheader style={styles.header}>Manual Information</Subheader>
          <ListItem innerDivStyle={styles.miniListItem} disabled={true}>
            <TextField
              disabled={this.props.disabled}
              floatingLabelText='Manual Name'
              fullWidth={true}
              value={this.state.name}
              onChange={(event, newValue) => this.setState({ name: newValue })}
            />
          </ListItem>
          <ListItem innerDivStyle={styles.miniListItem} disabled={true}>
            <TextField
              style={styles.identifierField}
              disabled={this.props.disabled}
              floatingLabelText='Manual Label'
              fullWidth={true}
              value={this.state.label}
              onChange={(event, newValue) => this.setState({ label: newValue })}
            />
          </ListItem>

          <ListItem innerDivStyle={styles.miniListItem} disabled={true}>
            {this.props.disabled ? null : (
              <PdfButtonPicker
                disabled={this.props.disabled}
                label='Select PDF file'
                onFile={data => this.onFileLoad(data)}
              />
            )}
          </ListItem>
          {this.props.disabled ? null : (
            <ListItem disabled={true}>
              <RaisedButton
                onClick={() => this.onSubmit()}
                disabled={this.props.disabled || !this.isFormValid()}
                primary={true}
                label='SAVE'
                fullWidth={true}
              />
            </ListItem>
          )}
        </List>
      </div>
    );
  }
}
