import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Loading } from '../../common/components/loading.component';
import { DataBox } from '../../common/components/data-box.component';
import { MessagesList } from './messages-list.component';
import { SendMessageInput } from './send-message-input.component';
import { debounce } from 'lodash';
import { push } from 'react-router-redux';
import { store } from '../../store';
import { RaisedButton } from 'material-ui';
import { Link } from 'react-router-dom';

export class HelpdeskChatView extends Component {
  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
      params: PropTypes.object.isRequired,
    }).isRequired,
    chatData: PropTypes.shape({
      error: PropTypes.any,
      loading: PropTypes.bool,
      helpdeskChat: PropTypes.object,
    }),
    sendMessage: PropTypes.func.isRequired,
    subscribeToMore: PropTypes.func.isRequired,
    loadOlder: PropTypes.func.isRequired,
    markAsResolved: PropTypes.func.isRequired,
  };

  constructor() {
    super();

    this._subscriptionHandle = null;
    this.loadOlder = debounce(this.loadOlder.bind(this), 500);
  }

  sendMessage(messageType, content) {
    this.props.sendMessage(messageType, content, this.props.match.params.id);
  }

  getMessagesObj() {
    let messages = { messages: [], hasMore: false };

    try {
      messages = this.props.chatData.helpdeskChat.messages;
    } catch (e) {
      // nothing...
    }

    return messages;
  }

  notifyReadStatus(chatId, messageId) {
    this.props.notifyReadStatus(chatId, messageId).catch(() => null);
  }

  loadOlder() {
    const messagesObj = this.getMessagesObj();
    const messagesArr = messagesObj.messages;
    const firstMessage = messagesArr[0] || null;

    if (firstMessage && firstMessage.id) {
      this.props.loadOlder(firstMessage.id);
    }
  }

  componentDidUpdate() {
    const messagesObj = this.getMessagesObj();
    const messagesArr = messagesObj.messages;
    const lastMessageRead = messagesArr.length > 0 ? messagesArr[messagesArr.length - 1] : null;

    if (lastMessageRead && lastMessageRead.id) {
      const handle = this.props.subscribeToMore(lastMessageRead.id);

      if (handle) {
        this._subscriptionHandle = handle;
      }
    }
  }

  componentWillUnmount() {
    if (this._subscriptionHandle) {
      this._subscriptionHandle();
      this._subscriptionHandle = null;
    }
  }

  async markAsResolved() {
    this.props.markAsResolved().then(() => {
      store.dispatch(push('/admin/helpdesk'));
    });
  }

  render() {
    if (this.props.chatData.loading) {
      return (<Loading />);
    }

    const messagesObj = this.getMessagesObj();
    const messagesArr = messagesObj.messages;
    const hasMore = messagesObj.hasMore;
    const lastMessageRead = messagesArr.length > 0 ? messagesArr[messagesArr.length - 1] : null;

    if (lastMessageRead && lastMessageRead.id && !lastMessageRead.id.includes('temp_')) {
      if (lastMessageRead && lastMessageRead.author && lastMessageRead.author.id) {
        this.notifyReadStatus(this.props.chatData.helpdeskChat.id, lastMessageRead.id);
      }
    }

    return (
      <div style={{ marginLeft: '3%', marginRight: '3%', height: '100%' }}>
        <Link to={{ pathname: '/admin/helpdesk', query: { reload: true } }} style={{ color: 'black' }}>
          back to chats list
        </Link>
        <DataBox
          style={{ height: '90%', display: 'flex', flexDirection: 'column' }}
          title={`Chat with ${this.props.chatData.helpdeskChat.users[0].name}`}>
          <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <MessagesList onLoadOlder={() => this.loadOlder()} hasMore={hasMore} messages={messagesArr} />
            <SendMessageInput onSendMessage={(messageType, content) => this.sendMessage(messageType, content)} />
            <RaisedButton
              onClick={() => this.markAsResolved()}
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
                fontStyle: 'italic',
                textAlign: 'center',
                fontSize: 12,
                fontWeight: 'bold',
              }}>
              MARK AS RESOLVED
            </RaisedButton>
          </div>
        </DataBox>
      </div>
    );
  }
}

