import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField';
import Subheader from 'material-ui/Subheader';
import { List, ListItem } from 'material-ui/List';
import { ImageButtonPicker } from '../../common/components/image-button-picker.component';
import { RaisedButton } from 'material-ui';
import { Theme } from '../../theme/theme';
import { Loading } from '../../common/components/loading.component';
import { FontAwesome } from '../../common/components/font-awesome';
import { ProfileAvatar } from '../../common/components/profile-avatar.component';
import { CourseIcon } from '../../common/components/course-icon.component';

const styles = {
  header: {
    lineHeight: 'initial',
    paddingTop: 10
  },
  miniListItem: {
    paddingTop: 0,
    paddingBottom: 0
  },
  identifierField: {
    width: 230,
    marginLeft: 15
  },
  lecturerField: {
    width: 100,
    marginRight: 10
  },
  avatar: {
    marginTop: 12
  },
  headerNote: {
    color: Theme.ACTIVE2_COLOR
  }
};

export class CreateEditCourseFormView extends Component {
  static propTypes = {
    course: PropTypes.any.isRequired,
    disabled: PropTypes.bool,
    createManualCourse: PropTypes.func.isRequired
  };

  constructor() {
    super();

    this.state = {
      isLoading: false,
      isDone: false,
      error: null,
      courseName: '',
      identifier: '',
      lecturerFirstName: '',
      lecturerLastName: '',
      lecturerImage: null
    };
  }

  componentDidMount() {
    const { course } = this.props;

    if (Object.keys(course).length > 0) {
      this.setState({
        courseName: course.fullName,
        identifier: course.identifier,
        lecturerFirstName: course.teacher ? course.teacher.firstName : '',
        lecturerLastName: course.teacher ? course.teacher.lastName : '',
        lecturerImage: course.teacher ? course.teacher.profilePictureUrl : null
      });
    }
  }

  onFileLoad(data) {
    this.setState({
      lecturerImage: data
    });
  }

  onSubmit() {
    this.setState({
      isLoading: true,
      error: null
    });

    this.props
      .createManualCourse({
        existingId: this.props.course.id,
        courseName: this.state.courseName,
        identifier: this.state.identifier,
        lecturerFirstName: this.state.lecturerFirstName,
        lecturerLastName: this.state.lecturerLastName,
        lecturerImage:
          this.state.lecturerImage &&
          this.state.lecturerImage.startsWith('data:image/')
            ? this.state.lecturerImage
            : null
      })
      .then(() => {
        this.setState({
          isDone: true,
          isLoading: false
        });
      })
      .catch(err => {
        this.setState({
          error: err,
          isLoading: false
        });
      });
  }

  isFormValid() {
    return (
      this.state.courseName &&
      this.state.courseName.length > 2 &&
      this.state.identifier &&
      this.state.identifier.length > 2
    );
  }

  render() {
    if (this.state.isLoading) {
      return <Loading />;
    }

    if (this.state.isDone) {
      return (
        <div style={{ textAlign: 'center', marginTop: 20 }}>
          <FontAwesome name='check' />
          <br />
          <br />
          Done!
        </div>
      );
    }

    return (
      <div>
        <List>
          {this.state.error ? (
            <Subheader style={styles.headerNote}>
              {this.state.error.toString()}
            </Subheader>
          ) : null}
          <Subheader style={styles.header}>Course Information</Subheader>
          <ListItem innerDivStyle={styles.miniListItem} disabled={true}>
            <TextField
              disabled={this.props.disabled}
              floatingLabelText='Group Name'
              fullWidth={true}
              value={this.state.courseName}
              onChange={(event, newValue) =>
                this.setState({ courseName: newValue })
              }
            />
          </ListItem>
          <ListItem innerDivStyle={styles.miniListItem} disabled={true}>
            <CourseIcon
              style={{ maxWidth: '20px' }}
              active={false}
              courseIdentifier={this.state.identifier}
            />
            <TextField
              style={styles.identifierField}
              disabled={this.props.disabled}
              floatingLabelText='Group Identifier'
              fullWidth={true}
              value={this.state.identifier}
              onChange={(event, newValue) =>
                this.setState({ identifier: newValue })
              }
            />
          </ListItem>
          <Subheader style={styles.header}>Instructor</Subheader>
          <ListItem
            innerDivStyle={styles.miniListItem}
            disabled={true}
            leftAvatar={
              <ProfileAvatar
                style={styles.avatar}
                src={this.state.lecturerImage}
              />
            }
          >
            <TextField
              disabled={this.props.disabled}
              style={styles.lecturerField}
              floatingLabelText='First Name'
              value={this.state.lecturerFirstName}
              onChange={(event, newValue) =>
                this.setState({ lecturerFirstName: newValue })
              }
            />
            <TextField
              disabled={this.props.disabled}
              style={styles.lecturerField}
              floatingLabelText='Last Name'
              value={this.state.lecturerLastName}
              onChange={(event, newValue) =>
                this.setState({ lecturerLastName: newValue })
              }
            />
            {this.props.disabled ? null : (
              <ImageButtonPicker
                disabled={this.props.disabled}
                label='Select Instructor Image'
                onFile={data => this.onFileLoad(data)}
              />
            )}
          </ListItem>
          {this.props.disabled ? null : (
            <ListItem disabled={true}>
              <RaisedButton
                onClick={() => this.onSubmit()}
                disabled={this.props.disabled || !this.isFormValid()}
                primary={true}
                label='SAVE'
                fullWidth={true}
              />
            </ListItem>
          )}
        </List>
      </div>
    );
  }
}
