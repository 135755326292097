import gql from 'graphql-tag';

export const notifyChatReadStatusMutation = gql`
    mutation notifyChatReadStatus($chatId: String!, $messageId: String!){
        notifyChatReadStatusHelpdesk(chatId: $chatId, messageId: $messageId){
            chat {
                id
                unreadMessagesCount
            }
        }
    }
`;
