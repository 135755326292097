import gql from 'graphql-tag';

export const updateSettingsMutation = gql`
  mutation updateSettings($changes: [ServerSettingInput!]!) {
    updateSettings(changes: $changes) {
      name
      value
    }
  }
`;
