import React, { Component } from 'react';
import { GridList, GridTile } from 'material-ui';
import { DataBox } from '../../common/components/data-box.component';
import { Analytics } from '../../analytics/components/analytics.container';
import { AllUsersStatisticsForm } from './allUsersStatistics.container';

const CONTENT_HEIGHT = 650;

const styles = {
  gridList: {
    width: '100%',
  },
  gridContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    marginLeft: '3%',
    marginRight: '3%',
    overflow: 'scroll',
    height: '100vh'
  },
};


export class DashboardView extends Component {
  getAnalyticsParams() {
    return [
      {
        category: 'App', action: 'AppVisit', identifier: null, name: 'App Uses',
      },
      {
        category: 'App', action: 'AppVisit', identifier: null, name: 'App Uses (Unique)', flags: { unique: true },
      },
      {
        category: 'Auth', action: 'Login', identifier: null, name: 'User Logins',
      },
      {
        category: 'Feed', action: 'EventView', identifier: null, name: 'Event Views',
      },
      {
        category: 'Feed', action: 'EventView', identifier: null, name: 'Event Views (Unique)', flags: { unique: true },
      },
      {
        category: 'Feed', action: 'AnswerPoll', identifier: null, name: 'Total Votes',
      },
      {
        category: 'Feed', action: 'AnswerPoll', identifier: null, name: 'Total Votes (Unique)', flags: { unique: true },
      },
      {
        category: 'Feed', action: 'NewsReadMore', identifier: null, name: 'News Items clicks',
      },
      {
        category: 'Feed', action: 'NewsReadMore', identifier: null, name: 'News Items clicks (Unique)', flags: { unique: true },
      },
    ];
  }

  render() {
    return (
      <div style={styles.gridContainer}>
        <GridList cellHeight={CONTENT_HEIGHT} style={styles.gridList}>
          <GridTile rows={2} cols={2}>
            <DataBox title="App Use Analytics">
              <Analytics params={this.getAnalyticsParams()} />
            </DataBox>
            <DataBox title="All Users Statistics">
              <AllUsersStatisticsForm />
            </DataBox>
          </GridTile>
        </GridList>
      </div>
    );
  }
}
