import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RaisedButton, ListItem, List, Subheader, Checkbox } from 'material-ui';
import { PLATFORMS } from '../../common/wizard-platforms';
import { Loading } from '../../common/components/loading.component';

export class PublicationPlatformPickerFormView extends Component {
  static propTypes = {
    editEntityId: PropTypes.string,
    editEntity: PropTypes.object,
    navigate: PropTypes.func.isRequired,
    publish: PropTypes.func.isRequired,
  };

  constructor() {
    super();

    this.state = {
      platforms: ['NEWS_FEED'],
      done: false,
      loading: false,
      result: 0,
    };
  }

  componentWillMount() {
    const { editEntity } = this.props

    if (editEntity && editEntity.platforms) {
      this.setState({
        platforms: this.props.editEntity.platforms,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { editEntity } = nextProps

    if (editEntity && editEntity.platforms) {
      this.setState({
        platforms: nextProps.editEntity.platforms,
      });
    }
  }

  handlePlatformChange(pId, isChecked) {
    if (isChecked) {
      this.setState({
        platforms: [...this.state.platforms, pId],
      });
    } else {
      this.setState({
        platforms: this.state.platforms.filter(id => id !== pId),
      });
    }
  }

  publish() {
    this.props.publish(this.props.editEntityId, this.state.platforms)
      .then(({ data }) => {
        this.setState({ done: true, result: data.publishToFeed });
      });
  }

  skip() {
    const entityType = this.props.currentLocation.split('/')[2];
    this.props.navigate(`/admin/${entityType}`);
  }

  render() {
    if (this.state.loading) {
      return (<Loading />);
    }

    if (this.state.done) {
      return (
        <div style={{ width: '100%', textAlign: 'center' }}>
          The feed item was successfully published !
          <br />
          <RaisedButton
            onClick={() => this.skip()}
            style={{ marginTop: 30 }}
            label="Back to list" />
        </div>
      );
    }

    return (
      <div>
        <span style={{ paddingLeft: 15 }}><strong>Note: </strong> Re-publishing feed item will mark it as "NEW" in the mobile app.</span>
        <br />
        <List>
          <Subheader>Pick relevant platforms:</Subheader>
          {
            PLATFORMS.map(platform => (
              <ListItem
                key={platform.identifier}
                leftCheckbox={
                  <Checkbox
                    onCheck={(e, checked) => this.handlePlatformChange(platform.identifier, checked)}
                    checked={this.state.platforms && this.state.platforms.includes(platform.identifier)} />
                }
                primaryText={platform.name}
              />
            ))
          }
          <ListItem disabled={true}>
            <RaisedButton
              onClick={() => this.skip()}
              style={{ marginRight: 30 }}
              label="Skip" />
            <RaisedButton
              onClick={() => this.publish()}
              disabled={!this.state.platforms.length}
              primary
              label="Continue" />
          </ListItem>
        </List>
      </div>
    );
  }
}
