import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FlatButton } from 'material-ui';

export class ImageButtonPicker extends Component {
  static propTypes = {
    label: PropTypes.any,
    onFile: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
  };

  onInputChange(e) {
    Array.from(e.target.files).forEach(file => {
      const reader = new FileReader();
      reader.onload = r => {
        this.props.onFile(r.target.result);
      };
      reader.readAsDataURL(file);
    });
  }

  componentDidMount() {
    if (this.input) {
      this.input.addEventListener(
        'change',
        this.onInputChange.bind(this),
        false,
      );
    }
  }

  componentWillUnmount() {
    if (this.input) {
      this.input.removeEventListener(
        'change',
        this.onInputChange.bind(this),
        false,
      );
    }
  }

  render() {
    return (
      <FlatButton
        fullWidth={this.props.fullWidth}
        disabled={this.props.disabled}
        style={{ marginTop: 10, marginBottom: 10 }}
        containerElement="label"
        label={this.props.label}>
        <input accept="image/x-png,image/gif,image/jpeg" type="file" ref={ref => this.input = ref} />
      </FlatButton>
    );
  }
}
