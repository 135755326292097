import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { reducer, middleware } from './router';
import { courses } from './courses/courses.reducer';
import { manuals } from './manuals/manuals.reducer';
import { videos } from './videos/videos.reducer';
import { feed } from './feed/feed.reducer';
import { students } from './students/students.reducer';

const logger = createLogger();

const enhancers = compose(
  applyMiddleware(middleware),
  applyMiddleware(thunk, logger)
);

export const store = createStore(
  combineReducers({
    router: reducer,
    courses,
    feed,
    students,
    manuals,
    videos
  }),
  enhancers
);
