import { graphql } from 'react-apollo';
import { SnapchatApprovalsView } from './snapchat-approvals.component';
import { waitingSnapchatApprovalsQuery } from '../../students/graphql/waiting-snapchat-approvals.query';

const withData = graphql(waitingSnapchatApprovalsQuery, {
  name: 'approvals',
  options: {
    pollInterval: 10000,
    fetchPolicy: 'network-only',
  },
});

export const SnapchatApprovals = withData(SnapchatApprovalsView);
