import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, FlatButton } from 'material-ui';
import { ProfileAvatar } from '../../common/components/profile-avatar.component';
import { DataTable } from '../../common/components/data-table.component';
import { CourseIcon } from '../../common/components/course-icon.component';
import { GridList, GridTile } from 'material-ui';
import { DataBox } from '../../common/components/data-box.component';
import { Theme } from '../../theme/theme';
import { Modal } from 'reactstrap';
import PieChart from './pieChart.component';
import MultiLineChart from './lineChart.component';
import { Loading } from '../../common/components/loading.component';


const CONTENT_HEIGHT = 650;

const styles = {
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  infoSpan: {
    marginRight: 10,
    fontWeight: 'bold'
  },
  details: {
    marginLeft: 20
  },
  tableContainer: {
    marginTop: 10
  },
  infoText: {
    userSelect: 'text'
  },
  gridList: {
    width: '100%',
  },
  gridContainer: {
    display: 'flex',
    // flexWrap: 'wrap',
    justifyContent: 'space-around',
    marginLeft: '3%',
    marginRight: '3%',
  },
};

interface ModalProps {
  isOpen: boolean;
  student?: any;  // Consider specifying a more precise type if possible
  onRequestClose: () => void;
  userStatistics: any;
}

interface ModalState {
  params: any[]; 
  data: any;
  watchPieData: any;
  loading: boolean;
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];


export class StudentStatisticsModalView extends Component<ModalProps, ModalState>{
    static propTypes = {
      isOpen: PropTypes.bool.isRequired,
      student: PropTypes.any,
      onRequestClose: PropTypes.func.isRequired,
      userStatistics: PropTypes.any,
    }

    constructor(props: ModalProps) {
      super(props);
  
      this.state = {
        params: [],
        data: null,
        watchPieData: null,
        loading: true,
      }
    }

    componentWillReceiveProps = (newProps: any) => {
      console.log("new props =>", newProps)
      this.setState({ loading: true })
      if(newProps.userStatistics.userStatistics !== null && newProps.userStatistics.userStatistics !== undefined 
        && this.props.userStatistics.userStatistics !== null && this.props.userStatistics.userStatistics !== undefined
        && JSON.stringify(newProps.userStatistics.userStatistics) !== JSON.stringify(this.props.userStatistics.userStatistics)) {
          this.setState({ loading: false})
          this.buildArray(newProps.userStatistics.userStatistics)
      }
    }
   

    buildArray = (data: any) => {
      this.setState({ loading: true });
      console.log("data arrived!", data)
      let params = [
        { name: "User Logins", value: data.loginCount },
        { name: "Yogi Clickes", value: data.yogiClicks },
        { name: "User Sympthoms", value: data.allChatsSympthoms.length },
        { name: "User Reactions", value: data.userLikesCount },
        { name: "User Comments", value: data. userCommentCount },
        { name: "Chat Use", value: data.usingChats ? "Yes": "No"},
        { name: "Yogi Use", value: data.yogiClicks > 0 ? "Yes": "No"},
        { name: "Friends Use", value: data.usingGroups ? "Yes": "No"},
        { name: "Likes Use", value: data.usingLikes ? "Yes": "No"},
        { name: "Live Streaming Use", value: data.usingLiveStreaming ? "Yes": "No"},
        { name: "Modifications Use", value: data.usingModifications ? "Yes": "No"},
        { name: "Poses Use", value: data.usingPoses ? "Yes": "No"},
        { name: "Sessions Use", value: data.usingSessions ? "Yes": "No"},
        { name: "Upload Post Use", value: data.usingPost ? "Yes": "No"},
        { name: "Yoga Science Use", value: data.usingYogaScience ? "Yes": "No"},
        { name: "Videos Modification Use", value: data.usingYogaModification? "Yes": "No"},
        { name: "Videos Poses Use", value: data.usingYogaPoses? "Yes": "No"},
        { name: "Videos Sessions Use", value: data.usingYogaSessions? "Yes": "No"},
      ]

      let watchPieData = [
        { name: 'Watch Now', value: data.watchNow },
        { name: 'Watch Later', value: data.watchLater },
      ]

      this.setState({ watchPieData, data, params, loading: false })
     
    }

  

    render() {
     
      console.log("props inside render stat =>", this.props)
      if(this.props.userStatistics.userStatistics !== null && this.props.userStatistics.userStatistics !== undefined && this.state.params.length === 0 ) {
        this.buildArray(this.props.userStatistics.userStatistics)
      }
    

        const actions = [
          <FlatButton
            label='Close'
            onClick={() => this.props.onRequestClose()}
            onTouchTap={() => this.props.onRequestClose()}
          />
        ];

        return (
           <Dialog
            autoDetectWindowHeight={false}
            title={`User Statistics`}
            actions={actions}
            modal={false}
            open={this.props.isOpen}
            onRequestClose={() => this.props.onRequestClose()}
           >
           {this.state.loading && <Loading/> }
           {
             this.state.loading === false &&
      
              <div style={{...styles.gridContainer,  flexDirection: 'column', overflow: 'scroll', height: '40vh'}}>
                <GridList cellHeight={CONTENT_HEIGHT} style={styles.gridList}>
                  <GridTile rows={1} cols={2}>
                    <div style={{ flexDirection: 'row', display: 'flex', flexWrap: 'wrap' }}>
                    {
                      this.state.params.map((resultData, index) => (
                        <div
                          key={index}
                          style={{
                            paddingBottom: 40, width: '33%', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column',
                          }}>
                          <div style={{
                            textAlign: 'center', fontSize: 14, fontWeight: 'bold', color: Theme.PRIMARY_COLOR,
                          }}>{resultData.name}</div>
                          <div style={{
                            textAlign: 'center', fontSize: 34, paddingTop: 5, color: Theme.SECONDARY_TEXT_COLOR,
                          }}>{resultData.value}</div>
                        </div>
                      ))
                    }
                  
                     
                    </div>
                  </GridTile>
                </GridList>
                <div style={{ display: 'flex'}}>
                {
                    this.state.data !== null &&
                    <PieChart 
                      firstValue={this.state.data.watchNow}
                      secondValue={this.state.data.watchLater}
                      firstLabel={"Watch Now"}
                      secondLabel={"Watch Later"}
                    />
                }
                {
                    this.state.data !== null &&
                    <PieChart 
                      firstValue={this.state.data.postsWithMedia}
                      secondValue={this.state.data.postsWithoutMedia}
                      firstLabel={"Posts with media"}
                      secondLabel={"Posts without media"}
                    />
                }
                </div>
                <div style={{ display: 'flex'}}>
                {
                    this.state.data !== null &&
                    <PieChart 
                      firstValue={this.state.data.finishedVideo}
                      secondValue={this.state.data.notFinishedVideo}
                      firstLabel={"Video finished"}
                      secondLabel={"Video not finished"}
                    />
                } 
                {
                  this.state.data !== null &&
                  <PieChart 
                    firstValue={this.state.data.usefulVideo}
                    secondValue={this.state.data.notUsefulVideo}
                    firstLabel={"Video Useful"}
                    secondLabel={"Video not useful"}
                  />
                }
                </div>
                {
                  this.state.data !== null &&
                  <MultiLineChart data={this.state.data.allChatsSympthoms} />
                }
                {
                   this.state.data !== null &&
                   <div>
                      <label style={{ fontWeight: 'bold'}}>Written materials:</label>
                     {
                       this.state.data.manualNames.map((manual: any) => (
                         <div>• {manual}</div>
                       ))
                     }
                   </div>
                }
              </div>
            }
           </Dialog>
        )
        
    }
}