import moment from 'moment';

export class TimezoneMiddleware {
  static getHeader() {
    return {
      'UTC-Offset': moment().utcOffset(),
    };
  }

  static get offset() {
    return moment().utcOffset();
  }
}
