import { graphql } from 'react-apollo';
import { CreateEditManualFormView } from './create-edit-manual-form.component';
import { createManualMutation } from '../graphql/create-new-manual.mutation';

const withMutation = graphql(createManualMutation, {
  options: {
    refetchQueries: ['allManuals', 'statistics']
  },
  props: ({ ownProps, mutate }) => ({
    createManual: manualData => mutate({ variables: manualData })
  })
});

export const CreateEditManualForm = withMutation(CreateEditManualFormView);
