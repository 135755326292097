import gql from 'graphql-tag';

export const waitingPhotoApprovalsQuery = gql`
  query waitingPhotoApprovalsApprovals {
    waitingPhotoApprovals {
      id
      createdAt
      imageUrl
      user {
        id
        name
      }
      label
    }
  }
`;
