import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RaisedButton from 'material-ui/RaisedButton';
import { Loading } from '../../common/components/loading.component';

const styles = {
  button: {
    marginTop: 10,
    marginBottom: 30,
  },
};

export class StaticDataView extends Component {
  static propTypes = {
    loadStaticData: PropTypes.func.isRequired,
    height: PropTypes.number,
  };

  constructor() {
    super();

    this.state = {
      isLoading: false,
      error: '',
      results: null,
      verify: false,
    };
  }

  loadStaticData() {
    if (!this.state.verify) {
      this.setState({
        verify: true,
      });

      return;
    }

    this.setState({
      isLoading: true,
    });

    this.props.loadStaticData().then(() => {
      this.setState({
        isLoading: false,
        verify: false,
      });
    });
  }

  render() {
    if (this.state.isLoading) {
      return <Loading small={true} />;
    }

    return (
      <RaisedButton
        onClick={() => this.loadStaticData()}
        style={styles.button}
        label={this.state.verify ? 'Are you sure? Press again to execute' : 'Reload stulist.txt, icalfeed.txt and images.txt'} />
    );
  }
}
