import gql from 'graphql-tag';

export const helpdeskChatsQuery = gql`
    query helpdeskChats {
        helpdeskChats {
            id
            unreadMessagesCount
            createdAt(momentFormat: "lll")
            lastMessage {
                createdAt(momentFormat: "lll")
                content
                messageType
            }
            lastUpdate
            users(withMe: false) {
                id
                username
                name
                profilePictureUrl
            }
            isHidden
        }
    }
`;
