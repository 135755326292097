import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RaisedButton, Subheader, TextField } from 'material-ui';
import { Loading } from '../../common/components/loading.component';

const styles = {
  center: {
    textAlign: 'center',
  },
  button: {
    width: 200,
  },
  buttonsContainer: {
    paddingLeft: 20,
    paddingRight: 20,
    textAlign: 'center',
    width: '80%',
  },
};

export class HelpCenterSettingsView extends Component {
  static propTypes = {
    updateSettings: PropTypes.func.isRequired,
    settings: PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.any,
      settings: PropTypes.array,
    }),
    height: PropTypes.number,
  };

  constructor() {
    super();

    this.state = {
      helpdeskAutoResponse: null,
      helpdeskVideoId: null,
    };
  }

  update(fieldName, fieldValue) {
    if (fieldValue === null) {
      return;
    }

    this.props.updateSettings([
      {
        name: fieldName,
        value: fieldValue,
      },
    ]);
  }

  render() {
    if (this.props.settings.loading) {
      return <Loading />;
    }

    const { settings } = this.props.settings;
    const helpdeskAutoResponse = settings.find(v => v.name === 'helpdeskAutoResponse').value;
    const helpdeskVideoId = settings.find(v => v.name === 'helpdeskVideoId').value;

    return (
      <div style={{ ...styles.buttonsContainer, height: this.props.height || null }}>
        <Subheader>Helpdesk User Profile</Subheader>
        <TextField
          multiLine={false}
          floatingLabelText="YouTube Video ID"
          value={this.state.helpdeskVideoId === null ? helpdeskVideoId : this.state.helpdeskVideoId}
          fullWidth={true}
          onChange={(event, newValue) => this.setState({ helpdeskVideoId: newValue })}
        />
        <RaisedButton onClick={() => this.update('helpdeskVideoId', this.state.helpdeskVideoId)}>Update</RaisedButton>

        <Subheader>Helpdesk Automatic Response</Subheader>
        <TextField
          multiLine={true}
          rows={2}
          rowsMax={3}
          floatingLabelText="Automatic response"
          value={this.state.helpdeskAutoResponse === null ? helpdeskAutoResponse : this.state.helpdeskAutoResponse}
          fullWidth={true}
          onChange={(event, newValue) => this.setState({ helpdeskAutoResponse: newValue })}
        />
        <RaisedButton onClick={() => this.update('helpdeskAutoResponse', this.state.helpdeskAutoResponse)}>Update</RaisedButton>
      </div>
    );
  }
}
