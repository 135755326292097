import gql from 'graphql-tag';

export const allVideosQuery = gql`
  query allVideos(
    $search: String
    $skip: Int
    $limit: Int
    $perferedIds: [String]
  ) {
    allVideos(
      search: $search
      skip: $skip
      limit: $limit
      perferedIds: $perferedIds
    ) {
      total
      totalForFilter
      items {
        id
        order
        name
        label
        data
        yocasType
        previewImage
        duration
      }
    }
  }
`;
