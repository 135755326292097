import React, { Component } from 'react';
import { Checkbox, List, ListItem } from 'material-ui';
import { FiltersBar } from '../../common/components/filters-bar.component';
import TextField from 'material-ui/TextField';
import { FloatingActionButton, RaisedButton } from 'material-ui';
import { FontAwesome } from '../../common/components/font-awesome';
import {CreateMeetingModal} from './create-meeting-modal';
import { ActionMenu } from '../../common/components/action-menu.component';
import { DataTable } from '../../common/components/data-table.component';
import moment from 'moment';
import { apolloClient } from '../../core/apollo/apollo-subs';
import { meetingCreatedSubscription } from '../graphql/meeting-added.subscription';
import { updateMeetingStartedMutation } from '../graphql/update-host-joined.mutation';
import './meetings.css';

const { ZoomMtg } = require('@zoomus/websdk')
const apiKey = "2WRtlJLbTD2Oo_u9ItMp-g"
export class MeetingsView extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            loading: [],
            users: null,
            createModalOpen: false,
            allMeetings: null,
            meetings: null,
            skip: 0,
            limit: 100,
            search: "",
            showOnlyFuture: false
        }
    }

    componentWillMount = () => {
        this.getMeetings()
        this.subscribeToNewMeetings()
        const showList =
        this.props.students &&
        this.props.students.allStudents &&
        this.props.students.allStudents.items; 

        if(showList !== null && showList !== undefined) {
            this.setState({ users: showList })
        }
    }

    componentWillReceiveProps = () => {
        const showList =
        this.props.students &&
        this.props.students.allStudents &&
        this.props.students.allStudents.items; 

        if(showList !== null && showList !== undefined && this.state.users === null) {
            this.setState({ users: showList })
        }
    }


    subscribeToNewMeetings = () => {
        apolloClient.subscribe({
            query: meetingCreatedSubscription,
            variables: {}
        })
        .subscribe({
            next: (data) => {
                console.log('new meeting added!', data)
                let newMeeting = data.meetingCreated
                this.setState( {allMeetings: [newMeeting, ...this.state.allMeetings]}, () => {
                    this.setState({ meetings: this.state.allMeetings })
                })
            }
        })
    }

    getMeetings = async () => {
        let res = await fetch('https://Be.YOCAS.life:4000/get_meetings', {
        // let res = await fetch('http://localhost:4000/get_meetings', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            body: JSON.stringify({
                search: this.state.search,
                skip: this.state.skip,
                limit: this.state.limit
            })
        })
        let meetings = await res.json()
        console.log('meetings =>', meetings)
        let sortedMeetings =  meetings.meetings.sort((a,b) => new Date(b.meetingTime) - new Date(a.meetingTime))
        console.log('sortedMeetings =>', sortedMeetings)
        this.setState({ allMeetings: sortedMeetings }, () => {
            this.setState({ meetings: this.state.allMeetings })
        })

    }


    updateSearch(newValue) {
        this.props.updateFilter({ skip: 0, search: newValue });
    }

    openCreateMeetingModal = () => {
        this.setState({ createModalOpen: !this.state.createModalOpen })
    }

    saveMeeting = async (newMeeting) => {
        console.log('new meeting =>', newMeeting)
        this.setState({ createModalOpen: false })

        let res = await fetch('https://Be.YOCAS.life:4000/create_meeting', {
        // let res = await fetch('http://localhost:4000/create_meeting', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            body: JSON.stringify({
                meeting: newMeeting
            })
        })
    } 

    updateHostJoined = (meeting) => {
        // fetch('https://Be.YOCAS.life:4000/graphql', {
        let id = meeting._id === undefined ? meeting.id : meeting._id
        let variables = { meetingId: id }
        console.log('variables', variables)
        fetch('https://Be.YOCAS.life:4000/graphql', {
            method: 'POST',
            body: JSON.stringify({
              query: `
                mutation updateMeetingStarted($meetingId: String){
                    updateMeetingStarted(meetingId: $meetingId) {
                        id
                    }
                }
              `,
              variables,
              fetchPolicy: 'network-only'
            }),
            headers: {
              'Content-Type': 'application/json'
            }
          })
        .then(res => res.json())
        .then(res => res.data.updateMeetingStarted);
    }

    startMeetingAsHost = async (item) => {
        console.log('start meeting =>', item)
        // this.updateHostJoined(item.id)
        this.joinMeeting(item)
    }

    getSignature = async (meetingNumber, hostType) => {
        let res = await fetch('https://Be.YOCAS.life:4000/generate_signature', {
        // let res = await fetch('http://localhost:4000/generate_signature', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            body: JSON.stringify({
                meetingNumber,
                role: hostType
            })
        })
        let data = await res.json()
        let signature = data.signature
        return signature
    }

    joinMeeting = async (meeting) => {
        // window.location.href = `https://v.dualitmeestdomian.com/${meeting.meetingName}`
        this.props.history.push(`/admin/video/${meeting.meetingName}`)
        // document.getElementById('zmmtg-root').style.display = 'block'
        // ZoomMtg.setZoomJSLib('https://source.zoom.us/1.9.0/lib', '/av');
        // ZoomMtg.i18n.load('en-US'); // load http://localhost:9999/websdk/dist/lib/lang/en-US.json 2
        // ZoomMtg.i18n.reload('en-US');

        // // ZoomMtg.setZoomJSLib('node_modules/@zoomus/websdk/dist/lib', '/av');
        // ZoomMtg.preLoadWasm();
        // ZoomMtg.prepareJssdk();

        let hostType = 1
        console.log('hostType =>', hostType)
        console.log('meeting after =>', meeting)
        if(hostType === 1) {
            console.log('meeting =>', meeting)
            this.updateHostJoined(meeting)   
        }
        // let signature = await this.getSignature(meeting.meetingNumber.toString(), hostType)
        // let a = {
        //     signature,
        //     meetingNumber: meeting.meetingNumber.toString(),
        //     userName: meeting.hostName,
        //     apiKey,
        //     userEmail: meeting.hostEmail,
        //     passWord: meeting.meetingCode,
        // }
        // console.log('a =>' ,a)
        // ZoomMtg.init({
        //     leaveUrl: "https://adminpanelforyocas.azurewebsites.net/meetings",
        //     isSupportAV: true,
        //     // on success, call the join method
        //     success: () => {
        //         ZoomMtg.join({
        //             signature,
        //             meetingNumber: meeting.meetingNumber.toString(),
        //             userName: meeting.hostName,
        //             apiKey,
        //             userEmail: meeting.hostEmail,
        //             passWord: meeting.meetingCode,
        //             success: (success) => {
        //               console.log('success',success)
        //               localStorage.setItem("meetingId", meeting.id)
        //             },
        //             error: (error) => {
        //               console.log('error',error)
        //             }
        //           })
        //     },
        //     error: (error) => {
        //         console.log('error',error)
        //     }
        // })
    }

    updateMeetings = () => {
        if(this.state.showOnlyFuture) {
            this.setState({ meetings: this.state.allMeetings, showOnlyFuture: false})
        } else {
            let filteredMeetings = this.state.allMeetings.filter(elem => moment(elem.meetingTime).isAfter(moment()))
            this.setState({ meetings: filteredMeetings, showOnlyFuture: true})
        }
    }



    

    render() { 
        const totalForFilter = this.state.meetings ? this.state.meetings.length : 0
        const tableDesc = [
            { headerName: 'Live Stream Name', width: 30, valueFrom: 'meetingName' },
            { headerName: 'Live Stream Number', width: 30, valueFrom: 'meetingNumber' },
            { headerName: 'Host Name', width: 25, valueFrom: 'hostName' },
            { headerName: 'Host Email', width: 30, valueFrom: 'hostEmail' },
            { 
                headerName: 'Live Stream Starting Time', 
                width: 30,
                render: item => moment(item.meetingTime).format('MM/DD/YYYY h:mm A')
            },
            {
                headerName: '',
                width: 30,
                render: item => <RaisedButton
                    onClick={() => this.startMeetingAsHost(item)}
                    disabled={moment(item.meetingTime).isBefore(moment())}
                    primary={true}
                    label='Start Live Stream'
                    fullWidth={true}
                />
            }
            // {
            //   headerName: 'Actions',
            //   width: 15,
            //   rowStyle: {
            //     overflow: 'visible'
            //   },
            //   render: item => {
            //     const disabled = this.isDisableEditForm(item);
      
            //     return (
            //       <div>
            //         {disabled ? null : (
            //           <FontAwesome
            //             style={styles.actionIcon}
            //             onClick={() => this.removeMeeting(item)}
            //             tooltip='Remove'
            //             name='trash'
            //           />
            //         )}
            //       </div>
            //     );
            //   }
            // }
          ];
      



        return (
            <div>
                <FiltersBar title='Live Streaming'>
                    <TextField
                        hintText='Search live stream'
                        value={this.props.search}
                        onChange={(event, newValue) => this.updateSearch(newValue)}
                    />
                    <FloatingActionButton
                        style={styles.addButton}
                        onClick={() => this.openCreateMeetingModal()}
                    >
                        <FontAwesome name='plus' />
                    </FloatingActionButton>
                  
                </FiltersBar>
                <div onClick={this.updateMeetings} style={styles.showFutureContainer}>
                    {!this.state.showOnlyFuture ? "Show only future live stream" : "Show all live stream"}
                </div>

                {
                    this.state.createModalOpen && 
                    <ActionMenu
                    onRequestClose={() => this.setState({ createModalOpen: null })}
                    title={'Create Live Stream'}
                    open={!!this.state.createModalOpen}
                  >
                    <CreateMeetingModal saveMeeting={this.saveMeeting}/>
                </ActionMenu>
                }
               { 
                this.state.meetings &&
                <div style={{ ...styles.row, ...styles.tableContainer }}>

                <DataTable            
                    height='60vh'
                    loading={false}
                    skip={0}
                    limit={10}
                    total={totalForFilter}
                    onNext={() => this.updatePagination(true)}
                    onPrev={() => this.updatePagination(false)}
                    onResetFilter={() => this.resetFilter()}
                    style={styles.table}
                    idField='id'
                    data={this.state.meetings}
                    description={tableDesc}
                    showFooter={false}
                />
            </div>

                }
            </div>
        )
    }
}

const styles = {
    row: {
        display: 'flex',
        flexDirection: 'row'
      },
      infoSpan: {
        marginRight: 10,
        fontWeight: 'bold'
      },
      details: {
        marginLeft: 20
      },
      tableContainer: {
        marginTop: 10
      },
      infoText: {
        userSelect: 'text'
      },
    addButton: {
      float: 'right',
      zIndex: '99',
    },
    actionIcon: {
      cursor: 'pointer',
      fontSize: 12,
      marginRight: 10
    },
    table: {
      marginLeft: '3%',
      marginRight: '3%'
    },
    showFutureContainer: {
        marginLeft: '3%',
        backgroundColor: 'white',
        padding: '1%',
        marginBottom: '2%',
        fontSize: '13px',
        color: '#1114a4',
        textDecoration: 'underline',
        cursor: 'pointer',
        width: '94%'
    }
};