import { graphql, compose } from 'react-apollo';
import { allManualsQuery } from '../graphql/all-manuals-query';
import { ManualsView } from './manuals.component';
import { connect } from 'react-redux';
import { updateManualsFilter, editEntity } from '../manuals.actions';
import { removeManualMutation } from '../graphql/remove-manual.mutation';
import { withStatistics } from '../../common/components/with-statistics.container';

const mapStateToProps = ({ manuals }) => ({
  skip: manuals.skip,
  limit: manuals.limit,
  search: manuals.search,
  editModalOpen: manuals.isEditMode,
  editModeEntity: manuals.editEntity
});

const mapDispatch = dispatch => ({
  updateFilter: filterChanges => dispatch(updateManualsFilter(filterChanges)),
  toggleEditMode: entity => dispatch(editEntity(entity))
});

const withState = connect(mapStateToProps, mapDispatch);

const withData = graphql(allManualsQuery, {
  name: 'manuals',
  options: props => {
    let extraVars = {
      perferedIds: []
    };

    if (
      props.search === '' &&
      props.checkedItems &&
      props.checkedItems.length > 0
    ) {
      extraVars = {
        perferedIds: props.checkedItems
          .map(group =>
            group.startsWith('manual_') ? group.replace('manual_', '') : null
          )
          .filter(item => item)
      };
    }

    return {
      fetchPolicy: 'network-only',
      variables: {
        skip: props.skip,
        limit: props.limit,
        search: props.search,
        ...extraVars
      }
    };
  }
});

export const withManuals = compose(withState, withData);

const withMutation = graphql(removeManualMutation, {
  options: {
    refetchQueries: ['allManuals', 'statistics']
  },
  props: ({ ownProps, mutate }) => ({
    removeManual: manualId => mutate({ variables: { manualId } })
  })
});

export const Manuals = withManuals(withMutation(withStatistics(ManualsView)));
