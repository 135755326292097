import { AuthorizationMiddleware } from './authorization-middleware';
import { TimezoneMiddleware } from './timezone-middleware';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import { ApolloLink } from 'apollo-link';
import ApolloClient from 'apollo-client';
import introspectionQueryResultData from './fragmentTypes';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { split } from 'apollo-link';

export const getConnection = (uri, wsUri) => {
  const httpLink = createHttpLink({ uri });

  const middlewareLink = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        ...AuthorizationMiddleware.getHeader(),
        ...TimezoneMiddleware.getHeader(),
      },
    });

    return forward(operation);
  });

  const wsLink = new WebSocketLink({
    uri: wsUri,
    options: {
      lazy: true,
      timeout: 20000,
      connectionParams: () => ({
        authToken: AuthorizationMiddleware.token,
        timezoneOffset: TimezoneMiddleware.offset,
      }),
      reconnect: true,
    },
  });

  const cache = new InMemoryCache({
    addTypename: true,
    fragmentMatcher: new IntrospectionFragmentMatcher({
      introspectionQueryResultData,
    }),
  });

  const mergedLink = middlewareLink.concat(httpLink);

  const link = split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);

      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    mergedLink,
  );

  return new ApolloClient({
    link,
    cache,
  });
};
