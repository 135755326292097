const config = {
  basePath: '/',
  server: {
    protocol: 'https',
    host: 'Be.YOCAS.life',
    port: 5000,
    path: '/graphql',
    profileImagesUploadPath: '/upload/profileImages'
  },
  cleanStorage: false
};

export default config;
