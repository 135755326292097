import { graphql } from 'react-apollo';
import { waitingLiveFeedApprovalsQuery } from '../../students/graphql/waiting-live-feed-approvals.query';
import { LiveFeedApprovalsView } from './live-feed-approvals.component';

const withData = graphql(waitingLiveFeedApprovalsQuery, {
  name: 'approvals',
  options: {
    pollInterval: 10000,
    fetchPolicy: 'network-only',
  },
});

export const LiveFeedApprovals = withData(LiveFeedApprovalsView);
