import { graphql } from 'react-apollo';
import { CreateEditVideoFormView } from './create-edit-video-form.component';
import { createVideoMutation } from '../graphql/create-new-video.mutation';

const withMutation = graphql(createVideoMutation, {
  options: {
    refetchQueries: ['allVideos', 'statistics']
  },
  props: ({ ownProps, mutate }) => ({
    createVideo: videoData => mutate({ variables: videoData })
  })
});

export const CreateEditVideoForm = withMutation(CreateEditVideoFormView);
