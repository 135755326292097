import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StatisticsBar } from '../../common/components/statistics-bar.component';
import { Theme } from '../../theme/theme';
import { FiltersBar } from '../../common/components/filters-bar.component';
import { DataTable } from '../../common/components/data-table.component';
import TextField from 'material-ui/TextField';
import { ActionMenu } from '../../common/components/action-menu.component';
import { FontAwesome } from '../../common/components/font-awesome';
import { CreateEditCourseForm } from './create-edit-course-form.container';
import { FloatingActionButton } from 'material-ui';
import { YesNoModal } from '../../common/components/yes-no-modal.component';
import { ManageCourseStudentsDialog } from './manage-course-students.container';
import { ProfileAvatar } from '../../common/components/profile-avatar.component';
import { CourseIcon } from '../../common/components/course-icon.component';

const styles = {
  addButton: {
    float: 'right'
  },
  actionIcon: {
    cursor: 'pointer',
    fontSize: 12,
    marginRight: 10
  },
  table: {
    marginLeft: '3%',
    marginRight: '3%'
  }
};

export class CoursesView extends Component {
  static propTypes = {
    editModalOpen: PropTypes.bool,
    editModeEntity: PropTypes.any,
    statisticsData: PropTypes.any,
    toggleEditMode: PropTypes.func,
    removeCourse: PropTypes.func,
    skip: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    search: PropTypes.string.isRequired,
    updateFilter: PropTypes.func.isRequired,
    courses: PropTypes.shape({
      error: PropTypes.any,
      loading: PropTypes.bool,
      allCourses: PropTypes.shape({
        total: PropTypes.number,
        totalForFilter: PropTypes.number,
        items: PropTypes.arrayOf(PropTypes.any)
      })
    })
  };

  constructor() {
    super();

    this.state = {
      courseToRemove: null,
      courseToManage: null
    };
  }

  buildStatistics() {
    const data = this.props.statisticsData || {};

    return [
      {
        name: 'Groups',
        value: data.courses || 0,
        color: Theme.ACTIVE1_COLOR
      },
      {
        name: 'Manually Created',
        value: data.coursesCreatedManually || 0,
        color: Theme.ACTIVE2_COLOR
      },
      {
        name: 'Average per group',
        value: data.averageUsersPerCourse || 0,
        color: Theme.PRIMARY_COLOR
      }
    ];
  }

  updatePagination(nextData) {
    if (nextData) {
      this.props.updateFilter({ skip: this.props.skip + this.props.limit });
    } else {
      let newSkip = this.props.skip - this.props.limit;

      if (newSkip < 0) {
        newSkip = 0;
      }

      this.props.updateFilter({ skip: newSkip });
    }
  }

  resetFilter() {
    this.props.updateFilter({ skip: 0, search: '' });
  }

  updateSearch(newValue) {
    this.props.updateFilter({ skip: 0, search: newValue });
  }

  getTitle() {
    if (this.props.editModeEntity) {
      if (Object.keys(this.props.editModeEntity).length === 0) {
        return 'Create Group';
      }

      if (this.isDisableEditForm(this.props.editModeEntity)) {
        return 'View Group';
      }

      return 'Edit Group';
    }

    return '';
  }

  isDisableEditForm(entity = this.props.editModeEntity) {
    return entity && entity.isManual === false;
  }

  removeCourse(course) {
    this.setState({
      courseToRemove: course
    });
  }

  removeModalResult(doRemove) {
    console.log(doRemove, 'doRemove');
    const course = this.state.courseToRemove;

    this.setState({
      courseToRemove: null
    });

    if (doRemove) {
      this.props.removeCourse(course.id);
    }
  }

  manageCourseStudents(course) {
    this.setState({
      courseToManage: course
    });
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.state.courseToManage !== null &&
      nextProps.courses &&
      nextProps.courses.allCourses &&
      nextProps.courses.allCourses.items
    ) {
      this.setState({
        courseToManage: nextProps.courses.allCourses.items.find(
          course => course.id === this.state.courseToManage.id
        )
      });
    }
  }

  render() {
    const stats = this.buildStatistics();

    const tableDesc = [
      {
        headerName: '',
        width: 5,
        render: item => (
          <CourseIcon
            style={{ maxWidth: '15px' }}
            active={false}
            courseIdentifier={item.identifier}
          />
        )
      },
      { headerName: 'Identifier', width: 20, valueFrom: 'identifier' },
      { headerName: 'Subject', width: 30, valueFrom: 'fullName' },
      {
        headerName: 'Lecturer',
        width: 20,
        render: item => {
          if (item && item.teacher && item.teacher.fullName) {
            return (
              <div>
                <ProfileAvatar size={30} src={item.teacher.profilePictureUrl} />
                <div
                  style={{
                    display: 'inline-block',
                    lineHeight: '30px',
                    verticalAlign: 'top',
                    marginLeft: 5
                  }}
                >
                  {item.teacher.fullName}
                </div>
              </div>
            );
          }

          return <span />;
        }
      },
      {
        headerName: 'Participants',
        width: 10,
        render: item => String(item.students.length)
      },
      {
        headerName: 'Actions',
        width: 15,
        rowStyle: {
          overflow: 'visible'
        },
        render: item => {
          const disabled = this.isDisableEditForm(item);

          return (
            <div>
              <FontAwesome
                style={styles.actionIcon}
                onClick={() => this.manageCourseStudents(item)}
                tooltip='Participants List'
                name='users'
              />
              <FontAwesome
                style={styles.actionIcon}
                onClick={() => this.props.toggleEditMode(item)}
                tooltip={disabled ? 'View' : 'Edit'}
                name={disabled ? 'eye' : 'pencil-square-o'}
              />
              {disabled ? null : (
                <FontAwesome
                  style={styles.actionIcon}
                  onClick={() => this.removeCourse(item)}
                  tooltip='Remove'
                  name='trash'
                />
              )}
            </div>
          );
        }
      }
    ];

    const totalForFilter = this.props.courses.allCourses
      ? this.props.courses.allCourses.total
      : 0;
    const items = this.props.courses.allCourses
      ? this.props.courses.allCourses.items
      : [];

    return (
      <div>
        <ManageCourseStudentsDialog
          disabled={this.isDisableEditForm(this.state.courseToManage)}
          loading={this.props.courses.loading}
          course={this.state.courseToManage}
          currentStudents={
            this.state.courseToManage ? this.state.courseToManage.students : []
          }
          open={this.state.courseToManage !== null}
          onRequestClose={() => this.manageCourseStudents(null)}
        />
        <YesNoModal
          title='Are you sure?'
          open={this.state.courseToRemove !== null}
          onNo={() => this.removeModalResult(false)}
          onYes={() => this.removeModalResult(true)}
        >
          {this.state.courseToRemove === null ? null : (
            <div>
              Removing the course{' '}
              <strong>
                {this.state.courseToRemove.fullName}(
                {this.state.courseToRemove.identifier})
              </strong>{' '}
              will also remove the course from all participants that connected
              to it!
            </div>
          )}
        </YesNoModal>
        <ActionMenu
          onRequestClose={() => this.props.toggleEditMode(null)}
          title={this.getTitle()}
          open={this.props.editModalOpen}
        >
          <CreateEditCourseForm
            disabled={this.isDisableEditForm()}
            course={this.props.editModeEntity}
          />
        </ActionMenu>
        <StatisticsBar data={stats} />
        <FiltersBar title='Groups'>
          <TextField
            hintText='Search groups'
            value={this.props.search}
            onChange={(event, newValue) => this.updateSearch(newValue)}
          />
          <FloatingActionButton
            style={styles.addButton}
            onClick={() => this.props.toggleEditMode({})}
          >
            <FontAwesome name='plus' />
          </FloatingActionButton>
        </FiltersBar>
        <DataTable
          loading={this.props.courses.loading}
          skip={this.props.skip}
          limit={this.props.limit}
          total={totalForFilter}
          onNext={() => this.updatePagination(true)}
          onPrev={() => this.updatePagination(false)}
          onResetFilter={() => this.resetFilter()}
          style={styles.table}
          idField='id'
          data={items}
          description={tableDesc}
        />
      </div>
    );
  }
}
