import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RaisedButton, ListItem, List, Subheader, Checkbox } from 'material-ui';
import { Loading } from '../../common/components/loading.component';
import { UsersPicker } from './users-picker.container';
import { CoursesPicker } from './courses-picker.container';
import { FontAwesome } from '../../common/components/font-awesome';

export class TargetPickerFormView extends Component {
  static propTypes = {
    targets: PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.any,
      publicationTargets: PropTypes.arrayOf(PropTypes.any)
    }).isRequired,
    updateTargets: PropTypes.func.isRequired,
    editEntityId: PropTypes.string,
    editEntity: PropTypes.object,
    currentLocation: PropTypes.any,
    navigate: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    hideButtons: PropTypes.bool,
    disableAllUsers: PropTypes.bool,
    groups: PropTypes.any
  };

  constructor() {
    super();

    this.state = {
      groups: ['all'],
      closedPickers: []
    };

    this.pickerPerGroup = {
      users: {
        component: () => (
          <UsersPicker
            fromMeetings = {false}
            checkedItems={this.state.groups || []}
            onItemChange={(id, isChecked) =>
              this.handleGroupsChange(id, isChecked)
            }
          />
        ),
        counter: () =>
          (this.state.groups || []).filter(group => group.startsWith('user_'))
            .length,
        teardown: () => {
          this.setState({
            groups: this.state.groups.filter(
              group => !group.startsWith('user_')
            )
          });
        }
      },
      courses: {
        component: () => (
          <CoursesPicker
            checkedItems={this.state.groups || []}
            onItemChange={(id, isChecked) =>
              this.handleGroupsChange(id, isChecked)
            }
          />
        ),
        counter: () =>
          (this.state.groups || []).filter(group => group.startsWith('course_'))
            .length,
        teardown: () => {
          this.setState({
            groups: this.state.groups.filter(
              group => !group.startsWith('course_')
            )
          });
        }
      }
    };
  }

  componentDidMount() {
    if (this.props.disableAllUsers) {
      this.setState({
        groups: []
      });
    }
    if (this.props.groups) {
      this.setState({
        groups: this.props.groups
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.editEntity) {
      const targets = nextProps.editEntity.targets || [];

      this.setState({
        groups: targets
      });
    }

    if (nextProps.groups) {
      this.setState({
        groups: nextProps.groups
      });
    }
  }

  handleGroupsChange(groupId) {
    if (!this.state.groups.includes(groupId)) {
      this.setState(
        {
          groups: [...this.state.groups, groupId]
        },
        () => {
          if (this.props.onChange) {
            this.props.onChange(this.state.groups);
          }
        }
      );
    } else {
      this.setState(
        {
          groups: this.state.groups.filter(id => id !== groupId)
        },
        () => {
          if (this.props.onChange) {
            this.props.onChange(this.state.groups);
          }
          if (this.pickerPerGroup[groupId]) {
            this.pickerPerGroup[groupId].teardown();
          }
        }
      );
    }
  }

  toggleGroupClose(groupId) {
    if (!this.state.closedPickers.includes(groupId)) {
      this.setState({
        closedPickers: [...this.state.closedPickers, groupId]
      });
    } else {
      this.setState({
        closedPickers: this.state.closedPickers.filter(id => id !== groupId)
      });
    }
  }

  skip() {
    const entityType = this.props.currentLocation.split('/')[2];
    this.props.navigate(`/admin/${entityType}`);
  }

  save() {
    const entityType = this.props.currentLocation.split('/')[2];
    this.props
      .updateTargets(this.props.editEntityId, this.state.groups)
      .then(() => {
        this.props.navigate(
          `/admin/${entityType}/platforms/${this.props.editEntityId}`
        );
      })
      .catch(console.log);
  }

  render() {
    if (this.props.targets.loading) {
      return <Loading />;
    }

    return (
      <List>
        <Subheader>Pick relevant groups:</Subheader>
        {this.props.targets.publicationTargets.map(group => {
          if (group.id === 'all' && this.props.disableAllUsers) {
            return null;
          }

          const isChecked = this.pickerPerGroup[group.id]
            ? this.pickerPerGroup[group.id].counter() > 0
            : this.state.groups && this.state.groups.includes(group.id);
          const total =
            group.total === null
              ? this.pickerPerGroup[group.id]
                ? ` (${this.pickerPerGroup[group.id].counter()} items picked)`
                : null
              : ` (Total ${group.total} MSJ Participants)`;
          let name =
            group.name === 'All Students'
              ? 'All Participants'
              : group.name === 'By Courses'
              ? 'By Groups'
              : group.name;
          return (
            <ListItem
              onClick={e => e.preventDefault()}
              key={group.id}
              leftCheckbox={
                <Checkbox
                  onCheck={(e, checked) =>
                    this.handleGroupsChange(group.id, checked)
                  }
                  checked={isChecked}
                />
              }
              primaryText=''
            >
              <span
                style={{ cursor: 'pointer' }}
                onClick={e => {
                  this.toggleGroupClose(group.id);
                  e.preventDefault();
                }}
              >
                {name} {total}
                {this.pickerPerGroup[group.id] ? (
                  <FontAwesome
                    style={{ marginLeft: 10 }}
                    name={
                      !this.state.closedPickers.includes(group.id)
                        ? 'caret-down'
                        : 'caret-up'
                    }
                  />
                ) : (
                  ''
                )}
              </span>
              {this.state.closedPickers.includes(group.id) &&
              this.pickerPerGroup[group.id]
                ? this.pickerPerGroup[group.id].component()
                : null}
            </ListItem>
          );
        })}
        {!this.props.hideButtons && (
          <ListItem disabled={true}>
            <RaisedButton
              onClick={() => this.skip()}
              style={{ marginRight: 30 }}
              label='Skip'
            />
            <RaisedButton
              onClick={() => this.save()}
              disabled={this.state.groups.length === 0}
              primary={true}
              label='Continue'
            />
          </ListItem>
        )}
      </List>
    );
  }
}
