import React, { Component } from 'react';
import { createFeedContainer } from '../../feed/components/feed-items';
import { Theme } from '../../theme/theme';
import { ProfileAvatar } from '../../common/components/profile-avatar.component';
import { Analytics } from '../../analytics/components/analytics.container';
import { Dialog, RaisedButton } from 'material-ui';
import { FontAwesome } from '../../common/components/font-awesome';

const statistics = data => [
  {
    name: 'Published News',
    value: data.publishedNews || 0,
    color: Theme.ACTIVE1_COLOR
  },
  {
    name: 'Average Per Category',
    value: data.averageNewsCategory || 0,
    color: Theme.ACTIVE2_COLOR
  }
];

const tableColumns = componentContext => [
  { headerName: 'Title', width: 20, valueFrom: 'title' },
  {
    headerName: 'Author',
    width: 15,
    render: item => {
      if (item && item.author && item.author.name) {
        return (
          <div>
            <ProfileAvatar size={30} src={item.author.profilePictureUrl} />
            <div
              style={{
                display: 'inline-block',
                lineHeight: '30px',
                verticalAlign: 'top',
                marginLeft: 5
              }}
            >
              {item.author.name}
            </div>
          </div>
        );
      }

      return <span />;
    }
  }
];

export const NewsTable = createFeedContainer(
  'NEWS',
  'News',
  statistics,
  tableColumns,
  null,
  (item, componentContext) => [
    <FontAwesome
      tooltip='Analytics'
      onClick={() => componentContext.props.onAnalyticsClicked(item)}
      key={1}
      style={{ cursor: 'pointer', fontSize: 12, marginRight: 10 }}
      name='line-chart'
    />,
    <a target={'_blank'} href={item.link}>
      <FontAwesome
        tooltip='External Link'
        key={1}
        style={{ cursor: 'pointer', fontSize: 12, marginRight: 10 }}
        name='external-link'
      />
    </a>
  ]
);

export class News extends Component {
  constructor() {
    super();

    this.state = {
      analytics: null
    };
  }

  openAnalytics(news) {
    this.setState({
      analytics: news
    });
  }

  getAnalyticsParams() {
    return [
      {
        category: 'Feed',
        action: 'NewsReadMore',
        identifier: this.state.analytics.id,
        name: 'Read more clicks'
      },
      {
        category: 'Feed',
        action: 'NewsReadMore',
        identifier: this.state.analytics.id,
        name: 'Read more clicks (Unique)',
        flags: { unique: true }
      }
    ];
  }

  render() {
    return (
      <div>
        <Dialog
          onRequestClose={() => this.openAnalytics(null)}
          title={
            this.state.analytics
              ? `Analytics for news: ${this.state.analytics.title}`
              : ''
          }
          actions={[
            <RaisedButton
              primary={true}
              label='Close'
              onClick={() => this.openAnalytics(null)}
              onTouchTap={() => this.openAnalytics(null)}
            />
          ]}
          contentStyle={{
            position: 'absolute',
            left: '50%',
            top: '30%',
            transform: 'translate(-50%, -50%)'
          }}
          open={this.state.analytics !== null}
        >
          {this.state.analytics && (
            <Analytics params={this.getAnalyticsParams()} />
          )}
        </Dialog>
        <NewsTable onAnalyticsClicked={news => this.openAnalytics(news)} />
      </div>
    );
  }
}
