import gql from 'graphql-tag';
import { newsFeedEventFragment } from '../../events/graphql/news-feed-event-item.fragment';
import { newsFeedPollFragment } from '../../polls/graphql/news-feed-poll-item.fragment';
import { newsFeedNewsFragment } from '../../news/graphql/news-feed-news-item.fragment';

export const feedItemByIdQuery = gql`
  query feedItem($id: String!) {
    feedItem(id: $id) {
      ...EventFields
      ...PollFields
      ...NewsFields
    }
  }

  ${newsFeedEventFragment}
  ${newsFeedPollFragment}
  ${newsFeedNewsFragment}
`;
