import { graphql } from 'react-apollo';
import { SnapchatApprovalDialogView } from './snapchat-approval-dialog.component';
import { manuallyApproveSnapchatMutation } from '../../students/graphql/approve-manually-snapchat.mutation';

const withApproveMutation = graphql(manuallyApproveSnapchatMutation, {
  options: {
    refetchQueries: ['waitingSnapchatApprovals', 'statistics'],
  },
  props: ({ ownProps, mutate }) => ({
    approve: (approvalId, username) => mutate({ variables: { approvalId, approve: true, username } }),
    reject: approvalId => mutate({ variables: { approvalId, approve: false } }),
  }),
});

export const SnapchatApprovalDialog = withApproveMutation(SnapchatApprovalDialogView);
