import gql from 'graphql-tag';

export const publicationTargetsQuery = gql`
  query publicationTargets {
    publicationTargets {
      name
      id
      total
    }
  }
`;
