import { graphql } from 'react-apollo';
import { AddEditCategoryModalView } from './add-edit-category.component';
import { createUpdateCategoryMutation } from '../graphql/create-update-category.mutation';

const withAddEditMutation = graphql(createUpdateCategoryMutation, {
  props: ({ mutate }) => ({
    createUpdate: (existingId, category) => mutate({
      variables: {
        updateId: existingId,
        category,
      },
      refetchQueries: ['categories'],
    }),
  }),
});

export const AddEditCategoryModal = withAddEditMutation(AddEditCategoryModalView);
