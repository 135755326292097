import React, { Component } from 'react';
import Subheader from 'material-ui/Subheader';
import { List, ListItem } from 'material-ui/List';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import { Theme } from '../../theme/theme';
import { FormikMaterialTextField } from '../../common/components/material-text-field.formik';
import { UserPickerDropdown } from '../../feed/components/user-dropdown.component';
import { AMPMTimePicker } from '../../common/components/time-picker.rn';
import { DatePicker, RaisedButton } from 'material-ui';
import { UsersPicker } from '../../feed/components/users-picker.container';
import moment from 'moment';
import Dialog from 'material-ui/Dialog';
import { FlatButton } from 'material-ui';

export class CreateMeetingModal extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            selectedUsers: ['all'],
            displayAuthor: null,
            startDate: null,
            startTime: moment().format('hh:mm A'),
            timeValue: new Date(),
            groups: [],
            errorModal: false
        }
    }



    
    


    createMeeting = (values) => {
        let date = moment(this.state.startDate).format('YYYY/MM/DD')
        var momentObj = moment(date + this.state.startTime, 'YYYY-MM-DDLT');
        var dateTime = momentObj.format('YYYY-MM-DDTHH:mm:ssZ');

        if(this.state.displayAuthor === null || this.state.displayAuthor === "" ||
        this.state.startDate === null || this.state.startDate === "" ||
          values.meetingName == null || values.meetingName === "" ) {
            console.log('cannot continue!!')
            this.setState({ errorModal: true})
        }
        else {
          let newMeeting = {
            host: this.state.displayAuthor,
            meetingTime: dateTime,
            meetingName: values.meetingName,
            participants: this.state.groups
        }
        this.props.saveMeeting(newMeeting)
        }
    }

  


    handleGroupsChange = (groupId) => {
        console.log('groupId =>', groupId)
        if (!this.state.groups.includes(groupId)) {
          this.setState(
            {
              groups: [...this.state.groups, groupId]
            },
            () => {
                console.log('this.state.groups =>', this.state.groups)
            }
          );
        } else {
          this.setState(
            {
              groups: this.state.groups.filter(id => id !== groupId)
            },
            () => {
            // this.setState({
            //     groups: this.state.groups.filter(
            //         group => !group.startsWith('user_')
            //     )
            //     });
            //     console.log('this.state.groups =>', this.state.groups)
            }
          );
        }

      }

    render() {

      const actions = [
          <FlatButton
            label='Close'
            onTouchTap={() =>this.setState({ errorModal: !this.state.errorModal})}
            onClick={() => this.setState({ errorModal: !this.state.errorModal})}
          />
        ]
     
        ;

        return (
            <Formik
                initialValues = {{

                }}
                onSubmit = {this.createMeeting}
            >
                 {({ isSubmitting, isValid, handleSubmit }) => {
                     return (
                         <form onSubmit={handleSubmit}>
                            <List>
                             <Subheader style={styles.header}>Live Streaming Information</Subheader>
                             <ListItem innerDivStyle={styles.miniListItem} disabled={true}>
                                <Field
                                    name='meetingName'
                                    floatingLabelText='Live Stream Name*'
                                    component={FormikMaterialTextField}
                                />
                            </ListItem>
                            <ListItem innerDivStyle={styles.miniListItem} disabled={true}>
                               <UserPickerDropdown  
                                fromMeetings={true}
                                isPart={false}
                                selectedUser={this.state.displayAuthor} 
                                onChange={user => this.setState({ displayAuthor: user })} />
                            </ListItem>
                            <ListItem innerDivStyle={styles.miniListItem} disabled={true}>
                                <UsersPicker
                                    fromMeetings={true}
                                    checkedItems={this.state.groups}
                                    onItemChange={(id, isChecked) =>
                                      this.handleGroupsChange(id, isChecked)
                                    }
                                />
                            </ListItem>
                            <ListItem innerDivStyle={styles.miniListItem} disabled={true}>
                                <div>
                                    <DatePicker
                                    firstDayOfWeek={0}
                                    style={styles.inline}
                                    autoOk={true}
                                    floatingLabelText="Start Date*"
                                    value={this.state.startDate}
                                    onChange={(event, startDate) => this.setState({ startDate })}
                                    container="inline"
                                    mode="landscape" />
                                    <AMPMTimePicker
                                    label="Start Time*"
                                    value={this.state.startTime}
                                    onChange={(e, v) => this.setState({ startTime: v })} />
                                </div>
                            </ListItem>

                            <ListItem disabled={true}>
                                <RaisedButton
                                    onClick={() => handleSubmit()}
                                    // disabled={this.props.disabled || !this.isFormValid()}
                                    primary={true}
                                    label='SAVE'
                                    fullWidth={true}
                                />
                            </ListItem>
                            
                            </List>
                            
                            <Dialog
                              title={"Error"}
                              actions={actions}
                              modal={true}
                              open={this.state.errorModal}
                            >
                              <div>Please fill all the required fields</div>
                            </Dialog>

                         </form>
                     )
                 }}
        
             
            </Formik>
        )
    }
    

}




const styles = {
    header: {
      lineHeight: 'initial',
      paddingTop: 10
    },
    miniListItem: {
      paddingTop: 0,
      paddingBottom: 0
    },
    identifierField: {
      width: 230,
      marginLeft: 15
    },
    lecturerField: {
      width: 100,
      marginRight: 10
    },
    avatar: {
      marginTop: 12
    },
    headerNote: {
      color: Theme.ACTIVE2_COLOR
    }
  };