import React, { Component } from 'react';
import { GridList, GridTile } from 'material-ui/GridList';
import { DataBox } from '../../common/components/data-box.component';
import { Settings } from './settings.container';
import { ManageCategories } from './manage-categories.container';
import { HelpCenterSettings } from './help-center-settings.container';

const CONTENT_HEIGHT = 450;

const styles = {
  gridList: {
    width: '100%',
  },
  gridContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    marginLeft: '3%',
    marginRight: '3%',
  },
};

export class SettingsPage extends Component {
  renderGrid() {
    return (
      <div style={styles.gridContainer}>
        <GridList cellHeight={CONTENT_HEIGHT} style={styles.gridList}>
          <GridTile rows={1} cols={2}>
            <ManageCategories />
          </GridTile>
          <GridTile rows={1} cols={1}>
            <DataBox title="Server Settings">
              <Settings height={CONTENT_HEIGHT - 80} />
            </DataBox>
          </GridTile>
          <GridTile rows={1} cols={1}>
            <DataBox title="Help Center Settings">
              <HelpCenterSettings height={CONTENT_HEIGHT - 80} />
            </DataBox>
          </GridTile>
          
        </GridList>
      </div>
    );
  }

  render() {
    return (
      <div>
        { this.renderGrid() }
      </div>
    );
  }
}
