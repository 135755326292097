import { graphql } from 'react-apollo';
import { CreateEditCourseFormView } from './create-edit-course-form.component';
import { createManualCourseMutation } from '../graphql/create-manual-course.mutation';

const withMutation = graphql(createManualCourseMutation, {
  options: {
    refetchQueries: ['allCourses', 'statistics'],
  },
  props: ({ ownProps, mutate }) => ({
    createManualCourse: courseData => mutate({ variables: courseData }),
  }),
});

export const CreateEditCourseForm = withMutation(CreateEditCourseFormView);
