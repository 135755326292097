import { HelpdeskChatView } from './helpdesk-chat-screen.component';
import { graphql } from 'react-apollo/index';
import { helpdeskChatQuery } from '../graphql/chat.query';
import { sendMessageMutation } from '../graphql/send-message.mutation';
import moment from 'moment';
import { helpdeskChatsQuery } from '../graphql/helpdesk-chats.query';
import { compose } from 'redux';
import update from 'immutability-helper';
import { notifyChatReadStatusMutation } from '../graphql/notify-chat-read-status.mutation';
import { newChatMessagesSubscription } from '../graphql/new-chat-messages.subscription';
import { markAsResolvedMutation } from '../graphql/mark-as-resolved.mutation';
import gql from 'graphql-tag';

let subscribed = false;

export const withChat = graphql(helpdeskChatQuery, {
  skip: ownProps => !(ownProps.match && ownProps.match.params && ownProps.match.params.id),
  options: ownProps => ({
    fetchPolicy: 'network-only',
    variables: {
      id: ownProps.match.params.id,
      cursor: null,
    },
  }),
  props: ({ ownProps, chatData }) => ({
    loadOlder: messageId => {
      try {
        const messages = chatData.helpdeskChat.messages.messages;

        if (!messages || messages.length === 0) {
          return null;
        }

        return chatData.fetchMore({
          variables: {
            cursor: messageId,
            id: ownProps.match.params.id,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (fetchMoreResult && fetchMoreResult.helpdeskChat) {
              return update(prev, {
                helpdeskChat: {
                  messages: {
                    hasMore: { $set: fetchMoreResult.helpdeskChat.messages.hasMore },
                    cursor: { $set: fetchMoreResult.helpdeskChat.messages.cursor },
                    messages: {
                      $unshift: fetchMoreResult.helpdeskChat.messages.messages,
                    },
                  },
                },
              });
            }

            return prev;
          },
        });
      } catch (e) {
        return null;
      }
    },
    subscribeToMore: afterId => {
      if (!subscribed) {
        subscribed = true;

        const handle = chatData.subscribeToMore({
          document: newChatMessagesSubscription,
          variables: {
            chatId: ownProps.match.params.id,
            after: afterId || '',
          },
          updateQuery: (prev, { subscriptionData: { data: { helpdeskChatMessages } } }) => update(prev, {
            helpdeskChat: {
              messages: {
                messages: {
                  $push: [helpdeskChatMessages],
                },
              },
            },
          }),
        });

        return () => {
          handle();
          subscribed = false;
        };
      }
      return null;
    },
    chatData,
  }),
  name: 'chatData',
});

const withNotifyChatReadStatus = graphql(notifyChatReadStatusMutation, {
  props: ({ ownProps, mutate }) => ({
    notifyReadStatus: (chatId, messageId) => mutate({
      variables: {
        chatId,
        messageId,
      },
      updateQueries: {
        chat: (prev, { mutationResult: { data: { notifyChatReadStatusHelpdesk } } }) => update(prev, {
          chat: {
            unreadMessagesCount: { $set: notifyChatReadStatusHelpdesk.chat.unreadMessagesCount },
          },
        }),
      },
    }),
  }),
});

export const withMarkAsResolved = graphql(markAsResolvedMutation, {
  props: ({ ownProps, mutate }) => ({
    markAsResolved: (chatId = null) => mutate({
      variables: {
        chatId: chatId || ownProps.match.params.id,
      },
      optimisticResponse: {
        __typename: 'Mutation',
        markAsResolved: true,
      },
      update: (store, { data }) => {
        const id = chatId || ownProps.match.params.id;
        const chatFragment = gql`
            fragment ChatResolvedFields on Chat {
                isHidden
                id
            }
        `;

        const identifier = `Chat:${id}`;
        const fragmentData = store.readFragment({
          fragment: chatFragment,
          id: identifier,
        });

        if (fragmentData) {
          fragmentData.isHidden = data.markAsResolved;
          store.writeFragment({
            fragment: chatFragment,
            id: identifier,
            data: fragmentData,
          });
        }
      },
    }),
  }),
});

const withSendMessageMutation = graphql(sendMessageMutation, {
  props: ({ ownProps, mutate }) => ({
    sendMessage: (messageType, content, chatId) => mutate({
      variables: {
        chatId,
        messageType,
        ...(
          messageType === 'IMAGE' ? ({ imageData: content }) : ({ content })
        ),
      },
      optimisticResponse: {
        __typename: 'Mutation',
        sendMessageHelpdesk: {
          id: `temp_${Date.now().toString()}`,
          author: {
            __typename: 'User',
            username: 'helpdesk',
            id: 'temp',
            name: 'Helpdesk',
            profilePictureUrl: null,
            friendRequestStatus: null,
            friendsSince: null,
            appSupportChat: true,
          },
          createdAt: moment().format('LT'),
          createdAtDay: moment().format('ll'),
          serverMessage: false,
          messageType,
          content,
          imageThumb: messageType === 'IMAGE' ? content : null,
          __typename: 'ChatMessage',
        },
      },
      update:
        (store, { data: { sendMessageHelpdesk } }) => {
          const chatState = store.readQuery({
            query: helpdeskChatQuery,
            variables: {
              id: chatId,
              cursor: null,
            },
          });

          if (!chatState.helpdeskChat.messages.messages.find(m => m.id === sendMessageHelpdesk.id)) {
            chatState.helpdeskChat.messages.messages.push({
              ...sendMessageHelpdesk,
              content,
            });

            store.writeQuery({
              query: helpdeskChatQuery,
              variables: {
                id: chatId,
                cursor: null,
              },
              data: chatState,
            });
          }

          try {
            const myChatsState = store.readQuery({
              query: helpdeskChatsQuery,
            });

            const currentChatIndex = myChatsState.helpdeskChats.findIndex(chat => chat.id === chatId);

            myChatsState.helpdeskChats[currentChatIndex] = {
              ...(myChatsState.helpdeskChats[currentChatIndex]),
              lastUpdate: String(Date.now()),
              lastMessage: {
                __typename: 'ChatMessage',
                messageType,
                content: sendMessageHelpdesk.content,
                createdAt: sendMessageHelpdesk.createdAt,
              },
            };

            store.writeQuery({
              query: helpdeskChatsQuery,
              data: myChatsState,
            });
          } catch (e) {

          }
        },
    }),
  }),
});

export const HelpdeskChat = compose(withMarkAsResolved, withChat, withSendMessageMutation, withNotifyChatReadStatus)(HelpdeskChatView);
