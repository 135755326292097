import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontIcon } from 'material-ui';
import { Tooltip } from 'react-lightweight-tooltip';
import { faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class FontAwesome extends Component {
  static propTypes = {
    name: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.any,
    tooltip: PropTypes.string
  };

  render() {
    if (!this.props.name) {
      return null;
    }

    const style = { ...(this.props.style || {}), zIndex: 1499 };
    let icon;
    if (this.props.name === 'video') {
      icon = (
        <FontAwesomeIcon
          onClick={() => this.props.onClick && this.props.onClick()}
          style={style}
          style={Object.assign({ color: '#1114a4' }, style)}
          icon={faVideo}
        />
      );
    } else {
      icon = (
        <FontIcon
          onClick={() => this.props.onClick && this.props.onClick()}
          style={style}
          className={`fa fa-${this.props.name}`}
        />
      );
    }

    if (this.props.tooltip && this.props.tooltip !== '') {
      return (
        <Tooltip
          content={this.props.tooltip}
          styles={{ tooltip: { minWidth: 20 }, wrapper: { cursor: 'pointer' } }}
        >
          {icon}
        </Tooltip>
      );
    }

    return icon;
  }
}
