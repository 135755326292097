import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Subheader, ListItem } from 'material-ui';
import { groupBy, toPairs } from 'lodash';
import { Loading } from '../../common/components/loading.component';
import { Image } from '../../common/components/image.component';
import { FontAwesome } from '../../common/components/font-awesome';
import { PhotoApprovalDialog } from './photo-approval-dialog.container';

const styles = {
  miniListItem: {
    paddingTop: 2,
    paddingBottom: 2,
  },
  name: {
    display: 'inline-block',
    verticalAlign: 'top',
    marginTop: 5,
    marginLeft: 8,
    marginRight: 8,
    paddingTop: 5,
  },
  date: {
    display: 'inline-block',
    verticalAlign: 'top',
    marginTop: 5,
    marginLeft: 8,
    marginRight: 8,
    paddingTop: 7,
    fontSize: 10,
    float: 'right',
  },
  changeArrow: {
    verticalAlign: 'top',
    marginLeft: 8,
    marginRight: 8,
    marginTop: 5,
  },
  photosList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    paddingLeft: '16px',
  },
  photoItem: { width: '50px', paddingRight: '10px', cursor: 'pointer' },
};

export class PhotoApprovalsView extends Component {
  static propTypes = {
    approvals: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      error: PropTypes.any,
      waitingPhotoApprovals: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    height: PropTypes.number,
  };

  constructor() {
    super();

    this.state = {
      currentApproval: null,
    };
  }

  openApproval(approval) {
    this.setState({
      currentApproval: approval,
    });
  }

  close() {
    this.props.approvals.refetch();
    this.setState({ currentApproval: null });
  }

  render() {
    if (this.props.approvals.loading) {
      return <Loading />;
    }

    let { waitingPhotoApprovals } = this.props.approvals;

    waitingPhotoApprovals = (waitingPhotoApprovals || []).filter(i => i.user);

    const approvalsByUser = toPairs(groupBy(waitingPhotoApprovals, ({ user }) => user.id));

    return (
      <div style={{ height: this.props.height, overflow: 'scroll' }}>
        <PhotoApprovalDialog
          onRequestClose={() => this.close()}
          open={this.state.currentApproval !== null}
          approval={this.state.currentApproval}
        />
        <div>
          <Subheader>Pending Approvals</Subheader>
          {waitingPhotoApprovals.length === 0 ? (
            <ListItem
              disabled={true}
              primaryText="There are no pending approvals!"
              leftIcon={<FontAwesome name="smile-o" />}
            />
          ) : null}
          {approvalsByUser.map(([userId, approvals]) => (
            <div key={userId}>
              <Subheader>{approvals[0].user.name}</Subheader>
              <div style={styles.photosList}>
                {approvals.map(approval => (
                  <div
                    key={approval.id}
                    style={styles.photoItem}
                    title={approval.createdAt}
                    onClick={() => this.openApproval(approval)}
                  >
                    <Image size={50} src={approval.imageUrl} />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
