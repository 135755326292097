import React, { Component } from 'react';
import { Dialog, RaisedButton } from 'material-ui';
import Chart from 'chart.js';
import { createFeedContainer } from '../../feed/components/feed-items';
import { Theme } from '../../theme/theme';
import { ProfileAvatar } from '../../common/components/profile-avatar.component';
import { FontAwesome } from '../../common/components/font-awesome';
import { Analytics } from '../../analytics/components/analytics.container';

Chart.defaults.global.responsive = true;

const statistics = data => [
  {
    name: 'Published Polls',
    value: data.publishedPolls || 0,
    color: Theme.ACTIVE1_COLOR
  },
  {
    name: 'Average Votes',
    value: data.averageVotes || 0,
    color: Theme.ACTIVE2_COLOR
  },
  {
    name: 'Average Per Category',
    value: data.averagePollsCategory || 0,
    color: Theme.PRIMARY_COLOR
  }
];

const tableColumns = componentContext => [
  { headerName: 'Question', width: 30, valueFrom: 'question' },
  { headerName: 'Total Votes', width: 15, valueFrom: 'totalVotes' },
  {
    headerName: 'Author',
    width: 20,
    render: item => {
      if (item && item.author && item.author.name) {
        return (
          <div>
            <ProfileAvatar size={30} src={item.author.profilePictureUrl} />
            <div
              style={{
                display: 'inline-block',
                lineHeight: '30px',
                verticalAlign: 'top',
                marginLeft: 5
              }}
            >
              {item.author.name}
            </div>
          </div>
        );
      }

      return <span />;
    }
  }
];

const PollsTable = createFeedContainer(
  'POLL',
  'Polls',
  statistics,
  tableColumns,
  null,
  (item, componentContext) => [
    <FontAwesome
      tooltip='Votes'
      onClick={() => componentContext.props.pieChartClicked(item)}
      key='0'
      style={{ cursor: 'pointer', fontSize: 12, marginRight: 10 }}
      name='pie-chart'
    />,
    <FontAwesome
      tooltip='Analytics'
      onClick={() => componentContext.props.onAnalyticsClicked(item)}
      key={1}
      style={{ cursor: 'pointer', fontSize: 12, marginRight: 10 }}
      name='line-chart'
    />
  ]
);

export class Polls extends Component {
  constructor() {
    super();

    this.state = {
      modalPoll: null,
      analytics: null
    };
  }

  open(item) {
    this.setState({ modalPoll: item });
  }

  close() {
    if (this.chart) {
      this.chart.destroy();
    }
    this.setState({ modalPoll: null });
  }

  getAnalyticsParams() {
    return [
      {
        category: 'Feed',
        action: 'AnswerPoll',
        identifier: this.state.analytics.id,
        name: 'Total Votes'
      },
      {
        category: 'Feed',
        action: 'AnswerPoll',
        identifier: this.state.analytics.id,
        name: 'Total Votes (Unique)',
        flags: { unique: true }
      }
    ];
  }

  renderPie() {
    if (!this.state.modalPoll) {
      return null;
    }

    const possibleAnswers = this.state.modalPoll.possibleAnswers;

    const data = {
      labels: possibleAnswers.map(item => item.answer),
      datasets: [
        {
          backgroundColor: [
            Theme.PRIMARY_COLOR,
            Theme.SECONDARY_COLOR,
            Theme.ACTIVE1_COLOR,
            Theme.ACTIVE2_COLOR
          ],
          fillColor: 'rgba(220,220,220,0.5)',
          strokeColor: 'rgba(220,220,220,1)',
          pointColor: 'rgba(220,220,220,1)',
          pointStrokeColor: '#fff',
          data: possibleAnswers.map(item => item.value)
        }
      ]
    };

    return (
      <canvas
        style={{ width: 400, height: 200 }}
        width='400'
        height='200'
        ref={ref => {
          if (ref !== null) {
            this.chart = new Chart(ref, {
              type: 'pie',
              data
            });
          }
        }}
      />
    );
  }

  openAnalytics(event) {
    this.setState({
      analytics: event
    });
  }

  render() {
    return (
      <div>
        <Dialog
          onRequestClose={() => this.close()}
          title={this.state.modalPoll ? this.state.modalPoll.question : ''}
          actions={[
            <RaisedButton
              primary={true}
              label='Close'
              onClick={() => this.close()}
              onTouchTap={() => this.close()}
            />
          ]}
          open={this.state.modalPoll !== null}
        >
          {this.renderPie()}
        </Dialog>
        <Dialog
          onRequestClose={() => this.openAnalytics(null)}
          title={
            this.state.analytics
              ? `Analytics for poll: ${this.state.analytics.question}`
              : ''
          }
          actions={[
            <RaisedButton
              primary={true}
              label='Close'
              onClick={() => this.openAnalytics(null)}
              onTouchTap={() => this.openAnalytics(null)}
            />
          ]}
          contentStyle={{
            position: 'absolute',
            left: '50%',
            top: '30%',
            transform: 'translate(-50%, -50%)'
          }}
          open={this.state.analytics !== null}
        >
          {this.state.analytics && (
            <Analytics params={this.getAnalyticsParams()} />
          )}
        </Dialog>
        <PollsTable
          onAnalyticsClicked={event => this.openAnalytics(event)}
          pieChartClicked={item => this.open(item)}
        />
      </div>
    );
  }
}
