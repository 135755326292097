import React, { Component } from 'react';
import { typography } from 'material-ui/styles';
import { Theme } from '../../theme/theme';
import {
  Drawer,
  ListItem,
  List,
  Avatar,
  FloatingActionButton
} from 'material-ui';
import defaultProfileImage from '../../public/default_profile.png';
import { FontAwesome } from '../../common/components/font-awesome';
import { MENU_ITEMS } from '../menu-items';
import { Link, RouteComponentProps } from 'react-router-dom';
import logo from '../../public/dualiti.png';
import { logout } from '../../account/account.util';
import { ChildProps } from 'react-apollo';
import { GetUserQuery } from '../../ApolloComponents';

const styles = {
  menuIcon: {
    paddingLeft: 20,
    paddingTop: 12,
    fontSize: 16,
    color: Theme.PRIMARY_TEXT_COLOR
  },
  menuIconVideo: {
    color: 'rgb(255, 255, 255)',
    position: 'absolute',
    top: '19px',
    left: '38px',
    fontSize: '13px',
    zIndex: 1499
  },
  menuItem: {
    color: typography.textFullWhite,
    textTransform: 'uppercase',
    height: 45
  },
  drawer: {
    zIndex: 2,
    backgroundColor: Theme.PRIMARY_COLOR
  },
  listItem: {
    color: typography.textFullWhite,
    fontSize: 12
  },
  logo: {
    backgroundColor: Theme.SECONDARY_COLOR,
    maxHeight: 64
  },
  footer: {
    position: 'fixed',
    bottom: 20,
    width: '100%',
    textAlign: 'center'
  }
} as any;

type Props = ChildProps<RouteComponentProps<{}>, GetUserQuery>;

export class AppDrawerView extends Component<Props> {
  isActive(route: string) {
    return this.props.location.pathname.includes(route);
  }

  render() {
    const { data } = this.props;

    if (!data || data.loading || !data.getUser) {
      return null;
    }

    const { getUser: user } = data;

    return (
      <Drawer containerStyle={styles.drawer} docked={true} open={true}>
        <div style={styles.logo}>
          <List>
            <ListItem
              disabled={true}
              style={styles.listItem}
              leftAvatar={
                <Avatar
                  src={user.profilePictureUrl || defaultProfileImage}
                  size={40}
                />
              }
            >
              {user.name}
            </ListItem>
          </List>
        </div>
        <div>
          <List>
            {MENU_ITEMS.map((menuItem, index) => (
              <Link to={menuItem.link} key={index}>
                <ListItem
                  style={{
                    ...styles.menuItem,
                    ...(this.isActive(menuItem.link)
                      ? {
                          backgroundColor: Theme.ACTIVE_ITEM_BG_COLOR,
                          fontWeight: 'bold'
                        }
                      : {})
                  }}
                  leftAvatar={
                    <FontAwesome
                      style={
                        menuItem.title === "Videos" ?   
                        {
                          ...styles.menuIconVideo,
                          ...(this.isActive(menuItem.link)
                            ? { color: Theme.SECONDARY_COLOR }
                            : {})
                        }:
                        {
                          ...styles.menuIcon,
                          ...(this.isActive(menuItem.link)
                            ? { color: Theme.SECONDARY_COLOR }
                            : {})
                        }}
                      name={menuItem.iconName}
                    />
                  }
                >
                  {menuItem.title}
                </ListItem>
              </Link>
            ))}
          </List>
        </div>
        <div style={styles.footer}>
          <FloatingActionButton
            onClick={() => logout()}
            onTouchTap={() => logout()}
            backgroundColor={Theme.ACTIVE2_COLOR}
          >
            <FontAwesome name='sign-out' />
          </FloatingActionButton>
          <div
            style={{
              marginTop: 20,
              color: 'white',
              textAlign: 'center',
              fontSize: 11,
              verticalAlign: 'top'
            }}
          >
            Powered by Dualiti LLC <br />
            <img
              alt='Dualiti LLC'
              style={{ width: 75, marginTop: 10, objectFit: 'contain' }}
              src={logo}
            />
          </div>
        </div>
      </Drawer>
    );
  }
}
