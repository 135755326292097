import gql from 'graphql-tag';

export const waitingProfilePictureApprovalsQuery = gql`
  query waitingProfilePictureApprovals {
    waitingProfilePictureApprovals {
      id
      createdAt
      imageUrl
      defaultImageUrl
      currentImageUrl
      user {
        id
        name
        profilePictureUrl
      }
    }
  }
`;
