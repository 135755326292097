import { graphql } from 'react-apollo';
import { publicationTargetsQuery } from '../graphql/publication-targets.query';
import { TargetPickerFormView } from './target-picker-form.component';
import { withEditEntity } from './with-edit-feed-item.container';
import { updateNewsFeedItemTargetsQuery } from '../graphql/update-news-feed-item-targets.mutation';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';

const mapDispatch = dispatch => ({
  navigate: route => dispatch(push(route)),
});

const withState = connect(({ router }) => ({
  currentLocation: router.location.pathname,
}), mapDispatch);

const withData = graphql(publicationTargetsQuery, {
  name: 'targets',
  options: {
    fetchPolicy: 'network-only',
  },
});

const withMutation = graphql(updateNewsFeedItemTargetsQuery, {
  props: ({ ownProps, mutate }) => ({
    updateTargets: (itemId, targets) => mutate({ variables: { itemId, targets } }),
  }),
});

export const TargetPickerForm = withState(withData(withMutation(withEditEntity(TargetPickerFormView))));
