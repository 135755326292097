import gql from 'graphql-tag';

export const newChatMessagesSubscription = gql`
    subscription newChatMessages($chatId: String!, $after: String!) {
        helpdeskChatMessages(chatId: $chatId, after: $after) {
            id
            createdAt(momentFormat: "LT")
            createdAtDay: createdAt(momentFormat: "ll")
            serverMessage
            content
            imageThumb: content(imageParams: { height: "300", width: "300", crop: FIT })
            author {
                id
                username
                name
                profilePictureUrl
            }
            messageType
        }
    }
`;
