import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, FlatButton } from 'material-ui';
import { Loading } from '../../common/components/loading.component';
import { YesNoModal } from '../../common/components/yes-no-modal.component';

class PhotoContainer extends Component {
  static propTypes = {
    photo: PropTypes.any.isRequired,
    title: PropTypes.string,
    onPress: PropTypes.func
  };

  componentDidMount() {
    this.setState({
      hover: false
    });
  }

  render() {
    let containerStyles = styles.photo;

    if (this.state && this.state.hover) {
      containerStyles = {
        ...containerStyles,
        ...styles.photoHover
      };
    }

    return (
      <div
        style={containerStyles}
        title={this.props.title}
        onClick={() => this.props.onPress()}
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
      >
        <img src={this.props.photo.url} style={{ width: '100%' }} alt='' />
      </div>
    );
  }
}

export class StudentPhotosModalView extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    student: PropTypes.any,
    onRequestClose: PropTypes.func,
    deletePhoto: PropTypes.func,
    photosData: PropTypes.shape({
      loading: PropTypes.bool,
      photos: PropTypes.arrayOf(PropTypes.any)
    })
  };

  constructor() {
    super();

    this.state = {
      loading: false,
      photoToDelete: null
    };
  }

  closeDeleteModal() {
    this.setState({ photoToDelete: null });
  }

  openDeleteModal(photo) {
    this.setState({ photoToDelete: photo });
  }

  deletePhoto() {
    this.props.deletePhoto(this.state.photoToDelete.id);
    this.closeDeleteModal();
  }

  renderNothingToDo() {
    return (
      <div style={{ textAlign: 'center' }}>
        <span>
          <strong>{this.props.student.name} has no photos!</strong>
        </span>
      </div>
    );
  }

  renderManagement() {
    const {
      photosData: { photos }
    } = this.props;

    return (
      <div style={styles.photosList}>
        {photos.map(photo => (
          <PhotoContainer
            key={photo.id}
            photo={photo}
            onPress={() => this.openDeleteModal(photo)}
            title='Delete photo'
          />
        ))}
      </div>
    );
  }

  render() {
    if (!this.props.isOpen) {
      return null;
    }

    const actions = [
      <FlatButton
        label='Close'
        onClick={() => this.props.onRequestClose()}
        onTouchTap={() => this.props.onRequestClose()}
      />
    ];

    const { student, photosData } = this.props;
    const count = (photosData.photos || []).length;

    return (
      <Dialog
        autoDetectWindowHeight={false}
        title={`${student.name}'s Photos Management`}
        actions={actions}
        modal={false}
        open={this.props.isOpen}
        onRequestClose={() => this.props.onRequestClose()}
      >
        {this.state.loading || photosData.loading ? (
          <Loading />
        ) : (
          <div>
            <YesNoModal
              open={!!this.state.photoToDelete}
              showClose={false}
              title='Are you sure you want to remove the photo?'
              onYes={() => this.deletePhoto()}
              onNo={() => this.closeDeleteModal()}
            >
              {this.state.photoToDelete ? (
                <div style={styles.containerInModal}>
                  <img
                    alt=''
                    src={this.state.photoToDelete.url}
                    style={styles.photoInModal}
                  />
                </div>
              ) : null}
            </YesNoModal>
            {count ? this.renderManagement() : this.renderNothingToDo()}
          </div>
        )}
      </Dialog>
    );
  }
}

const styles = {
  photosList: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  photo: {
    maxWidth: '150px',
    maxHeight: '150px',
    marginRight: '15px',
    flex: '0 0 150px',
    cursor: 'pointer',
    textAlign: 'center',
    overflow: 'hidden'
  },
  photoHover: {
    opacity: 0.7
  },
  containerInModal: {
    textAlign: 'center'
  },
  photoInModal: {
    maxWidth: '500px',
    maxHeight: '500px'
  }
};
