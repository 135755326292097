import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, FlatButton, RaisedButton, TextField } from 'material-ui';
import { Loading } from '../../common/components/loading.component';

export class StudentSocialNetworksModalView extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    student: PropTypes.any,
    onRequestClose: PropTypes.func.isRequired,
    modify: PropTypes.func.isRequired
  };

  constructor() {
    super();

    this.state = {
      snapchatNewUser: '',
      loading: false
    };
  }

  componentWillMount() {
    if (this.props.student && this.props.student.snapchat) {
      this.setState({
        snapchatNewUser: this.props.student.snapchat
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.student && nextProps.student.snapchat) {
      this.setState({
        loading: false,
        snapchatNewUser: nextProps.student.snapchat
      });
    }
  }

  resetFacebook() {
    this.execute({
      userId: this.props.student.id,
      socialNetwork: 'FACEBOOK',
      newValue: null,
      reset: true
    });
  }

  resetLinkedIn() {
    this.execute({
      userId: this.props.student.id,
      socialNetwork: 'LINKEDIN',
      newValue: null,
      reset: true
    });
  }

  resetInstagram() {
    this.execute({
      userId: this.props.student.id,
      socialNetwork: 'INSTAGRAM',
      newValue: null,
      reset: true
    });
  }

  resetTwitter() {
    this.execute({
      userId: this.props.student.id,
      socialNetwork: 'TWITTER',
      newValue: null,
      reset: true
    });
  }

  resetSnapchat() {
    this.execute({
      userId: this.props.student.id,
      socialNetwork: 'SNAPCHAT',
      newValue: null,
      reset: true
    });
    this.setState({
      snapchatNewUser: ''
    });
  }

  updateSnapchat() {
    this.execute({
      userId: this.props.student.id,
      socialNetwork: 'SNAPCHAT',
      newValue: this.state.snapchatNewUser,
      reset: false
    });
  }

  execute(variables) {
    this.setState({ loading: true });

    this.props
      .modify(variables)
      .catch(() => null)
      .then(() => this.setState({ loading: false }));
  }

  render() {
    if (!this.props.isOpen) {
      return null;
    }

    const actions = [
      <FlatButton
        label='Close'
        onClick={() => {
          this.setState({
            loading: false,
            snapchatNewUser: ''
          });
          this.props.onRequestClose();
        }}
        onTouchTap={() => {
          this.setState({
            loading: false,
            snapchatNewUser: ''
          });
          this.props.onRequestClose();
        }}
      />
    ];

    const { student } = this.props;

    return (
      <Dialog
        autoDetectWindowHeight={false}
        title={`${student.name}'s Social Networks`}
        actions={actions}
        modal={false}
        open={this.props.isOpen}
        onRequestClose={() => this.props.onRequestClose()}
      >
        {this.state.loading ? (
          <Loading />
        ) : (
          <div>
            <div style={{ display: 'block ' }}>
              <span>
                <strong>Facebook: </strong>
              </span>
              <TextField
                id='facebook'
                placeholder='Not connected yet'
                value={student.facebookName || ''}
                disabled={true}
                style={{ marginLeft: 10, marginRight: 10 }}
              />
              <RaisedButton
                onClick={() => this.resetFacebook()}
                onTouchTap={() => this.resetFacebook()}
                disabled={student.facebook === null}
                label='Reset'
                secondary={true}
              />
            </div>
            <div style={{ display: 'block ' }}>
              <span>
                <strong>LinkedIn: </strong>
              </span>
              <TextField
                id='linkedin'
                placeholder='Not connected yet'
                value={student.linkedin || ''}
                disabled={true}
                style={{ marginLeft: 10, marginRight: 10 }}
              />
              <RaisedButton
                onClick={() => this.resetLinkedIn()}
                disabled={student.linkedin === null}
                onTouchTap={() => this.resetLinkedIn()}
                disabled={student.linkedin === null}
                label='Reset'
                secondary={true}
              />
            </div>
            <div style={{ display: 'block ' }}>
              <span>
                <strong>Instagram: </strong>
              </span>
              <TextField
                id='instagram'
                placeholder='Not connected yet'
                value={student.instagram || ''}
                disabled={true}
                style={{ marginLeft: 10, marginRight: 10 }}
              />
              <RaisedButton
                onClick={() => this.resetInstagram()}
                onTouchTap={() => this.resetInstagram()}
                disabled={student.instagram === null}
                label='Reset'
                secondary={true}
              />
            </div>
            <div style={{ display: 'block ' }}>
              <span>
                <strong>Twitter: </strong>
              </span>
              <TextField
                id='twitter'
                placeholder='Not connected yet'
                value={student.twitter || ''}
                disabled={true}
                style={{ marginLeft: 10, marginRight: 10 }}
              />
              <RaisedButton
                onClick={() => this.resetTwitter()}
                onTouchTap={() => this.resetTwitter()}
                disabled={student.twitter === null}
                label='Reset'
                secondary={true}
              />
            </div>
            <div style={{ display: 'block ' }}>
              <span>
                <strong>Snapchat: </strong>
              </span>
              <TextField
                id='snapchat'
                placeholder='Not connected yet'
                value={this.state.snapchatNewUser}
                style={{ marginLeft: 10, marginRight: 10 }}
                onChange={(event, newValue) =>
                  this.setState({ snapchatNewUser: newValue })
                }
              />
              <RaisedButton
                onClick={() => this.updateSnapchat()}
                onTouchTap={() => this.updateSnapchat()}
                disabled={
                  this.state.snapchatNewUser === '' ||
                  this.state.snapchatNewUser === student.snapchat
                }
                label='Update'
                primary={true}
              />
              <RaisedButton
                style={{ marginLeft: 10 }}
                onClick={() => this.resetSnapchat()}
                onTouchTap={() => this.resetSnapchat()}
                disabled={student.snapchat === null}
                label='Reset'
                secondary={true}
              />
            </div>
          </div>
        )}
      </Dialog>
    );
  }
}
