/* eslint-disable */

import devConfig from './config.dev';
import prodConfig from './config.prod';

const environment = String(process.env.REACT_APP_ENV || 'dev').toLowerCase();

console.log(`App started in ${environment} env`);

let config = devConfig;

if (environment === 'production' || environment === 'staging') {
  config = prodConfig;
}

export default config;
