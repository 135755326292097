import React, { Component } from 'react';
import Chart from 'chart.js';

class PieChart extends Component {
  constructor(props) {
    super(props);
    this.canvasRef = null;
    this.chart = null;
  }

  componentDidMount() {
    this.initializeChart();
  }

  componentWillUnmount() {
    // Proper cleanup to avoid memory leaks
    if (this.chart) {
      this.chart.destroy();
    }
  }

  initializeChart = () => {
    const ctx = this.canvasRef.getContext('2d');
    this.chart = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: [this.props.firstLabel, this.props.secondLabel],
        datasets: [{
          data: [this.props.firstValue, this.props.secondValue],
          backgroundColor: [
            'rgba(255, 99, 132, 0.8)',
            'rgba(54, 162, 235, 0.8)',
          ],
          borderColor: [
            'white',
            'white',
          ],
          borderWidth: 1
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false
      }
    });
  }

  render() {
    return (
      <div style={{ width: '300px', height: '300px' }}>
        <canvas
          ref={element => this.canvasRef = element}
          width="200"
          height="200"
        />
      </div>
    );
  }
}

export default PieChart;
